export class LaboralExport {
    numRegistro?: string
    tipo?: string
    dni?: string
    nombre?: string
    periodo?: any
    codigo?: any
    descripcion?: string
    bruto?: number
    complementoMaterial?: number
    vehiculo?: number
    kms?: number
    dietas?: number
    hExtras?: number
    hFestivas?: number
    brutoConExtras?: number
    prIrpf?: number
    irpf?: number
    ss?: number
    total?: number
    fechaPago?: string
    formaPago?: String
    ssEmpresa?: number


public constructor(data?: any, init?: Partial<LaboralExport>) {
    console.log(data)

    if (data) { 
        this.numRegistro= data.numRegistro
        this.tipo=data.tipo
         this.dni= data.dni
        this.nombre = data.nombre
        this.periodo=data.periodo
        this.codigo=data.codigo
        this.descripcion=data.descripcion
        this.bruto=data.bruto
        this.complementoMaterial=data.complementoMaterial
        this.vehiculo=data.vehiculo
        this.kms=data.kms
        this.dietas=data.dietas
        this.hExtras=data.hExtras
        this.hFestivas=data.hFestivas
        this.brutoConExtras=data.brutoConExtras
        this.prIrpf=data.prIrpf
        this.irpf=data.irpf
        this.ss=data.ss
        this.total=data.total
        this.fechaPago=data.fechaPago
        this.formaPago=data.formaPago
        this.ssEmpresa=data.ssEmpresa

    } else {
        Object.assign(this, init);
    }
}}