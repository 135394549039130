import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoUsuarioService {

  url: string = environment.API_URL + "/tipoUsuario";


  constructor(private http: HttpClient
  ) { }
  /* 
  Peticion al back para getear el listados de tipo de usuarios
  */
  getAllTipos(): Observable<any> {
    return this.http.get(`${this.url}/list`);
  }
}
