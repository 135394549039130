import { Injectable } from '@angular/core';
import { InterfazInicialComponent } from 'src/app/components/interfaz-inicial/interfaz-inicial.component';
import { TokenStorageService } from '../token/token-storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  /* 
  Servicio que se encarga de manejar todos los metodos 
  de funcionalidad que necesitan las tablas de los listados
  */
  constructor(
    private tokenService: TokenStorageService,

  ) { }

  
  currentUser = this.tokenService.getUser()
  canvas: any
  /* 
  Metodo que se encarga de comprobar la longitud de los componentes de una lista de un campo de la tabla y lo limita a un 
  maximo de tres
  */
  checkLength(obj: any, id: string): string {

    var response = ""
    if (obj.length > 0) {
      for (let index = 0; index < obj.length; index++) {
        if (index == 0) {
          if (obj.length > 1) {
            if (id) {
              response = response + obj[index][id] + ","
            } else {
              response = response + obj[index] + ","
            }

          } else {
            if (id) {
              response = response + obj[index][id]
            } else {
              response = response + obj[index]
            }
          }
        } else if (index == 1) {
          if (obj.length > 2) {
            if (id) {
              response = response + obj[index][id] + ","
            } else {
              response = response + obj[index] + ","
            }
          } else {
            if (id) {
              response = response + obj[index][id]
            } else {
              response = response + obj[index]
            }
          }

        }
        else if (index == 2) {
          if (obj.length > 3) {
            if (id) {
              response = response + obj[index][id] + "..."
            } else {
              response = response + obj[index] + "..."
            }
          } else {
            if (id) {
              response = response + obj[index][id]
            } else {
              response = response + obj[index]
            }
          }
        }
      }
    }
    return response
  }
  /* 
  Calcula la longitud del titulo de la columna de las tablas
  */
  calcTextWidth(text: any, font: any) {
    let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 10;
  }

  /* 
  Comprueba los permisos que va a tener sobre la tabla el ususario
  */
  checkPermiso(idPermiso: number, idAccion: number, isSuperAdmin: boolean, permisos: any): boolean {
    this.currentUser = this.tokenService.getUser()
    if (isSuperAdmin && this.currentUser.rol.id == 1 || this.currentUser.rol.id == 3) {
      return false;
    } else {
      return !permisos.some((e: any) =>
        idPermiso === e.permiso.id && e.accion.id === idAccion
      )
    }
  }

  checkPermisoProductor(idPermiso: number, idAccion: number, isSuperAdmin: boolean, permisos: any): boolean {
    this.currentUser = this.tokenService.getUser()
    let tienePermisos: boolean = false;
    if (isSuperAdmin && this.currentUser.rol.id == 1 || this.currentUser.rol.id == 3) {
      tienePermisos = false;
    } else {
      permisos.forEach((e: any) => {
        if(idPermiso === e.permiso.id && e.accion.id === idAccion) tienePermisos = true;
      })
    }

    return tienePermisos;
  }

  checkAnyAccionByPermiso(idPermiso: number, isSuperAdmin: boolean, permisos: any): boolean {
    this.currentUser = this.tokenService.getUser()
    if (isSuperAdmin && this.currentUser.rol.id == 1 || this.currentUser.rol.id == 3) {
      return false;
    } else {
      return !permisos.some((e: any) =>
        idPermiso === e.permiso.id
      )
    }
  }


  /* 
  Metodos para el filtrado por proyecto
  */
  proy: any
  proyectoSubject: Subject<any> = new Subject<any>();
  subscribeToProyectoChanges(callback: (proyecto: any) => void): void {

    this.proyectoSubject.subscribe(callback);
  }

  setProyecto(proyecto: any): void {
    this.proy = proyecto;
    this.proyectoSubject.next(proyecto);
  }


  checkPro(column: any, isAdmin: boolean) {

    if (column == "proyectos" || column == "proyecto") {
      if (isAdmin) {
        return true
      }
      return false
    }
    if (column == "permisos") {

      if (!isAdmin) {
        return true
      }


      return false
    }
    return true
  }


  columnaMarcada: any = null;
  columnaMarcadaClicada: any = null;
  marcarColumna(row: any, entra: any) {
    if (entra == null) {
      if (this.columnaMarcadaClicada === row) {
        this.columnaMarcadaClicada = null;
      } else {
        this.columnaMarcadaClicada = row;
      }
    }
    if (entra) {
      this.columnaMarcada = row;
    } else {
      this.columnaMarcada = null;
    }
  }
}
