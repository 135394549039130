import { Proyecto } from "./proyecto"

export class GastoExport {
    numOc?: number
    id?: number
    numeroFactura?: string
    fechaFactura!: string
    fechasPago!:string
    codigoCuentaConcepto!: string;
    conceptos!:string
    peridoIva!:string
    nombreProductora!:string
    cifProductora!:string
    tipoPago!:string
    irpf!:any
    iva!:any
    base!:any
    total!:any
    totalPagado!:any
    public constructor(data?: any, init?: Partial<GastoExport>) {
        if (data) {    
            if(data.numOc){
                this.numOc = data.numOc;
            }        
            this.id = data.id;
            this.numeroFactura = data.numeroFactura;
            this.fechaFactura = data.fecha_factura;
            this.fechasPago = "";
            data.pago.forEach((element:any, index: number) => {
                if (index === 0) this.fechasPago += `${element.fecha_pago}`;
                else this.fechasPago += `, ${element.fecha_pago}`;
            });
            this.codigoCuentaConcepto = data.conceptos[0].codigo.cuenta + " - " + data.conceptos[0].codigo.concepto; 
            this.conceptos = "";
            data.conceptos.forEach((element:any, index: number) => {
                if (index === 0) this.conceptos += `${element.descripcion}`;
                else this.conceptos += `, ${element.descripcion}`;
            });
            this.peridoIva = data.periodo_iva.nombre;
            this.nombreProductora = data.productora.nombre;
            this.cifProductora = data.productora.cif;
            this.tipoPago = data.tipo_gasto.nombre;
            this.irpf = data.irpf;
            this.iva = data.iva;
            this.base = data.base;
            this.total = data.total;
            this.totalPagado = data.total_pagado;
        
        } else {
            Object.assign(this, init);
        }
    }
}



export class JustificacionExport {
    numeroFactura?: string
    fechaFactura!: string
    peridoIva!:string
    nifProveedor!:any
    nombreProveedor!:any
    numPc!:any
    nombrePc!:any
    irpf!:any
    iva!:any
    base!:any
    total!:any
    public constructor(data?: any, init?: Partial<JustificacionExport>) {
        if (data) {          
            this.nifProveedor=data.nifProveedorPc
            this.nombreProveedor=data.nombreProveedorPc
            this.numeroFactura = data.numeroFactura
            this.numPc=data.num_pc
            this.nombrePc=data.nombre_pc
            this.fechaFactura = data.fecha_factura;
            this.peridoIva = data.periodo_iva.nombre
            this.irpf = data.irpf;
            this.iva = data.iva;
            this.base = data.base;
            this.total = data.total;        
        } else {
            Object.assign(this, init);
        }
 }
}