import {
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  delay,
  finalize,
  map,
  startWith,
} from 'rxjs';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { Cuenta } from 'src/app/models/cuenta';
import {
  ConceptosOC,
  ConceptosOCListar,
  NomId,
  OrdenCompra,
  PagoOC,
  UnidadesOC,
} from 'src/app/models/orden-compra';
import { Proveedor } from 'src/app/models/proveedor';
import { CuentaService } from 'src/app/services/cuenta.service';
import { NextcloudServiceService } from 'src/app/services/nextcloud-service.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OcGastoService } from 'src/app/services/servicios-funcionalidad/oc-gasto.service';
import { OrdenCompraService } from 'src/app/services/orden-compra.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { FechaService } from 'src/app/services/servicios-funcionalidad/fecha.service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import * as moment from 'moment';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { PdfExportService } from 'src/app/services/servicios-funcionalidad/pdf-export.service';
import { ProductoraService } from 'src/app/services/productora.service ';

@Component({
  selector: 'app-nueva-orden-compra',
  templateUrl: './nueva-orden-compra.component.html',
  styleUrls: ['./nueva-orden-compra.component.scss'],
  animations: [dropdownAnimation],
})
export class NuevaOrdenCompraComponent {
  loading: boolean = false;
  isExporting: boolean = false;
  currentUser = this.tokenService.getUser();

  proveedor: Proveedor = new Proveedor();
  private formBuilder: FormBuilder = new FormBuilder();
  PlaceHolderIva = 'IVA';
  invSN = ['IVA', 'IGIC', 'Otros'];
  isProveedorExpanded = 'in';
  isConceptoTotalExpanded = 'in';
  isConceptoExpanded = 'in';
  isFileExpanded = 'in';
  isFileProveedortoExpanded = 'in';
  isFormaDePagoExpanded = 'in';
  isFacturaExpanded = 'in';
  isReading = false;
  proveedores: Proveedor[] = [];
  productoras: any = [];
  form!: FormGroup;
  conceptos!: FormGroup;
  pagoOc!: FormGroup;

  ordenCompraAux: any;

  isUpdate = false;
  tiposUnidades: NomId[] = [];
  tiposOC: NomId[] = [];
  tiposDocumentoOC: NomId[] = [];
  codigos: Cuenta[] = [];
  codigosSelects!: Observable<Cuenta[]>;
  tiposPagoOC: NomId[] = [];
  periocidadPagoOC: NomId[] = [];
  listDocumentos: any = [{}];

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();

  modalDataNoComplete = false;

  isFirst = true;
  listaGastosId: any = [];
  listaGastosAsociados: any[] = [];
  displayedColumns2: string[] = [
    'Nº',
    'Fecha Factura',
    'Factura',
    'NIF',
    'Proveedor',
    'Base',
    'Iva',
    'Irpf',
    'Pagado',
    'Acciones',
  ];
  listConceptos: ConceptosOC[] = [];
  listConceptosListar: ConceptosOCListar[] = [];

  isCreatePage = true;
  isSave = false;
  isClearForm = false;
  nombreBoton = 'Guardar Orden de Compra';
  nombrePagina = 'Orden de Compra';

  totalBase = 0;
  totalIva = 0;
  totalIrpf = 0;
  totalOC = 0;
  totalPagado = 0;
  totalpendientepago = 0;
  listConceptosStore: any = [];
  isConcepto = true;
  isFormaPago = false;
  idFacturasOC = false;
  new: any;

  dialogUpdate = false;

  id: any;
  proyecto: any;
  tipoUsuario: any;
  mostrarActualizarEstado: boolean = false;
  mostrarEnviar: boolean = false;
  mostrarAceptado: boolean = false;
  mostrarRechazado: boolean = false;
  primeraBusqueda: boolean = true;

  tipoDocumentosProv: any = [];
  nombre: any;

  formProvedor!: FormGroup;
  createNewProveedor: boolean = false;
  deparamento: any;
  storedPagoProveedor: any;
  altEnviar: String = 'Enviar notificación a : ';
  dialogCancel = false;
  cuentaSeleccionada: any;
  lugares: any;
  indexUpdate: number = -1;
  mensajeDocumentosProveedor: string | undefined;
  constructor(
    private notificationService: NotificationService,
    private proveedorService: ProveedorService,
    private tokenService: TokenStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private oredenCompraService: OrdenCompraService,
    private cuentaService: CuentaService,
    private dateAdapter: DateAdapter<any>,
    private nextCloudService: NextcloudServiceService,
    private ocGastoService: OcGastoService,
    public fechaService: FechaService,
    public tableService: TableService,
    public interfazInicial: InterfazInicialComponent,
    public permisosProyectoService: PermisosProyectoService,
    private registroGastosService: RegistroGastosService,
    private renderer: Renderer2,
    private el: ElementRef,
    private storageService: StorageServiceService,
    private notificacionesService: NotificacionesService,
    private productoraService:ProductoraService,
    private pdfService: PdfExportService
  ) {}

  get unidadesOc(): FormArray {
    return this.conceptos.get('unidadesOc') as FormArray;
  }
  get fechasPago(): FormArray {
    return this.pagoOc.get('fechasPago') as FormArray;
  }
  filters = [
    {
      field_type: 'STRING',
      key: 'proyectos.id',
      operator: 'CONTAINS_ID',
      value: this.interfazInicial.proyaux.id,
    },
  ];
  ngAfterViewInit(): void {
    /* 
    Añade eventos listener a todos los elementos de autocomplete para que bloqueen el scroll
    */
    var autocompletes: any = document.getElementsByName('autocompletename');
    var blo: any = document.getElementsByName('overflowblox');
    var correccionAncho: any = document.getElementById('correccion');
    if (autocompletes[0]) {
      autocompletes.forEach((elemet: any) => {
        //Desactiva el bloque de scroll
        elemet.addEventListener('blur', function () {
          correccionAncho.classList.remove('correccionAncho');
          blo[0].classList.remove('bloquear-scroll');
        });
        //Activa el bloque de scroll
        elemet.addEventListener('focus', () => {
          correccionAncho.classList.add('correccionAncho');
          blo[0].classList.add('bloquear-scroll');
        });
      });
    }
  }

  ngOnInit(): void {
    this.dateAdapter.setLocale('es-ES');
    this.getAllProductoras();
    this.proveedorService.getAllTTiposDocs().subscribe({
      next: (data) => {
        this.tipoDocumentosProv = data;
      },
    });
    if (this.interfazInicial.proyaux.id != undefined) {
      this.lugares = this.interfazInicial.proyaux.lugarDecorado.map(
        (item: any) => item.lugar + ' - ' + item.decorado
      );
    } else {
      this.notificationService.warning(
        'ALERTA',
        'No dispones de un proyecto seleccionado, no podrás rellenar el proveedor ni la orden de compra.',
        5000
      );
    }

    if (
      this.tableService.checkAnyAccionByPermiso(
        12,
        this.interfazInicial.isSuperAdmin,
        this.interfazInicial.permisos
      )
    ) {
      this.notificationService.error(
        'ERROR',
        'No tienes permisos suficientes.',
        5000
      );
      this.router.navigate(['admin-contacine/proyectos']);
    } else {
      this.route.params.subscribe((params) => {
        if (params['id']) {
          this.id = params['id'];

          this.route.url.subscribe((data) => {
            if (data[0].path.includes('ver')) {
              this.isReading = true;
              

              this.isConceptoExpanded = 'out';

              if (
                this.tableService.checkPermiso(
                  12,
                  1,
                  this.interfazInicial.isSuperAdmin,
                  this.interfazInicial.permisos
                )
              ) {
                this.notificationService.error(
                  'ERROR',
                  'No tienes permisos suficientes.',
                  5000
                );
                this.router.navigate(['admin-contacine/proyectos']);
              }
            } else if (data[0].path.includes('editar')) {
              this.isUpdate = true;
              if (
                this.tableService.checkPermiso(
                  12,
                  3,
                  this.interfazInicial.isSuperAdmin,
                  this.interfazInicial.permisos
                )
              ) {
                this.notificationService.error(
                  'ERROR',
                  'No tienes permisos suficientes.',
                  5000
                );
                this.router.navigate(['admin-contacine/proyectos']);
              }
            }
          });
        } else {
          if (
            this.tableService.checkPermiso(
              12,
              2,
              this.interfazInicial.isSuperAdmin,
              this.interfazInicial.permisos
            )
          ) {
            this.notificationService.error(
              'ERROR',
              'No tienes permisos suficientes.',
              5000
            );
            this.router.navigate(['admin-contacine/proyectos']);
          }
        }
      });
    }
    const filter = {
      filters: this.filters,
      page: '0',
      sorts: [],
    };
    this.proveedorService
      .filter(filter)
      .pipe(
        finalize(() => {
          if (sessionStorage.getItem('ModificarProveedor')) {
            this.selectProveedorNombre({
              option: { value: sessionStorage.getItem('ModificarProveedor') },
            });
            sessionStorage.removeItem('ModificarProveedor');
          } else {
            if (this.storedPagoProveedor.id != undefined) {
              this.selectProveedorNombre({
                option: { value: this.storedPagoProveedor.nombreFiscal },
              });
            }
          }
        })
      )
      .subscribe({
        next: (data: any) => {
          console.log("DATA SUSCIRBE: ", data);
          
          this.proveedores = data.content as Proveedor[];

          if (!this.proveedores || this.proveedores.length == 0) {
            this.notificationService.warning(
              'ALERTA',
              'No existen proveedores para este proyecto.',
              3500
            );
          }
        },
      });
    this.oredenCompraService.getAllTiposUnidades().subscribe({
      next: (data) => {
        this.tiposUnidades = data as NomId[];
        this.tiposUnidades = this.tiposUnidades.filter(
          (tipoUnidad) => tipoUnidad.id != 1
        );
      },
    });
    this.oredenCompraService.getAllTiposOC().subscribe({
      next: (data) => {
        this.tiposOC = data as NomId[];
      },
      error: (err) => {},
    });
    this.oredenCompraService.getAllTiposDocumentoOC().subscribe({
      next: (data) => {
        this.tiposDocumentoOC = data as NomId[];
      },
      error: (err) => {},
    });
    this.oredenCompraService.getAllTiposPagosOC().subscribe({
      next: (data) => {
        this.tiposPagoOC = data as NomId[];
      },
      error: (err) => {},
    });
    this.oredenCompraService.getAllPeriocidadPagosOC().subscribe({
      next: (data) => {
        this.periocidadPagoOC = data as NomId[];
      },
      error: (err) => {},
    });
    this.permisosProyectoService
      .getPermisosFormPreyectoAndUsu(
        this.interfazInicial.proyaux.id,
        this.currentUser.id
      )
      .subscribe({
        next: (data) => {
          this.deparamento = data.departamento;
        },
      });

    var storedFormData: any = {};

    var storedConceptosData: any = {};
    /*  var storedPagoOcData: any = {} */
    if (!this.isUpdate && !this.isReading) {
      try {
        storedFormData = JSON.parse(
          this.storageService.get('ocForm')
            ? this.storageService.get('ocForm')
            : ''
        );
        this.storedPagoProveedor = JSON.parse(
          this.storageService.get('formProvedor')
            ? this.storageService.get('formProvedor')
            : ''
        );
        storedConceptosData = JSON.parse(
          this.storageService.get('conceptosForm')
            ? this.storageService.get('conceptosForm')
            : ''
        );       
      } catch {}
    }

    if (storedConceptosData[0]) {
      this.filtrarCuentasProyecto(storedConceptosData);
    }

    this.conceptos = new FormGroup({
      tipoConceptoOc: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      lugar: new FormControl({ value: '', disabled: this.isReading }),
      tipoImpuesto: new FormControl({ value: '', disabled: this.isReading }),
      descripcion: new FormControl({ value: '', disabled: this.isReading }),
      moneda: new FormControl({ value: 'Euro', disabled: this.isReading }),
      cambio: new FormControl({ value: '1', disabled: this.isReading }),
      conversion: new FormControl({ value: '', disabled: this.isReading }),
      suma: new FormControl({ value: '', disabled: this.isReading }),
      cuentaConcepto: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      fechaInicio: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      fechaFin: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      precio: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      irpf: new FormControl({ value: 0, disabled: this.isReading }, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,2}([,.][0-9]{1,2})?$/),
      ]),
      iva: new FormControl({ value: 0, disabled: this.isReading }, [
        Validators.required,
        Validators.pattern(/^[0-9]{1,2}([,.][0-9]{1,2})?$/),
      ]),
      unidadesOc: new FormArray([
        new FormGroup({
          tipoUnidad: new FormControl(
            { value: '', disabled: this.isReading },
            Validators.required
          ),
          unidades: new FormControl(
            { value: '', disabled: this.isReading },
            Validators.required
          ),
        }),
        new FormGroup({
          tipoUnidad: new FormControl(
            { value: '', disabled: this.isReading },
            Validators.required
          ),
          unidades: new FormControl(
            { value: '', disabled: this.isReading },
            Validators.required
          ),
        }),
      ]),
      aux: new FormControl({ value: '', disabled: true }),
    });

    this.formProvedor = new FormGroup({
      cif: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required
      ),
      nombreFiscal: new FormControl(
        { value: '', disabled: this.isReading },
        Validators.required
      ),
      nombreComercial: new FormControl({ value: '', disabled: true }),
      personaContacto: new FormControl({ value: '', disabled: true }),
      direccion: new FormControl({ value: '', disabled: true }),
      telefono: new FormControl(
        { value: '', disabled: true },
        Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)
      ),
      codigoPostal: new FormControl({ value: '', disabled: true }, [
        Validators.pattern('^[0-9]{5}$'),
      ]),
      ciudad: new FormControl({ value: '', disabled: true }),
      email: new FormControl({ value: '', disabled: true }, [
        Validators.pattern(
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        ),
      ]),
      web: new FormControl(
        {
          value: '',
          disabled: true,
        } /*[Validators.required, Validators.pattern(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/)]*/
      ),
      iban: new FormControl({ value: '', disabled: true }),
    });

    this.pagoOc = new FormGroup({
      tipoPagoOc: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      periodicidadPago: new FormControl({
        value:
          /*  storedPagoOcData.periodicidadPago ? storedPagoOcData.periodicidadPago : */ '',
        disabled: this.isReading,
      }),
      numeroPagos: new FormControl({
        value:
          /* storedPagoOcData.numeroPagos ? storedPagoOcData.numeroPagos :  */ '',
        disabled: this.isReading,
      }),
      fianza: new FormControl({
        value: /* storedPagoOcData.fianza || */ '0',
        disabled: this.isReading,
      }),
      fechaFianza: new FormControl({
        value: '',
        disabled: this.isReading,
      }),
      observaciones: new FormControl({
        value: /* storedPagoOcData.observaciones || */ '',
        disabled: this.isReading,
      }),
      fechaInicio: new FormControl({
        value:
          /* storedPagoOcData.fechaInicio ? moment(storedPagoOcData.fechaInicio) : */ '',
        disabled: this.isReading,
      }),
      fechasPago: new FormArray([]),
    });

    if (this.pagoOc.controls['tipoPagoOc'].value.id == 1) {
      this.getFechasPago();
    }
    this.filtrarCuentasProyecto(undefined);

    this.form = new FormGroup({
      proveedor: new FormControl({ value:undefined, disabled: this.isReading }, [
        Validators.required,
      ]),
      conceptos: new FormControl({ value: '', disabled: this.isReading }),
      pagoOc: new FormControl({ value: '', disabled: this.isReading }),
      productora: new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ]),
      solicitante: new FormControl({
        value: { id: this.currentUser.id },
        disabled: this.isReading,
      }),
      departamento: new FormControl({
        value: this.deparamento,
        disabled: this.isReading,
      }),
      proyecto: new FormControl({
        value: { id: '' },
        disabled: this.isReading,
      }),
      aux: new FormControl({ value: '', disabled: true }),
    });

    if (this.isUpdate || this.isReading) {
      this.oredenCompraService
        .findById(this.id)
        .pipe(
          finalize(() => {
            this.comprobarActualizarOrdenDeCOmpra();
          })
        )
        .subscribe({
          next: (data) => {
            if (data.estado.id == 1 && this.isUpdate) {
              this.notificationService.info(
                'ALERTA',
                'Esta Orden de Compra esta aceptada.'
              );
              this.router.navigate([
                `admin-contacine/ver-orden-compra/${data.id}`,
              ]);
            }
            this.listaGastosId = data.gastos;
            this.ordenCompraAux = data;
            if (this.ordenCompraAux.estado.id == 1 && this.tableService.checkPermiso(12, 3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)){
                this.isReading = false;
              }
            this.form.get('proveedor')?.setValue({ id: data.proveedor.id });
            this.proveedor = new Proveedor(data.proveedor);
            this.listConceptos = data.conceptos as any[];
            this.listConceptos.forEach((data: any) => {
              var auxListar: ConceptosOCListar = new ConceptosOCListar(data);
              auxListar.cuentaConcepto = data.codigo;
              auxListar.cuentaConcepto.codigo = data.codigo.cuenta;
              auxListar.descripcion = data.descripcion;
              auxListar.irpf = data.irpf;
              auxListar.impuesto = data.iva;
             // auxListar.base = data.suma;
              auxListar.precio = data.precio;
              auxListar.fechaInicio = data.fechaInicio;
              auxListar.fechaFin = data.fechaFin;
              auxListar.tipo = data.tipoConceptoOc;
              auxListar.tipoCantidad = data.unidadesOc[0].tipoUnidad.nombre;
              auxListar.cantidad = data.unidadesOc[0].unidades;
              if (data.unidadesOc[1]) {
                auxListar.unidadCantidad = 'Unidades';
                auxListar.unidad = data.unidadesOc[1].unidades;
              } else {
                auxListar.unidadCantidad = { nombre: '-' };
                auxListar.unidad = '-';
              }
              data.codigo = { id: data.codigo.id };
              this.listConceptosListar.push(auxListar);

              if (this.isFirst) {
                this.displayedColumns = Object.keys(auxListar);
                this.displayedColumns.splice(this.displayedColumns.indexOf('irpf'),1)
                this.displayedColumns.splice(this.displayedColumns.indexOf('impuesto'),1)
                this.displayedColumns.splice(this.displayedColumns.indexOf('unidadCantidad'),1)
                this.displayedColumns.splice(this.displayedColumns.indexOf('tipoImpuesto'),1)
                this.displayedColumns.splice(this.displayedColumns.indexOf('moneda'),1)
                this.displayedColumns.splice(this.displayedColumns.indexOf('cambio'),1)

                this.displayedColumns.push('acciones');
                this.isFirst = false;
              }
            });
            this.dataSource.data = this.listConceptosListar;
            this.lengyh = (this.dataSource as any).length;
            if(data.productora){
              this.form.get('productora')?.setValue({ id: data.productora.id})
            }
            this.pagoOc.get('tipoPagoOc')?.setValue(data.pagoOc.tipoPagoOc);
            if (data.pagoOc.periodicidadPago) {
              this.pagoOc
                .get('periodicidadPago')
                ?.setValue(data.pagoOc.periodicidadPago);
            }
            if (data.pagoOc.numeroPagos) {
              this.pagoOc.get('numeroPagos')?.setValue(data.pagoOc.numeroPagos);
            }
            if (data.pagoOc.fechasPago.length != 0) {
              this.pagoOc
                .get('fechaInicio')
                ?.setValue(this.fechaService.date(data.pagoOc.fechasPago[0]));
            }
            if (data.pagoOc.fianza) {
              this.pagoOc.get('fianza')?.setValue(data.pagoOc.fianza);
            }
            if (data.pagoOc.fechaFianza) {
              this.pagoOc
                .get('fechaFianza')
                ?.setValue(this.fechaService.date(data.pagoOc.fechaFianza));
            }
            this.pagoOc
              .get('observaciones')
              ?.setValue(data.pagoOc.observaciones);

            if (data.pagoOc.fechasPago.length >= 1) {
              data.pagoOc.fechasPago.forEach((da: any) => {
                this.fechasPago.push(
                  new FormControl({ value: da, disabled: true })
                );
              });
            }
            this.totalBase = parseFloat(data.base);
            this.totalIrpf = parseFloat(data.totalIrpf);
            this.totalIva = parseFloat(data.totalIva);
            this.totalOC = parseFloat(data.totalConceptos);
            this.totalpendientepago = parseFloat(data.pendientePago);
            this.totalPagado = parseFloat(data.facturado);
            if (data.documentos.length != 0) {
              this.listDocumentos = data.documentos;
              data.documentos.forEach((doc: any) => {
                if (doc.gasto_id != undefined) {
                  doc.gasto_id.forEach((element: any) => {});
                }

                this.nextCloudService
                  .getBase64(doc.id_nextcloud)
                  .pipe(
                    finalize(() => {
                      this.buscarGastosPoriD();
                    })
                  )
                  .subscribe({
                    next: (b64) => {
                      doc.documento_id = undefined;
                      doc.fecha_captura = undefined;
                      doc.base64 = b64.b64;
                    },
                    error: (b64) => {
                      this.notificationService.error(
                        'ERROR',
                        'Error al leer los documentos guardados.',
                        5000
                      );
                    },
                  });
              });
            } else {
              this.buscarGastosPoriD();
            }
          },
        });
    }
  }
  recuperarDatosStorage(storedConceptosData:any) {
    this.listConceptosStore = storedConceptosData;
    storedConceptosData.forEach((data: any) => {
      var auxGuardar = new ConceptosOC(data);
      this.totalBase =
        +auxGuardar.unidadesOc[0].unidades *
          auxGuardar.unidadesOc[1].unidades *
          auxGuardar.precio +
        auxGuardar.iva -
        auxGuardar.irpf;
      this.totalIva = +this.totalBase * (auxGuardar.iva / 100);
      this.totalIrpf = +this.totalBase * (auxGuardar.irpf / 100);
      this.totalOC = this.totalBase + this.totalIva - this.totalIrpf;
      this.totalpendientepago = this.totalOC;
      var codigoData:any;
      this.codigos.forEach((element: any) => {
        if (element.cuenta == data.codigo.split(' - ')[0]) {
          auxGuardar.codigo = {id:element.id};
          codigoData=element;
        }
      });
      

      this.listConceptos.push(auxGuardar);
      var auxListar: ConceptosOCListar = new ConceptosOCListar(data);
          auxListar.cuentaConcepto = codigoData;
 
      auxListar.descripcion = data.descripcion;
      auxListar.irpf = data.irpf;
      auxListar.impuesto = data.iva;
      //auxListar.base = data.suma;
      auxListar.precio = data.precio;
      auxListar.fechaInicio = data.fechaInicio;
      auxListar.fechaFin = data.fechaFin;
      auxListar.tipo = data.tipoConceptoOc;
      auxListar.tipoCantidad = data.unidadesOc[0].tipoUnidad.nombre;
      auxListar.cantidad = data.unidadesOc[0].unidades;
      if (data.unidadesOc[1]) {
        auxListar.unidadCantidad = 'Unidades';
        auxListar.unidad = data.unidadesOc[1].unidades;
      } else {
        auxListar.unidadCantidad = { nombre: '-' };
        auxListar.unidad = '-';
      }
      console.log(this.displayedColumns)
      this.listConceptosListar.push(auxListar);
      if (this.isFirst) {
        this.displayedColumns = Object.keys(auxListar);
        this.displayedColumns.splice(this.displayedColumns.indexOf('irpf'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('impuesto'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('unidadCantidad'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('tipoImpuesto'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('moneda'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('cambio'),1)


        this.displayedColumns.push('acciones');
        this.isFirst = false;
      }
      this.dataSource.data = this.listConceptosListar;
      this.lengyh = (this.dataSource as any).length;
    });
  }

  /**
   * Función para buscar gastos asociados a las IDs identificadas.
   */
  /* para buscar los pagos de los gastos
  buscarGastosPoriD() {
    if (this.primeraBusqueda) {
      this.primeraBusqueda = false;
      let indexAux = 0;
      let listaGastosAsociadosAux: any = [];
      this.listaGastosId.forEach((element: any) => {
        element.pago.forEach((pago: any) => {
          if (pago.pagado) {
            let elementAux = { ...element };
            listaGastosAsociadosAux[indexAux] = elementAux;
            listaGastosAsociadosAux[indexAux]['pagado'] = pago.cantidad;
            this.totalPagado = +pago.cantidad;
            indexAux++;
          }
        });
      });
      this.dataSource2 = new MatTableDataSource(listaGastosAsociadosAux);
      this.totalpendientepago = this.totalOC - this.totalPagado;
    }
  } */

  filtrarCuentasProyecto(storedConceptosData:any) {
    this.codigos=[];
    let filters: any[] = [
      {
        field_type: 'STRING',
        key: 'proyectos.id',
        operator: 'CONTAINS_ID_OR_NULL',
        value: this.interfazInicial.proyaux.id,
      },
      {
        field_type: 'INTEGER',
        key: 'version',
        operator: 'LIKE',
        value: this.interfazInicial.proyaux.versionCodigos,
      },
      {
        field_type: 'DATE',
        key: 'fechaBaja',
        operator: 'IS_NULL'
      },
    ];
    let pageSize: any = 99999;
    let page: any = 0;
    let sorts: any[] = [];
    let filter2: any = {
      filters: filters,
      page: page,
      size: pageSize,
      sorts: sorts,
    };
    this.cuentaService.filter(filter2)
    .pipe(
      finalize(() => {
        if(storedConceptosData!= undefined){
        this.recuperarDatosStorage(storedConceptosData);}
      })
    ).subscribe({
      next: (data) => {
        console.log("DATA CODIGOS", data);
        
        this.codigos = data.content as Cuenta[];
        this.codigosSelects = this.conceptos.controls[
          'cuentaConcepto'
        ].valueChanges.pipe(
          startWith(''),
          map((value) => this.filter(value || ''))
        );
      },
      error: (error) => {},
    });
  }

  buscarGastosPoriD() {
    if (this.primeraBusqueda) {
      this.primeraBusqueda = false;
      let indexAux = 0;
      let listaGastosAsociadosAux: any = [];
      this.listaGastosId.forEach((element: any) => {
        element.conceptos.forEach((concepto: any) => {
          let elementAux = { ...element };

          listaGastosAsociadosAux[indexAux] = elementAux;
          listaGastosAsociadosAux[indexAux]['facturado'] = concepto.total;
          listaGastosAsociadosAux[indexAux]['base'] = concepto.base;
          listaGastosAsociadosAux[indexAux]['irpf'] = concepto.irpf;
          listaGastosAsociadosAux[indexAux]['iva'] = concepto.iva;

          // this.totalPagado = +concepto.cantidad;
          indexAux++;
        });
      });
      this.dataSource2 = new MatTableDataSource(listaGastosAsociadosAux);
      //this.totalpendientepago = this.totalOC - this.totalPagado;
    }
  }

  /**
   * Función para actualizar el estado de una orden de compra.
   *
   * @param actualizar - Indica si se va a actualizar el estado
   */
  actualizarEstado(actualizar: boolean) {
    let mensaje = 'La orden de compra no es correcta.';
    const textareaValor: HTMLTextAreaElement | null = document.getElementById(
      'miTextarea'
    ) as HTMLTextAreaElement;
    if (textareaValor) {
      mensaje = textareaValor.value;
    }
    this.oredenCompraService
      .actualizarEstado(
        this.tipoUsuario.id,
        this.ordenCompraAux.id,
        actualizar,
        mensaje,
        this.interfazInicial.currentUser.id
      )
      .subscribe({
        next: (data) => {
          this.oredenCompraService
            .findById(this.id)
            .pipe(
              finalize(() => {
                this.comprobarActualizarOrdenDeCOmpra();
                this.dialogCancel = false;
              })
            )
            .subscribe({
              next: (data) => {
                this.ordenCompraAux = data;
              },
              error: (err) => {},
            });
        },
        error: (err) => {},
      });
  }

  /**
   * Función para comprobar y actualizar el estado de una orden de compra.
   */
  comprobarActualizarOrdenDeCOmpra() {
    if (this.isReading || this.isUpdate) {
      this.mostrarActualizarEstado = false;
      this.permisosProyectoService
        .getPermisosFormPreyectoAndUsu(
          this.interfazInicial.proyaux.id,
          this.currentUser.id
        )
        .pipe(
          finalize(() => {
            this.mostrarEstados();
          })
        )
        .subscribe({
          next: (data) => {
            this.tipoUsuario = data.tipoUsu;
          },
        });
    }
  }

  mostrarEstados() {
    if (
      this.interfazInicial.currentUser.id == this.ordenCompraAux.solicitante.id
    ) {
      this.mostrarEnviar = true;
    }
    if (this.ordenCompraAux.aceptado_director_produccion.id == 2) {
      if (this.tipoUsuario.id == 2 && this.ordenCompraAux.estado.id != 4) {
        this.mostrarActualizarEstado = true;
      }

      this.altEnviar = this.altEnviar + 'director producción, ';
    } else if (this.ordenCompraAux.aceptado_director_produccion.id == 1) {
      if (this.tipoUsuario.id == 2) {
        this.mostrarAceptado = true;
      }
    } else if (this.ordenCompraAux.aceptado_director_produccion.id == 3) {
      if (this.tipoUsuario.id == 2) {
        this.mostrarRechazado = true;
      }
    } else if (this.ordenCompraAux.aceptado_director_produccion.id == 4) {
      if (this.tipoUsuario.id == 2 && this.ordenCompraAux.estado.id != 4) {
        this.mostrarActualizarEstado = true;
      }
      this.altEnviar = this.altEnviar + 'director producción, ';
    }

    if (this.ordenCompraAux.aceptado_jefe_departamento.id == 2) {
      if (this.tipoUsuario.id == 5 && this.ordenCompraAux.estado.id != 4) {
        this.mostrarActualizarEstado = true;
      }
      this.altEnviar = this.altEnviar + 'jefe de departamento, ';
    } else if (this.ordenCompraAux.aceptado_jefe_departamento.id == 1) {
      if (this.tipoUsuario.id == 5) {
        this.mostrarAceptado = true;
      }
    } else if (this.ordenCompraAux.aceptado_jefe_departamento.id == 3) {
      if (this.tipoUsuario.id == 5) {
        this.mostrarRechazado = true;
      }
    } else if (this.ordenCompraAux.aceptado_jefe_departamento.id == 4) {
      if (this.tipoUsuario.id == 5 && this.ordenCompraAux.estado.id != 4) {
        this.mostrarActualizarEstado = true;
      }
      this.altEnviar = this.altEnviar + 'jefe de departamento, ';
    }

    if (this.ordenCompraAux.aceptado_jefe_contabilidad.id == 2) {
      if (this.tipoUsuario.id == 3 && this.ordenCompraAux.estado.id != 4) {
        this.mostrarActualizarEstado = true;
      }
      this.altEnviar = this.altEnviar + 'jefe de contabilidad, ';
    } else if (this.ordenCompraAux.aceptado_jefe_contabilidad.id == 1) {
      if (this.tipoUsuario.id == 3) {
        this.mostrarAceptado = true;
      }
    } else if (this.ordenCompraAux.aceptado_jefe_contabilidad.id == 3) {
      if (this.tipoUsuario.id == 3) {
        this.mostrarRechazado = true;
      }
    } else if (this.ordenCompraAux.aceptado_jefe_contabilidad.id == 4) {
      if (this.tipoUsuario.id == 3 && this.ordenCompraAux.estado.id != 4) {
        this.mostrarActualizarEstado = true;
      }
      this.altEnviar = this.altEnviar + 'jefe de contabilidad, ';
    } else {
      this.mostrarActualizarEstado = false;
    }
  }

  /**
   * Compara dos objetos para verificar si son iguales basándose en su propiedad `id`.
   *
   * @param obj1 - El primer objeto a comparar.
   * @param obj2 - El segundo objeto a comparar.
   * @returns Devuelve `true` si los objetos son iguales basándose en su propiedad `id`, de lo contrario devuelve `false`.
   */
  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id;
  }

  /**
   * Seleciona un proveedor basado en el nombre fiscal.
   *
   * @param event - El evento que desencadena la selección.
   */
  selectProveedorNombre(event: any) {
    this.proveedores.forEach((element: Proveedor) => {
      if (element.nombreFiscal == event.option.value) {
        this.proveedor = element;
        this.formProvedor.get('nombreComercial')?.disable();
        this.formProvedor.get('personaContacto')?.disable();
        this.formProvedor.get('email')?.disable();
        this.formProvedor.get('direccion')?.disable();
        this.formProvedor.get('codigoPostal')?.disable();
        this.formProvedor.get('ciudad')?.disable();
        this.formProvedor.get('iban')?.disable();
        this.formProvedor.get('telefono')?.disable();
        this.formProvedor.get('web')?.disable();

        this.tipoDocumentosProv.forEach((element: any) => {
          if (this.checkTipoDoc(element) == false) {
            this.mensajeDocumentosProveedor = 'Falta incluir los certificados y documentos';
          }
        });

        if (
          !this.proveedor.nombreComercial ||
          !this.proveedor.personaContacto ||
          !this.proveedor.email ||
          !this.proveedor.direccion ||
          !this.proveedor.direccion ||
          !this.proveedor.bancoIban ||
          !this.proveedor.telefonos
        ) {
          this.modalDataNoComplete = true;
        }
      }
    });
  }

  selectCuenta(event: any) {
    let s: String = event.option.value;
    this.codigosSelects.forEach((element: any) => {
      element.forEach((element2: any) => {
        if (element2.cuenta == s.substring(0, s.indexOf(' - '))) {
          this.cuentaSeleccionada = element2;
        }
      });
    });
    this.conceptos.get('cuentaConcepto')?.value;
  }

  /**
   * Seleciona un proveedor basado en el número de identificación fiscal (NIF).
   *
   * @param event - El evento que desencadena la selección.
   */
  selectProveedor(event: any) {
    this.proveedores.forEach((element: Proveedor) => {
      if (element.cif == event.option.value) {
        this.proveedor = element;
        this.formProvedor.get('nombreComercial')?.disable();
        this.formProvedor.get('personaContacto')?.disable();
        this.formProvedor.get('email')?.disable();
        this.formProvedor.get('direccion')?.disable();
        this.formProvedor.get('codigoPostal')?.disable();
        this.formProvedor.get('ciudad')?.disable();
        this.formProvedor.get('iban')?.disable();
        this.formProvedor.get('telefono')?.disable();
        this.formProvedor.get('web')?.disable();
        if (
          !this.proveedor.nombreComercial ||
          !this.proveedor.personaContacto ||
          !this.proveedor.email ||
          !this.proveedor.direccion ||
          !this.proveedor.direccion ||
          !this.proveedor.bancoIban ||
          !this.proveedor.telefonos
        ) {
          this.modalDataNoComplete = true;
        }
      }
    });
  }

  /**
   * Filtra una lista de cuentas basándose en un valor proporcionado.
   *
   * @param value - El valor utilizado para filtrar la lista de cuentas.
   * @returns Una lista filtrada de cuentas.
   */
  filter(value: string): Cuenta[] {
    var filterValue: any;
    try {
      filterValue = value.toLowerCase();
      if (filterValue.includes('-')) {
        filterValue = '';
      }
    } catch {
      filterValue = '';
    }

    return this.codigos.filter(
      (option) =>
        option.cuenta.toLowerCase().includes(filterValue) ||
        (option.concepto && option.concepto.toLowerCase().includes(filterValue))
    );
  }
  valueProv: any = '';

  /**
   * Filtra una lista de proveedores basándose en un valor proporcionado.
   *
   * @returns Una lista filtrada de proveedores.
   */
  filterProveedor() {
    return this.proveedores.filter(
      (option: Proveedor) =>
        option.cif.toLowerCase().includes(this.valueProv) ||
        option.nombreFiscal.toLowerCase().includes(this.valueProv)
    );
  }

  /**
   * Filtra los proveedores o por nif o por nombre.
   *
   * @param event - El evento que desencadena el filtrado.
   */
  filterCols(event: any) {
    try {
      this.valueProv = event.target.value.toLowerCase().replaceAll(' ', '');
    } catch {
      this.valueProv = '';
    }
    if (this.filterProveedor().length == 0) {
      this.createNewProveedor = true;
      this.formProvedor.get('nombreComercial')?.enable();
      this.formProvedor.get('personaContacto')?.enable();
      this.formProvedor.get('email')?.enable();
      this.formProvedor.get('direccion')?.enable();
      this.formProvedor.get('codigoPostal')?.enable();
      this.formProvedor.get('ciudad')?.enable();
      this.formProvedor.get('iban')?.enable();
      this.formProvedor.get('telefono')?.enable();
      this.formProvedor.get('web')?.enable();
      /*  this.formProvedor.get("personaContacto")?.setValue("")
          this.formProvedor.get("email")?.setValue("")
          this.formProvedor.get("direccion")?.setValue("")
          this.formProvedor.get("codigoPostal")?.setValue("")
          this.formProvedor.get("iban")?.setValue("")
          this.formProvedor.get("ciudad")?.setValue("")
          this.formProvedor.get("telefono")?.setValue("")
          this.formProvedor.get("web")?.setValue("") 
         this.formProvedor.get("nombreFiscal")?.setValue("")
          this.formProvedor.get("cif")?.setValue("")  */
    } else {
      this.createNewProveedor = false;
    }
  }

  /**
   * Agrega unidades a la orden de compra (genera la segunda de dos que hay).
   */
  addunidadesOc(): void {
    const metadato = this.formBuilder.group({
      tipoUnidad: new FormControl({
        value: this.tiposUnidades[1].nombre,
        disabled: true,
      }),
      unidades: new FormControl({ value: '', disabled: this.isReading }),
    });

    this.unidadesOc.push(metadato);
  }

  removeunidadesOc(index: number): void {
    this.unidadesOc.removeAt(index);
  }

  /**
   * Agrega una fecha de pago a la orden de compra (al form array).
   */
  addFechaPago(): void {
    this.fechasPago.push(
      new FormControl({ value: '', disabled: this.isReading }, [
        Validators.required,
      ])
    );
  }

  /**
   * Maneja el cambio en el tipo de pago de la orden de compra.
   */
  changeTipoPago() {
    this.fechasPago.clear();
    if (
      this.pagoOc.controls['tipoPagoOc'].value.id == 1 ||
      this.pagoOc.controls['tipoPagoOc'].value.id == 4
    ) {
      this.pagoOc.addControl('periodicidadPago', Validators.required);
      this.pagoOc.get('periodicidadPago')?.setValue('');
      this.pagoOc.addControl('numeroPagos', Validators.required);
      this.pagoOc.get('numeroPagos')?.setValue('');
      this.pagoOc.addControl('fechaInicio', Validators.required);
      this.pagoOc.get('fechaInicio')?.setValue('');
    }
    if (
      this.pagoOc.controls['tipoPagoOc'].value.id != 1 &&
      this.pagoOc.controls['tipoPagoOc'].value.id != 4
    ) {
      this.pagoOc.get('periodicidadPago')?.setValue('');
      this.pagoOc
        .get('periodicidadPago')
        ?.removeValidators(Validators.required);
      this.pagoOc.get('numeroPagos')?.setValue('');
      this.pagoOc.get('numeroPagos')?.removeValidators(Validators.required);
      this.pagoOc.get('fechaInicio')?.setValue('');
      this.pagoOc.get('fechaInicio')?.removeValidators(Validators.required);
    }
  }
  /**
   * Elimina una fecha de pago de la orden de compra (del form array) basándose en el índice proporcionado.
   *
   * @param index - El índice de la fecha de pago a eliminar.
   */
  removeFechaPago(index: number): void {
    this.fechasPago.removeAt(index);
  }

  /**
   * Calcula la suma de los precios de los conceptos en la orden de compra.
   *
   * @returns La suma de los precios de los conceptos en la orden de compra.
   */
  getSuma(): number {
    var auxUnidades: number = 1;
    this.unidadesOc.controls.forEach((element) => {
      if (element.value.unidades)
        auxUnidades = auxUnidades * parseFloat(element.value.unidades);
    });
    this.conceptos
      .get('suma')
      ?.setValue(auxUnidades * this.conceptos.get('precio')?.value);
    return (auxUnidades * this.conceptos.get('precio')?.value) as number;
  }

  getConversion(): number {
    this.conceptos
      .get('conversion')
      ?.setValue(this.getSuma() * this.conceptos.get('cambio')?.value);
    return this.getSuma() * this.conceptos.get('cambio')?.value;
  }

  /**
   * Obtiene el valor de un tipo específico de descuento o impuesto en la orden de compra.
   *
   * @param tipo - El tipo de descuento o impuesto del cual obtener el valor.
   * @returns El valor del descuento o impuesto especificado.
   */
  get(tipo: string): number {
    var auxUnidades: number = 1;
    this.unidadesOc.controls.forEach((element) => {
      if (element.value.unidades)
        auxUnidades = auxUnidades * parseFloat(element.value.unidades);
    });
    return (
      ((this.getConversion() * this.conceptos.get(tipo)?.value) as number) / 100
    );
  }

  /**
   * Calcula el total de la orden de compra.
   *
   * @returns El total de la orden de compra.
   */
  getTotal() {
    return this.getConversion() + this.get('iva') - this.get('irpf');
  }
  lengyh = 0;

  /**
   * Agrega un nuevo concepto a la orden de compra.
   */
  addConcepto() {
    const formValues: any = {};

    // Iterar sobre los controles dentro del FormArray
    this.unidadesOc.controls[1].get('tipoUnidad')?.disable();
    if (this.conceptos.valid) {
      this.unidadesOc.controls[1].get('unidades')?.enable();

      if (this.isFirst) {
        this.displayedColumns = Object.keys(
          new ConceptosOCListar(this.conceptos.value)
        );
        this.displayedColumns.push('acciones');
        this.displayedColumns.splice(this.displayedColumns.indexOf('irpf'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('impuesto'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('unidadCantidad'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('tipoImpuesto'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('moneda'),1)
        this.displayedColumns.splice(this.displayedColumns.indexOf('cambio'),1)
        this.isFirst = false;
      }
      var auxConceptoGardar: ConceptosOC = new ConceptosOC(
        this.conceptos.value
      );
      auxConceptoGardar.fechaFin = this.fechaService.type(
        this.conceptos.get('fechaFin')?.value
      );
      auxConceptoGardar.fechaInicio = this.fechaService.type(
        this.conceptos.get('fechaInicio')?.value
      );
      auxConceptoGardar.codigo = this.conceptos.get('cuentaConcepto')?.value;
      this.listConceptosStore.push(auxConceptoGardar);

      var auxConcepto: ConceptosOC = new ConceptosOC(this.conceptos.value);
      auxConcepto.fechaFin = this.fechaService.type(
        this.conceptos.get('fechaFin')?.value
      );
      auxConcepto.fechaInicio = this.fechaService.type(
        this.conceptos.get('fechaInicio')?.value
      );
      if (this.cuentaSeleccionada == undefined) {
        this.cuentaSeleccionada = auxConcepto.codigo;
      }
      auxConcepto.codigo = {
        id: this.cuentaSeleccionada.id,
      };
      if (this.indexUpdate == -1) {
        this.listConceptos.push(auxConcepto);
      } else {
        this.listConceptos[this.indexUpdate] = auxConcepto;
      }

      var auxListar: ConceptosOCListar = new ConceptosOCListar(
        this.conceptos.value
      );
      auxListar.cuentaConcepto = this.cuentaSeleccionada;
      //  auxListar.base = this.getTotal();
      auxListar.fechaInicio = this.fechaService.type(
        this.conceptos.get('fechaInicio')?.value
      );
      auxListar.fechaFin = this.fechaService.type(
        this.conceptos.get('fechaFin')?.value
      );
      auxListar.tipoCantidad =
        this.unidadesOc.controls[0].get('tipoUnidad')?.value.nombre;

      if (this.indexUpdate == -1) {
        this.listConceptosListar.push(auxListar);
      } else {
        this.listConceptosListar[this.indexUpdate] = auxListar;
        this.indexUpdate = -1;
      }
      this.dataSource.data = this.listConceptosListar;
      this.lengyh = (this.dataSource as any).length;

      this.getTotales();
      this.conceptos.reset();

      this.unidadesOc.controls[1].get('tipoUnidad')?.setValue('Unidades');
      this.unidadesOc.controls[1].get('tipoUnidad')?.disable();
      this.conceptos.get('moneda')?.setValue('Euro');
      this.conceptos.get('cambio')?.setValue(1);
    } else {
      this.unidadesOc.controls[1].get('tipoUnidad')?.disable();

      Object.keys(this.conceptos.controls).forEach((field) => {
        const control = this.conceptos.get(field);
        control?.markAsTouched({ onlySelf: true });
        control?.markAsDirty({ onlySelf: true });
      });

      this.notificationService.warning(
        'ALERTA',
        'Rellene todos los campos.',
        5000
      );
    }
    this.onFormChange();
  }

  /**
   * Convierte el primer carácter de un texto en mayúscula.
   *
   * @param text - El texto al cual convertir el primer carácter en mayúscula.
   * @returns El texto con el primer carácter convertido en mayúscula.
   */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  /**
   * Actualiza un concepto en la orden de compra.
   *
   * @param concepto - El concepto a actualizar.
   * @param index - El índice del concepto a actualizar.
   */
  updateConcepto(concepto: ConceptosOCListar, index: any, ver: boolean) {
    this.isConceptoExpanded='in'
    this.conceptos.get('tipoConceptoOc')?.setValue(concepto.tipo);
    this.conceptos.get('descripcion')?.setValue(concepto.descripcion);
    this.conceptos.get('cuentaConcepto')?.setValue(concepto.cuentaConcepto);
    this.conceptos.get('tipoImpuesto')?.setValue(concepto.tipoImpuesto);
    this.conceptos.get('lugar')?.setValue(concepto.lugar);
    this.conceptos.get('moneda')?.setValue(concepto.moneda);
    this.conceptos.get('cambio')?.setValue(concepto.cambio);
    this.conceptos.get('conversion')?.setValue(concepto.baseConversion);
    this.conceptos
      .get('fechaInicio')
      ?.setValue(this.fechaService.date(concepto.fechaInicio));
    this.conceptos
      .get('fechaFin')
      ?.setValue(this.fechaService.date(concepto.fechaFin));
    this.conceptos.get('precio')?.setValue(concepto.precio);
    this.conceptos.get('irpf')?.setValue(concepto.irpf);
    this.conceptos.get('iva')?.setValue(concepto.impuesto);
    var unid = new FormArray([
      new FormGroup({
        tipoUnidad: new FormControl({
          value: concepto.tipoCantidad,
          disabled: this.isReading,
        }),
        unidades: new FormControl(
          { value: concepto.cantidad, disabled: true },
          Validators.required
        ),
      }),
    ]);
    unid.push(
      new FormGroup({
        tipoUnidad: new FormControl({
          value: concepto.unidadCantidad,
          disabled: this.isReading,
        }),
        unidades: new FormControl(
          { value: concepto.unidad, disabled: this.isReading },
          Validators.required
        ),
      })
    );
    this.tiposUnidades.forEach((element) => {
      if (element.nombre == concepto.tipoCantidad) {
        this.unidadesOc.controls[0].get('tipoUnidad')?.setValue(element);
      }
    });
    this.unidadesOc.controls[1].get('tipoUnidad')?.setValue('Unidades');

    this.unidadesOc.controls[1].get('unidades')?.setValue(concepto.unidad);
    this.unidadesOc.controls[0].get('unidades')?.setValue(concepto.cantidad);
    //this.removeAtIndex(index);
    if (!ver) {
      console.log(concepto)
      this.totalBase = this.totalBase - concepto.baseConversion;
      this.totalIrpf = this.totalIrpf - concepto.irpf/100 *concepto.baseConversion;
      this.totalIva = this.totalIva - concepto.impuesto /100 *concepto.baseConversion;
      this.totalOC =
        this.totalOC -
        (concepto.baseConversion +  concepto.impuesto /100 *concepto.baseConversion - concepto.irpf/100 *concepto.baseConversion);

      this.totalpendientepago =
        this.totalpendientepago -
        (concepto.baseConversion + concepto.impuesto /100 *concepto.baseConversion - concepto.irpf/100 *concepto.baseConversion);
      this.indexUpdate = index;
    } else {
      this.getSuma();
    }
  }

  /**
   * Elimina un concepto de la orden de compra basándose en el índice proporcionado.
   *
   * @param index - El índice del concepto a eliminar.
   */
  removeAtIndex(index: any) {
    var con = this.listConceptosListar[index];
    this.listConceptos.splice(index, 1);
    this.listConceptosListar.splice(index, 1);
    var unidades = parseInt(con.cantidad) * parseInt(con.unidad);

    if(this.indexUpdate==-1){
      var base = (con.precio as number) * unidades;
      var irpf = base * (con.irpf / 100);
      var iva = base * (con.impuesto / 100);
      this.totalBase = this.totalBase - base;
      this.totalIrpf = this.totalIrpf - irpf;
      this.totalIva = this.totalIva - iva;
      this.totalOC = this.totalOC - (base + iva - irpf);
      this.totalpendientepago = this.totalpendientepago - (base + iva - irpf);
    }

    this.dataSource.data = this.listConceptosListar;
  }

  /**
   * Calcula los totales de la orden de compra.
   */
  getTotales() {
    this.totalBase = this.getSuma() + this.totalBase;
    this.totalIrpf = this.get('irpf') + this.totalIrpf;
    this.totalIva = this.get('iva') + this.totalIva;
    this.totalOC = this.getTotal() + this.totalOC;
    this.totalpendientepago = this.totalpendientepago + this.getTotal();
  }

  /**
   * Obtiene las fechas de pago para la orden de compra en caso de que sea un tipo autoogenerado.
   */
  getFechasPago() {
    if (
      this.pagoOc.get('fechaInicio')?.value &&
      this.pagoOc.get('periodicidadPago')?.value &&
      this.pagoOc.get('numeroPagos')?.value
    ) {
      this.oredenCompraService
        .postFechasPagosOC(
          this.pagoOc.get('periodicidadPago')?.value,
          this.pagoOc.get('numeroPagos')?.value,
          this.fechaService.type(this.pagoOc.get('fechaInicio')?.value)
        )
        .subscribe({
          next: (data: any) => {
            this.fechasPago.clear();
            data.forEach((da: any) => {
              this.fechasPago.push(
                new FormControl({ value: da, disabled: true })
              );
            });
            this.onFormChange();
          },
          error: (err) => {},
        });
    } else {
      this.fechasPago.clear();
    }
  }

  /**
   * Obtiene los plazos de pago para la orden de compra.
   */
  getPlazos() {
    if (this.pagoOc.get('numeroPagos')?.value) {
      this.fechasPago.clear();
      for (
        let index = 0;
        index < parseInt(this.pagoOc.get('numeroPagos')?.value);
        index++
      ) {
        this.fechasPago.push(
          new FormControl({ value: '', disabled: this.isReading })
        );
      }
    } else {
      this.fechasPago.clear();
    }
  }

  /**
   * Verifica si el tipo de pago de la orden de compra es Domiciliado.
   *
   * @returns `true` si el tipo de pago es Domiciliado, de lo contrario devuelve `false`.
   */
  checkTipoPagoOC() {
    if (this.pagoOc.get('tipoPagoOc')?.value.id == 1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Establece una fecha única para la orden de compra.
   *
   * @param date - La fecha única a establecer.
   */
  setFechaUnica(date: any) {
    this.fechasPago.clear();
    this.fechasPago.push(new FormControl({ value: date, disabled: true }));
    this.onFormChange();
  }

  /**
   * Establece las fechas de pago basándose en el control y el índice proporcionados.
   *
   * @param control - El control que contiene la fecha a establecer.
   * @param index - El índice de la fecha de pago a establecer.
   */
  setFechPlazos(control: any, index: any) {
    control.value = this.fechaService.type(control.value);
    this.fechasPago.controls[index] = control;
    this.fechasPago.value[index] = control.value;
  }

  /**
   * Maneja el cambio en el archivo proporcionado.
   *
   * @param event - El evento que contiene el archivo.
   * @param index - El índice del archivo que se está cambiando.
   */
  onFileChange(event: any, index: number) {
    if (
      event.target.files[0].type == 'application/pdf' ||
      event.target.files[0].type == 'image/png' ||
      event.target.files[0].type == 'image/jpeg'
    ) {
      const file = event.target.files[0];
      const bits = file.size * 8;
      const bytes = bits / 8;
      const kb = bytes / 1000;
      const mb = kb / 1000;
      if(mb < 20){
        this.convertFile(file).subscribe((base64) => {
          this.listDocumentos[index].nombre = event.target.files[0].name;
          this.listDocumentos[index].mime = event.target.files[0].type;
          this.listDocumentos[index].base64 = base64;
        });
      }
      else{
        this.notificationService.warning(
          'Información',
          'Solo se admite documnetos con tamaño menor a 20 MB.',
          5000
        );
      }
    } else {
      this.notificationService.warning(
        'Información',
        'Solo se admiten archivos del tipo: PDF, PNG y JPEG.',
        5000
      );
    }
  }

  /**
   * Convierte un archivo a su representación base64.
   *
   * @param file - El archivo a convertir.
   * @returns Una cadena base64 que representa el archivo convertido.
   */
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  /**
   * Maneja el cambio en el tipo de documento proporcionado.
   *
   * @param tipo - El tipo de documento que se está cambiando.
   * @param index - El índice del documento que se está cambiando.
   */
  changeTipoDoc(tipo: any, index: any) {
    this.listDocumentos[index].tipo = tipo;
  }

  changeDescr(descripcion: any, index: any) {
    this.listDocumentos[index].descripcion = descripcion.target.value;
  }

  /**
   * Maneja el envío del formulario de la orden de compra.
   */
  onSubmit() {
    this.isSave = false;
    if (this.form.controls['departamento'].value == undefined) {
      if (this.deparamento != undefined) {
        this.form.controls['departamento'].setValue({
          id: this.deparamento.id,
        });
      } else {
        this.notificationService.warning(
          'ERROR',
          'El usuario no tiene un departamento asignado.',
          5000
        );
      }
    }
    let proveedorAux: any;

    if (this.proveedor != undefined) {
      this.form.controls
      proveedorAux = this.form.controls['proveedor'].value;
      
    } else {
      this.notificationService.warning(
        'ERROR',
        'Selecciona un proveedor.',
        5000
      );
    }
    if (!this.isReading) {
      var pagoOc = new PagoOC(this.pagoOc?.value);

      pagoOc.fechasPago = this.pagoOc.get('fechasPago')?.value;
      pagoOc.fechaFianza = this.fechaService.type(
        this.pagoOc.get('fechaFianza')?.value
      );
      if (pagoOc.tipoPagoOc && pagoOc.tipoPagoOc.id != 1) {
        pagoOc.periodicidadPago = undefined;
      }

      this.form.get('cuentaConcepto')?.setValue(this.cuentaSeleccionada);
      this.form.get('pagoOc')?.setValue(pagoOc);
      this.form.get('conceptos')?.setValue(this.listConceptos);
      this.form.get('documentos')?.setValue(this.listDocumentos);
      this.form
        .get('proyecto')
        ?.setValue({ id: this.interfazInicial.proyaux.id });
      this.form.get('proveedor')?.setValue({ id: this.proveedor.id });
      if (
        this.form.valid &&
        this.listConceptos.length != 0 &&
        this.pagoOc.valid &&
        this.form.get('proveedor')?.value.id!= undefined
      ) {
        if (this.listDocumentos[0].nombre == undefined) {
          this.notificationService.warning(
            'ALERTA',
            'Esta orden de compra se creará sin ningún documento.',
            5000
          );
        }
        if (!this.isUpdate) {
          this.loading = true;
          this.unidadesOc.controls[1]
            .get('tipoUnidad')
            ?.setValue(this.tiposUnidades[1]);
          this.oredenCompraService
            .save({
              ordenCompra: this.form.value,
              documentos: this.listDocumentos,
            })
            .subscribe({
              next: (data) => {
                this.loading = false;

                this.storageService.eliminar('ocForm');
                this.ordenCompraAux = data;
                this.ordenCompraAux.gastos = [];
                this.mostrarEnviar=true;
                this.isReading=true;
                this.id = this.ordenCompraAux.id;
                this.notificationService.success(
                  'EXITO',
                  'Orden de compra guardada.',
                  5000
                );
                
              },
              error: (err) => {
                this.unidadesOc.controls[1]
                  .get('tipoUnidad')
                  ?.setValue(this.tiposUnidades[1].nombre);
                this.loading = false;
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning(
                    'ERROR',
                    err.error[Object.keys(err.error)[0]],
                    5000
                  );
                } else {
                  this.notificationService.warning(
                    'ERROR',
                    'Ha ocurrido un error.',
                    5000
                  );
                }
              },
            });
        } else {
          this.loading = true;
          this.unidadesOc.controls[1]
            .get('tipoUnidad')
            ?.setValue(this.tiposUnidades[1]);
          let listaDocumentosAux: any = [];
          this.listDocumentos.forEach((element: any) => {
            element = {
              id: element.id,
              mime: element.mime,
              nombre: element.nombre,
              tipo: element.tipo,
              base64: element.base64,
            };
            listaDocumentosAux.push(element);
          });
          this.oredenCompraService
            .update(this.id, {
              ordenCompra: this.form.value,
              documentos: listaDocumentosAux,
            })
            .subscribe({
              next: (data) => {
                this.loading = false;
                this.notificationService.success(
                  'ÉXITO',
                  'Orden de compra actualizada.',
                  5000
                );
                if(this.dialogUpdate){
                  this.ordenCompraAux = data
                  this.mostrarEnviar=true;
                  this.isReading=true;
                  this.dialogUpdate = false;
                }
                else{
                  this.router.navigate(['admin-contacine/orden-compra']);
                }
              },
              error: (err) => {
                this.unidadesOc.controls[1]
                  .get('tipoUnidad')
                  ?.setValue(this.tiposUnidades[1].nombre);

                this.loading = false;
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning(
                    'ERROR',
                    err.error[Object.keys(err.error)[0]],
                    5000
                  );
                } else {
                  this.notificationService.warning(
                    'ERROR',
                    'Ha ocurrido un error.',
                    5000
                  );
                }
              },
            });
        }

      } else {
        if (this.form.get('proveedor')?.value.id== undefined) {
          this.notificationService.warning(
            'ALERTA',
            'Seleccione un proveedor.',
            5000
          );
        }else if(this.listConceptos.length == 0) {
          Object.keys(this.conceptos.controls).forEach((field) => {
            const control = this.conceptos.get(field);
            control?.markAsTouched({ onlySelf: true });
            control?.markAsDirty({ onlySelf: true });
          });
          this.notificationService.warning(
            'ALERTA',
            'Introduzca algún concepto.',
            5000
          );
        } else if (!this.pagoOc.valid) {
          Object.keys(this.pagoOc.controls).forEach((field) => {
            const control = this.pagoOc.get(field);
            control?.markAsTouched({ onlySelf: true });
            control?.markAsDirty({ onlySelf: true });
          });
          this.notificationService.warning(
            'ALERTA',
            'Introduzca la forma de pago.',
            5000
          );
        } else {
          this.notificationService.warning(
            'ALERTA',
            'Rellene todos los campos.',
            5000
          );
        }
      }
    } else {
      this.router.navigate(['admin-contacine/orden-compra']);
    }
    this.form.get('proveedor')?.setValue(proveedorAux);
  }

  /**
   * Obtiene el nombre de un documento.
   *
   * @param doc - El documento del cual obtener el nombre.
   * @returns El nombre del documento.
   */
  getNombreDoc(doc: any): any {
    return doc.nombre;
  }

  /**
   * Visualiza un documento en una nueva ventana.
   *
   * @param doc - El documento a visualizar.
   */
  viewDoc(doc: any) {
    const byteArray = new Uint8Array(
      atob(doc.base64)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const opcionesVentana = 'width=800,height=600,scrollbars=yes';
    window.open(url, '_blank', opcionesVentana);
  }

  /**
   * Descarga un documento.
   *
   * @param doc - El documento a descargar.
   */
  downloadDoc(doc: any) {
    const linkSource = 'data:application/pdf;base64,' + doc.b64;
    const downloadLink = document.createElement('a');
    const fileName = doc.nombre;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  /**
   * Vincula una orden de compra con un gasto.
   *
   * @param doc - El documento de la orden de compra.
   */
  vincularOCGasto() {
    this.ocGastoService.setOc(this.ordenCompraAux);
    this.router.navigate(['admin-contacine/nuevo-gasto']);
  }

  /**
   * Visualiza un gasto asociado.
   *
   * @param gastoId - El ID del gasto asociado a visualizar.
   */
  verGAstoAsociado(gastoId: any) {
    this.router.navigate(['admin-contacine/ver-gasto/' + gastoId]);
  }

  /**
   * Navega para editar un elemento.
   *
   * @param id - El ID del elemento a editar.
   * @param accion - La acción a realizar en el elemento.
   */
  navegarAEditar(id: any, accion: any) {
    this.router.navigate([`admin-contacine/${accion}-gasto/` + id]);
  }

  download(element: any) {
    this.nextCloudService.getBase64(element.documento.id_nextcloud).subscribe({
      next: (data) => {
        const linkSource = 'data:application/pdf;base64,' + data.b64;
        const a = document.createElement('a');
        const fileName = 'Gasto_' + element.numeroFactura + '.pdf';
        a.href = linkSource;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
      },
    });
  }
  borrarArchivo(doc: any) {
    this.listDocumentos.splice(this.listDocumentos.indexOf(doc));
    if (this.listDocumentos.length == 0) {
      this.listDocumentos[0] = {};
    }
  }

  borrarElemento(id: any) {
    this.registroGastosService.deleteGasto(id).subscribe({
      next: (data) => {
        this.listaGastosAsociados.forEach((element) => {
          if (element.id == id) {
            this.listaGastosAsociados.slice(
              this.listaGastosAsociados.indexOf(element),
              this.listaGastosAsociados.indexOf(element) + 1
            );
          }
        });
        this.notificationService.success(
          'ÉXITO',
          'Gasto borrado correctamente.',
          5000
        );
      },
      error: (error) => {},
    });
  }

  checkTipoDoc(tipoDoc: any): boolean {
    if (this.proveedor.documentos) {
      return this.proveedor.documentos.some(
        (e: any) => tipoDoc.id === e.tipo.id
      );
    } else {
      return false;
    }
  }

  checkIsTa(tipo: any, index: any) {
    if (index == 0) {
      if (tipo.source.value.id == 5) {
        this.unidadesOc.controls[1].get('unidades')?.setValue(1);
        this.unidadesOc.controls[1].get('unidades')?.disable();
      } else {
        this.unidadesOc.controls[1].get('unidades')?.enable();
      }
    }
  }

  checkOptionUnidades(index: number): NomId[] {
    return this.tiposUnidades.filter((option) => {
      if (option.id == 2 && index == 0) {
        return false;
      } else {
        return true;
      }
    });
  }
  /**
   * Al clicar una tecla en los campos de nuemros detecta si es un . y lo cambia a , para los decimales
   * @param event evento de clickar
   * @param control control del form
   */
  changeDec(event: any, control: any): any {
    if (event.key == '.' && !control?.value.toString().includes(',')) {
      control.setValue(control?.value + ',');
    }
  }
  volver() {
    this.router.navigate(['admin-contacine/orden-compra']);
  }

  saveProveedor() {
    var prov: any = new Proveedor(this.formProvedor.value);
    prov.bancoIban = {
      nombreBanco: 'Incompleto',
      iban: this.formProvedor.controls['iban'].value,
    };
    prov.proyectos = [{ id: this.interfazInicial.proyaux.id }];
    prov.telefonos = [this.formProvedor.controls['telefono'].value];
    this.proveedorService
      .save({ proveedor: prov, documentos: null })
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.notificationService.success(
            'Guardado',
            'El proveedor se ha guardado correctamente.',
            5000
          );
          this.proveedores.push(data as Proveedor);
          this.proveedor = data as Proveedor;
          this.createNewProveedor = false;
          this.modalDataNoComplete = true;
        },
        error: (err) => {
          this.loading = false;
          if (err.error && Object.keys(err.error)[0]) {
            this.notificationService.warning(
              'ERROR',
              err.error[Object.keys(err.error)[0]],
              5000
            );
          } else {
            this.notificationService.warning(
              'ERROR',
              'Ha ocurrido un error.',
              5000
            );
          }
        },
      });
  }

  onFormChange() {
    if (!this.isUpdate && !this.isReading) {
      this.storageService.guardar('ocForm', JSON.stringify(this.form.value));
      this.storageService.guardar(
        'conceptosForm',
        JSON.stringify(this.listConceptosStore)
      );
      this.storageService.guardar(
        'formProvedor',
        JSON.stringify(this.proveedor)
      );
    }
  }

  limparFormulario() {
    this.conceptos.reset();
    this.formProvedor.reset();
    Object.keys(this.pagoOc.controls).forEach((field) => {
     console.log(field)
     if(field!="fechasPago"){
       this.pagoOc.controls[field].setValue("")
     }
    });

    if (this.pagoOc.controls['tipoPagoOc'].value.id == 1) {
      this.getFechasPago();
    }
    this.filtrarCuentasProyecto(undefined);

    this.form = new FormGroup({
      proveedor: new FormControl({ value: undefined, disabled: this.isReading }, [
        Validators.required,
      ]),
      conceptos: new FormControl({ value: '', disabled: this.isReading }),
      pagoOc: new FormControl({ value: '', disabled: this.isReading }),
      solicitante: new FormControl({
        value: { id: this.currentUser.id },
        disabled: this.isReading,
      }),
      departamento: new FormControl({
        value: this.deparamento,
        disabled: this.isReading,
      }),
      proyecto: new FormControl({
        value: { id: '' },
        disabled: this.isReading,
      }),
      aux: new FormControl({ value: '', disabled: true }),
    });
    console.log(6)

    this.isClearForm = false;
    this.storageService.eliminar('ocForm');
    this.listConceptosStore = [];
    this.listConceptosListar = [];
    this.dataSource.data = this.listConceptosListar;
    this.totalBase = 0;
    this.totalIva = 0;
    this.totalIrpf = 0;
    this.totalOC = 0;
    this.totalPagado = 0;
    this.totalpendientepago = 0;
    this.storageService.eliminar('conceptosForm');
    this.proveedor = new Proveedor();
    this.storageService.eliminar('formProvedor');
    this.formProvedor.reset();
  }

  enviarNotificacion() {
    if (this.loading == false) {
      this.loading = true;
      this.notificacionesService
        .send(
          this.tokenService.getUser().id,
          this.interfazInicial.proyaux.id,
          this.id
        )
        .subscribe({
          next: (data) => {
            this.ordenCompraAux = data;
            if(this.isUpdate){
              this.mostrarEstados();
              this.loading = false;
            }
            else{
              this.router.navigate(['admin-contacine/orden-compra']);
            }
            this.notificationService.success(
              'Enviado',
              'Se ha enviado el correo correctamente.',
              5000
            );
          },
          error: (error) => {
            this.loading = false;
            this.notificationService.warning(
              'ERROR',
              'Ha ocurrido un error.',
              5000
            );
          },
        });
    }
  }

  getConcepto(element: any): string {
    var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }

  checkEstadoAtu() {
    if (
      this.ordenCompraAux.estado.id == 3 ||
      this.ordenCompraAux.aceptado_director_produccion.id == 3 ||
      this.ordenCompraAux.aceptado_jefe_departamento.id == 3 ||
      this.ordenCompraAux.aceptado_jefe_contabilidad.id == 3 ||
      this.ordenCompraAux.aceptado_director_produccion.id == 1 ||
      this.ordenCompraAux.aceptado_jefe_departamento.id == 1 ||
      this.ordenCompraAux.aceptado_jefe_contabilidad.id == 1
    ) {
      this.dialogUpdate = true;
    } else {
      this.onSubmit();
    }
  }

  GoToPooveedor() {
    this.modalDataNoComplete = false;
    const datos = { dato: 'Hola desde Componente1' };
    this.proveedor.id;
    sessionStorage.setItem('ModificarProveedor', 'true');
    this.router.navigate([
      `admin-contacine/editar-proveedor/${this.proveedor.id}`,
    ]);
  }

  valueLug = '';
  filterLugar() {
    return this.lugares.filter((option: any) =>
      option.toLowerCase().includes(this.valueLug)
    );
  }

  filterColsLugar(event: any) {
    try {
      this.valueLug = event.target.value.toLowerCase();
    } catch {
      this.valueLug = '';
    }
  }
  CambiarIva() {
    this.PlaceHolderIva = this.conceptos.get('tipoImpuesto')?.value;
  }

  AbrirDialogProveedor() {
    this.modalDataNoComplete = false;

  }

  async generarPDF() {
    this.isProveedorExpanded = 'in';
    this.isConceptoTotalExpanded = 'in';
    this.isConceptoExpanded = 'out';
    this.isFileProveedortoExpanded = 'out';
    this.isFormaDePagoExpanded = 'in';
    this.isFacturaExpanded = 'in';
    this.isFileExpanded = 'in';
    this.isFileProveedortoExpanded = 'in';
    this.isExporting = true;
    this.loading = true;
    setTimeout(async () => {
      const componente = document.getElementById('correccion');
      try {
        if (componente != null) {
          await this.pdfService.generatePdf(componente, '0');
          this.loading = false;
          this.isExporting = false;
        }
      } catch {}
    }, 2000);
  }

  aniadirOCNumber(auxOrden: any): String {
    if(auxOrden){
      let num: string = auxOrden.numOc + '';
      for (let i = num.length; i < 4; i++) {
        num = 0 + num;
      }
      return ': OC' + num;
    }
    return ''
  }

  cambiarProductora(tipo: any) {
    this.form.controls['productora'].setValue({ id: tipo.value.id });
  }

  getAllProductoras() {
  
    let prodFilter = {
      filters: [
        {
          field_type: 'STRING',
          key: 'proyectos.id',
          operator: 'CONTAINS_ID',
          value: this.interfazInicial.proyaux.id,
        },
      ],
      page: '0',
      size: '',
      sorts: [],
    };
    this.productoraService.filter(prodFilter).subscribe({
      next: (data) => {
        this.productoras = data.content;

        if (!this.productoras || this.productoras.length == 0) {
          this.notificationService.warning(
            'ALERTA',
            'No existen productoras para este proyecto.',
            3500
          );
        }
      },
      error: (error) => { },
    });
  }
}
