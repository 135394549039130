import { Proyecto } from "./proyecto"

export class Productora {
    id!: number
    fechaAlta!: String
    cif!: String
    nombre!: String
    direccion!: String
    ciudad!: String
    bancosIban!: BancosIban[]
    codigoPostal!: string
    proyectos!: any[]

    telefonos!: string[]
    seguridadSocial!: string
    pais!: string;

    public constructor(data?: any, init?: Partial<Productora>) {
        if (data) {
            this.id = data.id;
            this.fechaAlta = data.fechaAlta
            this.cif = data.cif;
            this.nombre = data.nombre;
            this.direccion = data.direccion;
            this.ciudad = data.ciudad;
            this.bancosIban = data.bancosIban
            this.codigoPostal = data.codigoPostal
            this.telefonos = data.telefonos
            this.proyectos = data.proyectos
            this.seguridadSocial = data.seguridadSocial
            this.pais = data.pais;
        } else {
            Object.assign(this, init);
        }


    }
}

export class ProductoraListar {

/*     id!: number
 */ cif!: String
    nombre!: String
    direccion!: String
    ciudad!: String
    banco!: string
    iban!: string
    telefonos!: string
    codigoPostal!: string
    seguridadSocial!: string
    pais!: string

    public constructor(data?: any, init?: Partial<ProductoraListar>) {
        if (data) {
/*             this.id = data.id;
 */         this.cif = data.cif;
            this.nombre = data.nombre;
            this.direccion = data.direccion;
            this.ciudad = data.ciudad;
            this.banco = data.bancosIban[0] ? data.bancosIban[0].banco : ""
            this.iban = data.bancosIban[0] ? data.bancosIban[0].iban : ""
            this.telefonos = data.telefonos
            this.codigoPostal = data.codigoPostal
            this.seguridadSocial = data.seguridadSocial
            this.pais = data.pais

        } else {
            Object.assign(this, init);
        }


    }
}
export class ProductoraExport {

   /*  id!: number */
    cif!: String
    nombre!: String
    direccion!: String
    ciudad!: String
    fechaAlta!: String
    bancosIban!: any[]
    telefonos!: any[]
    codigoPostal!: string
    seguridadSocial!: string
    pais!: string

    public constructor(data?: any, init?: Partial<ProductoraExport>) {
        if (data) {
           /*  this.id = data.id; */
            this.cif = data.cif;
            this.fechaAlta = data.fechaAlta

            this.nombre = data.nombre;
            this.direccion = data.direccion;
            this.ciudad = data.ciudad;
            this.bancosIban = data.bancosIban
            this.telefonos = []
            data.telefonos.forEach((element: any, index: number) => {
                this.telefonos.push({ id: index, telefono: element })
            });
            this.codigoPostal = data.codigoPostal
            this.seguridadSocial = data.seguridadSocial
            this.pais = data.pais

        } else {
            Object.assign(this, init);
        }


    }
}
export class ProductoraExportProy {

    id!: number
    cif!: String
    fechaAlta!: String
    nombre!: String
    direccion!: String
    ciudad!: String
    bancosIban!: string
    telefonos!: string
    codigoPostal!: string
    seguridadSocial!: string
    pais!: string

    public constructor(data?: any, init?: Partial<ProductoraExportProy>) {
        if (data) {
            this.id = data.id;
            this.cif = data.cif;
            this.nombre = data.nombre;
            this.direccion = data.direccion;
            this.ciudad = data.ciudad;
            this.fechaAlta = data.fechaAlta

            this.bancosIban = ""
            data.bancosIban.forEach((element: any) => {
                this.bancosIban = this.bancosIban + element.iban + ", "
            });
            this.telefonos = ""
            data.telefonos.forEach((element: any) => {
                this.telefonos = this.telefonos + element + ", "
            });
            this.codigoPostal = data.codigoPostal
            this.seguridadSocial = data.seguridadSocial
            this.pais = data.pais;

        } else {
            Object.assign(this, init);
        }


    }
}
export class BancosIban {
    id?: number
    iban!: String
    nombreBanco!: String

    public constructor(data?: any, init?: Partial<BancosIban>) {
        if (data) {
            this.id = data.id;
            this.iban = data.iban
            this.nombreBanco = data.nombreBanco;

        } else {
            Object.assign(this, init);
        }


    }
}