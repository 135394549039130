import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { finalize } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FechaService } from 'src/app/services/servicios-funcionalidad/fecha.service';

@Component({
  selector: 'app-registro-pagos',
  templateUrl: './registro-pagos.component.html',
  styleUrls: ['./registro-pagos.component.scss']
})
export class RegistroPagosComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  proyecto: any;
  filters: any[] = [];
  pageSize: any = 99999;
  page: any = 0;
  sorts: any[] = [];
  evento: any = '';
  rutaNuevo = '';
  nombreBoton:any="pagos"
  nombrePagina = 'REGISTRO DE PAGOS';
  mostrarCampos: { [clave: string]: boolean } = {};
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  gastosRecibidos: any;
  listaPagos : any = [];
  totalElements = 0
  displayedColumns: string[]= [
    'gasto',
    'fecha_pago',
    'pagado',
    'forma_pago',
    'proveedor',
    'cuenta_cargo',
    'numero_talon',
    'cuenta_destino',
    'descipcion',
    'cantidad',

  ];

  dataSource = new MatTableDataSource();
  displayedColumnscompleta: string[] = [];
  ordenarCampos: { [clave: string]: boolean } = {};
  canvas:any;


  constructor(
    private registroGastosService: RegistroGastosService,
    private router: Router,
    private filterService: FilterService,
    public interfazInicial: InterfazInicialComponent,
    public tableService: TableService,
    public fechaService: FechaService,


  ) { }
  ngOnInit(): void {

    this.proyecto = this.tableService.proy
    this.tableService.subscribeToProyectoChanges((proyectoActual: any) => {
      this.proyecto = proyectoActual;
      if (!this.interfazInicial.isSuperAdmin) {
        this.filters = [
          {
            field_type: "STRING",
            key: "gasto.proyecto.id",
            operator: "LIKE",
            value: this.proyecto.id
          }];
        this.filter.filters = this.filters
      }
      this.getGastos();
    });
    if (!this.interfazInicial.isSuperAdmin) {
      this.filters = [
        {
          field_type: "STRING",
          key: "gasto.proyecto.id",
          operator: "LIKE",
          value: this.proyecto.id
        }];
      this.filter.filters = this.filters
    }
    this.getGastos();
  }


  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.filter = {
        filters: [],
        page:  this.page,
        size: this.pageSize,
        sorts: this.sorts,
      };
      this.getGastos();
    } else {
      let operator = 'ALL_FIELDS_CLASS';

      if (this.evento != '') {
        this.filters=[{
          operator: operator,
          value: this.evento,  
          values:["TipoPago.nombre", "Gasto.id"],}]

        ;
        if(!this.interfazInicial.isSuperAdmin){
        this.filters.push( {field_type: "STRING",
        key: "gasto.proyecto.id",
        operator: "LIKE",
        value: this.proyecto.id})
        }
        else{
          this.filters=[]
        }
        this.filter = {
          filters: this.filters,
          page:  this.page,
          size: this.pageSize,
          sorts: this.sorts,
        };
        this.getGastos();
      } else {
        if(!this.interfazInicial.isSuperAdmin){
        this.filter = {
          filters: [ {field_type: "STRING",
          key: "gasto.proyecto.id",
          operator: "LIKE",
          value: this.proyecto.id}],
          page:  this.page,
          size: this.pageSize,
          sorts: this.sorts,
        }
        };
        this.getGastos();
      }
    }}
  getGastos() {
    this.listaPagos=[]
    this.registroGastosService
      .getPagos(this.filter)
      .pipe(
        finalize(() => {
          //this.actualizarCalendario()
          this.iniciarListas();
        })
      )

      .subscribe({
        next: (data: any) => {
          
          if (data != null) {
            this.listaPagos=data['pageList'];
          }

          this.dataSource=this.listaPagos
          this.totalElements = data.source.length
          this.dataSource.paginator = this.paginator;
        
          if (this.ordenarCampos['id'] == undefined) {
            this.paginator._intl = this.interfazInicial.paginator
            let nombre: string = '';
            if (data['source'].length != 0) {
              var mapa = Object.entries(data['source'][0]);
              mapa.forEach((element) => {
                nombre = element[0];
                this.ordenarCampos[nombre] = false;
              });
              
              this.ordenarCampos['proveedor.cif']=false;
              this.ordenarCampos['proveedor.nombreFiscal']=false
            }
          }

        
        },
        error: (error) => {
        },
      });

  }

  /*
  actualizarCalendario(){
    console.log("actualizar calendario")
    this.listaPagos.forEach((element:any) => {
      if(!element.pagado){
        this.onDateSelected(element.fecha_pago)
      }
    });
  }
*/
  ordenar(property: string, sorts:any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (property.includes(clave)) {
        this.ordenarCampos[clave] = !this.ordenarCampos[clave];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.filter.sorts= [{ key: property, direction: orden }];
  
    this.getGastos();
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    let valueBool=null
    if(type=='pagado'){
      if (event.target != null && event.target.value!="") {
       let value: string= event.target.value
       if(("pagado").includes((value.toLowerCase()))){
        valueBool=true
      }
      if(("pendiente").includes((value.toLowerCase()))){
        valueBool="false"
      }
    }}else{
      valueBool=null
    }
    this.filters = this.filterService.doFilter(event, type, operator, field_type, this.filters,valueBool)
    if (!this.interfazInicial.isSuperAdmin) {
      this.filter.filters = this.filters
      this.filters = [
        {
          field_type: "STRING",
          key: "gasto.proyecto.id",
          operator: "LIKE",
          value: this.proyecto.id
        }];
      this.filter.filters.push(this.filters[0])
    }
    this.getGastos()
  }

  iniciarListas() {
    this.displayedColumnscompleta = [];
    if (
      sessionStorage.getItem(this.nombrePagina) &&
      sessionStorage.getItem(this.nombrePagina) != 'undefined'
    ) {
      var a: any = sessionStorage.getItem(this.nombrePagina);
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {
      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }

    this.actualizarTabla();
  }

  /**
   * Actualiza la lista de columnas a ser mostradas en la tabla.
   */
  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }


  calcTextWidth(text: any, font: any) {
    let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 10;
  }
  nextPage(event: PageEvent) {
    this.page = event.pageIndex + "";
    this.pageSize = event.pageSize + "";

    this.getGastos();
  }
  

  navegarAGasto(id: any) {
    this.router.navigate([`admin-contacine/ver-gasto/${id}`]);
  }

  /*selectedDate: moment.Moment = moment(); 
  
  onDateSelected(date:any) {
    const selectedDate =  moment(this.fechaService.date(date)); 
 
    const dayElement = document.querySelector(`.mat-calendar-body-cell[aria-label="${selectedDate.format('MMMM D, YYYY')}"]`);
    let div1Name="#div"+date
    if(document.querySelector(div1Name)==null){
    const div = document.createElement('div');
    div.id = div1Name;
    div.style.display="flex"
    const div2 = document.createElement('div');
    div.appendChild(div2)
    div2.style.backgroundColor = 'red'
    div2.style.borderRadius="100%"
    div2.style.height="20px"
    div2.style.width="20px"
    div2.style.marginLeft="20px"
    div2.style.marginTop="40px"
    div2.textContent = '';
    dayElement?.appendChild(div);
    } else{
    const div = document.querySelector("#div1")
    const div2 = document.createElement('div');
    div?.appendChild(div2)
    div2.style.backgroundColor = 'red'
    div2.style.borderRadius="100%"
    div2.style.height="20px"
    div2.style.width="20px"
    div2.style.marginLeft="5px"
    div2.style.marginTop="40px"
    div2.textContent = '';
     }
  }
*/
  
}
