import { Component } from '@angular/core';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.scss'],
  animations: [dropdownAnimation]
})
export class PlantillasComponent {
  visible: string = "";

  ngOnInit() {
    this.visible = 'out';
  }

  visibility(): void {
    this.visible = this.visible === 'out' ? 'in' : 'out';
  }

}
