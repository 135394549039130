import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoMoneda } from '../models/proyecto';

@Injectable({
  providedIn: 'root'
})
export class TipoMonedaService {
  /* 
  Servicio para las peticiones con back para el listado de tipos de moneda
  */
  url: string = environment.API_URL + "/tipo-moneda";

  constructor(private http: HttpClient) { }

  getTiposMoneda(): Observable<TipoMoneda[]> {
    return this.http.get<TipoMoneda[]>(`${this.url}/list`);
  }
}
