<!-- <app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [(action)]="action" 
    [canImport]="!isReading&&isControllerOrDirProd" [canCreate]="isControllerOrDirProd"></app-header-listados> -->

<app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
  [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
  [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento" [(isImport)]="isForImport"
  [canCreate]="tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
  [canExport]="tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
  [dataExport]="dataExport"
  [canImport]="!tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
  [nombreBoton]="nombreBoton" [(isPasarACoste)]="isForCoste"></app-header-listados>

<div>


  <div class="fondoblanco fondoblanco_tabla">

    <div class="mat-elevation-z8  " class="table_container data_table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="nº">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="REG.Nº"
                (keyup)="doFilter($event, 'numRegistro', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('REG.Nº', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['numRegistro'] ? 'fechaup':'fechadawn'" (click)=" ordenar('numRegistro')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.numRegistro}} </td>
        </ng-container>

        <ng-container matColumnDef="tipo">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="TIPO"
                (keyup)="doFilter($event, 'tipo', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('TIPO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['tipo'] ? 'fechaup':'fechadawn'" (click)=" ordenar('tipo')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.tipo}} </td>
        </ng-container>

        <ng-container matColumnDef="dni">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="DNI"
                (keyup)="doFilter($event, 'dni', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('DNI', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['dni'] ? 'fechaup':'fechadawn'" (click)=" ordenar('dni')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.dni}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="NOMBRE"
                (keyup)="doFilter($event, 'nombre', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('NOMBRE', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['nombre'] ? 'fechaup':'fechadawn'" (click)=" ordenar('nombre')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>


        <ng-container matColumnDef="periodo">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="PERÍODO"
                (keyup)="doFilter($event, 'periodo.nombre', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('periodo', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['periodo.nombre'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('periodo.nombre')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.periodo!=undefined?element.periodo.nombre:''}} </td>
        </ng-container>


        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="CÓDIGO"
                (keyup)="doFilter($event, 'codigo.cuenta', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('CÓDIGO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['codigo'] ? 'fechaup':'fechadawn'" (click)=" ordenar('codigo')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.codigo.cuenta}} </td>
        </ng-container>

        <ng-container matColumnDef="concepto">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="CONCEPTO"
                (keyup)="doFilter($event, 'codigo.concepto', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('CONCEPTO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['codigo'] ? 'fechaup':'fechadawn'" (click)=" ordenar('codigo')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.codigo.concepto}} </td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="DESCRIPCIÓN"
                (keyup)="doFilter($event, 'descripcion', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('DESCRIPCIÓN', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['descripcion'] ? 'fechaup':'fechadawn'" (click)=" ordenar('descripcion')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="bruto">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="BRUTO"
                (keyup)="doFilter($event, 'bruto', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('BRUTO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['bruto'] ? 'fechaup':'fechadawn'" (click)=" ordenar('bruto')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.bruto<0?'letra-red':''"> {{element.bruto|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="complemento material">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="COMPLEMENTO MATERIAL"
                (keyup)="doFilter($event, 'complementoMaterial', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('COMPLEMENTO MATERIAL', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['complementoMaterial'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('complementoMaterial')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.complementoMaterial<0?'letra-red':''"> {{element.complementoMaterial|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="vehiculo">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="VEHÍCULO"
                (keyup)="doFilter($event, 'vehiculo', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('VEHÍCULO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['vehiculo'] ? 'fechaup':'fechadawn'" (click)=" ordenar('vehiculo')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.vehiculo<0?'letra-red':''"> {{element.vehiculo|numberEsp}}€ </td>
        </ng-container>


        <ng-container matColumnDef="kms">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="KMS"
                (keyup)="doFilter($event, 'kms', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('KMS', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['kms'] ? 'fechaup':'fechadawn'" (click)=" ordenar('kms')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.hms<0?'letra-red':''"> {{element.kms|numberEsp}}€ </td>
        </ng-container>


        <ng-container matColumnDef="dietasActores">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="DIETAS ACTORES"
                (keyup)="doFilter($event, 'dietasActores', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('DIETAS ACTORES', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['dietasActores'] ? 'fechaup':'fechadawn'" (click)=" ordenar('dietasActores')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.dietas<0?'letra-red':''"> {{element.dietasActores|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="dietasTecnicos">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="DIETAS TÉCNICOS"
                (keyup)="doFilter($event, 'dietasTecnicos', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('DIETAS TÉCNICOS', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['dietasTecnicos'] ? 'fechaup':'fechadawn'" (click)=" ordenar('dietasTecnicos')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.dietas<0?'letra-red':''"> {{element.dietasTecnicos|numberEsp}}€ </td>
        </ng-container>


        <ng-container matColumnDef="hExtras">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="H EXTRAS"
                (keyup)="doFilter($event, 'hExtras', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('H EXTRAS', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['hExtras'] ? 'fechaup':'fechadawn'" (click)=" ordenar('hExtras')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.hExtras<0?'letra-red':''"> {{element.hExtras|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="hFestivas">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="H FESTIVAS"
                (keyup)="doFilter($event, 'hFestivas', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('H FESTIVAS', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['hFestivas'] ? 'fechaup':'fechadawn'" (click)=" ordenar('hFestivas')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.hFestivas<0?'letra-red':''"> {{element.hFestivas|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="brutoConExtras">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="BRUTO CON EXTRAS"
                (keyup)="doFilter($event, 'brutoConExtras', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('BRUTO CON EXTRAS', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['brutoConExtras'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('brutoConExtras')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.brutoConExtras<0?'letra-red':''"> {{element.brutoConExtras|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="prIrpf">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="% IRPF"
                (keyup)="doFilter($event, 'prIrpf', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('% IRPF', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['prIrpf'] ? 'fechaup':'fechadawn'" (click)=" ordenar('prIrpf')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.prIrpf<0?'letra-red':''"> {{element.prIrpf|numberEsp}} </td>
        </ng-container>

        <ng-container matColumnDef="irpf">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="IRPF"
                (keyup)="doFilter($event, 'irpf', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('IRPF', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['irpf'] ? 'fechaup':'fechadawn'" (click)=" ordenar('irpf')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.irpf<0?'letra-red':''"> {{element.irpf|numberEsp}}€ </td>
        </ng-container>


        <ng-container matColumnDef="ss">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="SS"
                (keyup)="doFilter($event, 'ss', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('SS', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['ss'] ? 'fechaup':'fechadawn'" (click)=" ordenar('ss')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.ss<0?'letra-red':''"> {{element.ss|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="formaPago">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="FORMA PAGO"
                (keyup)="doFilter($event, 'formaPago', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FORMA PAGO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['formaPago'] ? 'fechaup':'fechadawn'" (click)=" ordenar('formaPago')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.formaPago}} </td>
        </ng-container>

        <ng-container matColumnDef="fechaPago">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="FECHA PAGO"
                (keyup)="doFilter($event, 'fechaPago', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FECHA PAGO', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['fechaPago'] ? 'fechaup':'fechadawn'" (click)=" ordenar('fechaPago')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.fechaPago}} </td>
        </ng-container>

        <ng-container matColumnDef="ssEmpresa">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="SS EMPRESA"
                (keyup)="doFilter($event, 'ssEmpresa', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('SS EMPRESA', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['ssEmpresa'] ? 'fechaup':'fechadawn'" (click)=" ordenar('ssEmpresa')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.ssEmpresa<0?'letra-red':''"> {{element.ssEmpresa|numberEsp}}€ </td>
        </ng-container>

        <ng-container matColumnDef="fechaAlta">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="FECHA ALTA"
                (keyup)="doFilter($event, 'fechaAlta', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FECHA ALTA', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['fechaAlta'] ? 'fechaup':'fechadawn'" (click)=" ordenar('fechaAlta')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> {{element.fechaAlta}} </td>
        </ng-container>


        <ng-container matColumnDef="Acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="accionesth"><span class="correccionmargenAcciones uppercase">
              Acciones
            </span>
          </th>
          <td class=" " *matCellDef="let element" class="accionesth">
            <div class="acciones">
              <a title="Ver" class="display-view-button"></a>
              <a title="Editar" class="display-edit-button"></a>
              <a title="Descargar" class="display-download-button"></a>
              <a title="Borrar"
                [ngClass]="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)  || element.tieneCoste? 'display-delete-button':'delete-button pointer'"
                (click)="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos) || element.tieneCoste?'':dialogVisible = true; idEliminar = element"></a>



            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="tieneCoste">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <span class="textwe600">Coste</span>
              <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
            </div>
          </th>
          <td *matCellDef="let element"><span *ngIf="element.tieneCoste">En coste</span>
            <span *ngIf="!element.tieneCoste">
              <div class="activo pointer"> Pasar a coste</div>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercas" placeholder="TOTAL"
                (keyup)="doFilter($event, 'total', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Nº', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['total'] ? 'fechaup':'fechadawn'" (click)=" ordenar('total')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput" [ngClass]="element.total<0?'letra-red':''"> {{element.total}}€ </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
          (mouseenter)="tableService.marcarColumna(row,true)" (mouseleave)="tableService.marcarColumna(row,false)"
          (click)="tableService.marcarColumna(row,null)" *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>

    <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
      (page)="nextPage($event)">
    </mat-paginator>

    <div class="dialog-container" *ngIf="dialogVisible">


      <div class="dialog-content" style="width: 500px;">
        <div class="contenedortextos">
          <div class="circle_document">
            <a class="delete-div"></a>
          </div>
        </div>
        <div class="contenedortextos">
          <h4 class="colorh4">Va a eliminar un registro</h4>
        </div>
        <div class="contenedortextos2">
          <p class="text-center">Se va a proceder a la elimincion del registro {{idEliminar.numRegistro}} <br> ¿Desea
            continuar?</p>
        </div>

        <div class="d-flex justify-content-end">
          <button (click)="dialogVisible = false" id="btcancel" class="btn btn-primary mx-1">Cancelar</button>
          <button class="btn btn-primary col-4 mx-1" (click)="borrarElemento(idEliminar.id)">Confirmar</button>
        </div>
      </div>
    </div>
    <div class="dialog-container" *ngIf="isForImport">
      <div class="dialog-content" style="width: 500px;">
        <div class="contenedortextos">
          <div class="circle_document">
            <a class="document-div"></a>
          </div>
        </div>
        <div class="contenedortextos">
          <h4 class="colorh4">Va a importar Laboral</h4>
        </div>
        <div class="contenedortextos2">
          <p>Para importar laboral, primero será necesario descargar la plantilla que se
            proporciona. Los datos deben estar todos rellenos, sin errores y sin símbolos, ya que
            sino la
            importación no se realizará con éxito</p>
        </div>

        <div class="d-flex justify-content-end">
          <button (click)="(isForImport = false)" id="btcancel" class="btn btn-primary mx-1">Cancelar</button>
          <button class="buttonsAccions botExportar col-4 mx-1" type="button" (click)="downloadTemplate()">Plantilla
          </button>
          <button class="buttonsAccions buttonSquaredWhiteright col-4 mx-1"
            (click)="$event.preventDefault(); fileInput.click()">Importar</button>
          <input #inputFile type="file" #fileInput style="display: none;" (change)="onFileChange($event)">
        </div>
      </div>
    </div>

    <div class="dialog-container" *ngIf="isForCoste">
      <div class="dialog-content" style="width: 500px;">
        <div class="contenedortextos">
          <div class="circle_document">
            <a class="document-div"></a>
          </div>
        </div>
        <div class="contenedortextos">
          <h4 class="colorh4">Vas a pasar a coste</h4>
        </div>
        <div class="contenedortextos2">
          <p>Vas a pasar a coste todos los registros actuales. Reviselos antes de continuar ya que una vez esten en
            costes no se podran eliminar ni modificar</p>
        </div>

        <div class="d-flex justify-content-end">
          <button (click)="(isForCoste = false)" id="btcancel" class="btn btn-primary mx-1">Cancelar</button>

          <button class="btn btn-primary col-4 mx-1" (click)="pasarACostes()">Continuar</button>
        </div>
      </div>
    </div>


    <div class="sppiner-container" *ngIf="loading">
      <div class="spinner-border" role="status"></div>