import { Component, OnInit } from '@angular/core';
import { Notification, NotificationType } from 'src/app/models/notification';
import { Observable, Observer, Subscription } from "rxjs";
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications: Notification[] = [];
  private _subscription: Subscription = new Subscription;

  constructor(private _notificationSvc: NotificationService) { }

private _addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);

    }
  }

 ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }


className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'alert alert-success';
        break;

      case NotificationType.warning:
        style = 'alert alert-warning';
        break;

      case NotificationType.error:
        style = 'alert alert-danger';
        break;

      default:
        style = 'alert alert-primary';
        break;
    }

    return style;
  }
}