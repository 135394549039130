import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberEsp'
})
export class NumberEspPipe implements PipeTransform {

  /* 
  Servicio que se encarga de la transformacion de los 
  numeros a formato esp es decir miles a . y decimales a ,
  */
  transform(value: any): string | undefined {

    if (value == 0) {
      return "0,00"
    }
    if (!value) return "0,00";

    if (value < 10000) {
      const parts = value.toString().split('.');
      const integerPart = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
      const decimalPart = parts[1] || '00';
      if (decimalPart != "00") {
        decimalPart.split()
        var b = "00"
        if (decimalPart[1]) {
          b = `${integerPart},${decimalPart[0] + decimalPart[1]}`
        } else {
          b = `${integerPart},${decimalPart[0]}0`
        }
        return b
      }
      var a: any = `${integerPart},${decimalPart}`

      return a
    } else {
      return value.toLocaleString('es-ES', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }


  }


}
