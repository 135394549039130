import { Component, ViewChild } from '@angular/core';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { FilterService } from 'src/app/services/filter.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { Usuario } from 'src/app/models/usuario';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss'],
  
})

export class NotificacionesComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  filters: any[] = [];
  pageSize: any = 99999;
  page: any = 0;
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  totalElements = 0;
  evento: any = '';
  nombrePagina = 'NOTIFICACIONES';
  rutaNuevo = 'admin-contacine/nuevo-gasto';
  nombreBoton = '';
  dataExport: any= null;
  displayedColumns = ['Nº Oc', 'proveedor', "solicitante", "departamento", 'fecha captura', "estado", "estadoOc", "orden compra"];
  displayedColumnscompleta: string[] = [];


  canvas: any;
  proyecto :any;
 
  dataSource = new MatTableDataSource();
  constructor(
    private notificacionesService: NotificacionesService,
    private tokenService: TokenStorageService,
    public interfazInicial: InterfazInicialComponent,
    private filterService: FilterService,
    public tableService: TableService,
    private router: Router,
    ){}
    ngOnInit(): void {
      this.IniciarListas();
      this.proyecto= this.tableService.proy
    this.tableService.subscribeToProyectoChanges((proyectoActual: any) => {
     this.proyecto=proyectoActual;
      this.getNotificaciones()})
      this.getNotificaciones();    
    }

    getNotificaciones(){
      this.evaluateFilter()
      console.log(this.filter)
      this.notificacionesService.getNotificaciones(this.filter).subscribe({
        next: (data: any) => {
        this.totalElements=data.source.length
          console.log(data)
         this.dataSource =data.source


         if (this.ordenarCampos['id'] == undefined) {
          this.paginator._intl = this.interfazInicial.paginator;
          let nombre: string = '';
          if (data['source'].length != 0) {
            var mapa = Object.entries(data['source'][0]);
            mapa.forEach((element) => {
              nombre = element[0];
              this.ordenarCampos[nombre] = false;
            });
            this.ordenarCampos['ordenCompra.numOc'] = false;
            this.ordenarCampos['ordenCompra.proveedor.nombreFiscal'] = false;
            this.ordenarCampos['ordenCompra.solicitante.nombreUsuario'] = false;
            this.ordenarCampos['departamento.nombre'] = false;
          }
        }

        },
        error: (err) => {
    }})
    }

    evaluateFilter(){
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });

        this.filter.filters.push({
          field_type: 'STRING',
          key: 'usuario.id',
          operator: 'LIKE',
          value: this.interfazInicial.currentUser.id,
        });
    }

    


    IniciarListas() {
      console.log(this.mostrarCampos)
  
      if (
        sessionStorage.getItem(this.nombrePagina) &&
        sessionStorage.getItem(this.nombrePagina) != 'undefined'
      ) {
        var a: any = sessionStorage.getItem(this.nombrePagina);
        this.mostrarCampos = JSON.parse(a);
        this.displayedColumns.forEach((element) => {
          this.displayedColumnscompleta.push(element);
        });
      } else {
        this.displayedColumns.forEach((element) => {
          console.log(element)
          this.mostrarCampos[element] = true;
          this.displayedColumnscompleta.push(element);
        });
      }
      console.log(this.mostrarCampos)
      this.actualizarTabla();
    }

    actualizarTabla() {
      this.displayedColumns = [];
      this.displayedColumns.push(...this.displayedColumnscompleta);
      for (let clave in this.mostrarCampos) {
        if (this.mostrarCampos[clave] == false) {
          this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
        }
      }
    }

    doGeneralFilter() {
      console.log("displayedColumns", this.displayedColumns)
      if (this.evento.pageSize != undefined) {
        this.pageSize = this.evento.pageSize;
        this.filter = {
          filters: [],
          page: this.page,
          size: this.pageSize,
          sorts: this.sorts,
        };
        this.getNotificaciones();
      } else {
        let operator = 'ALL_FIELDS_CLASS';
  
        if (this.evento != '') {
          this.filter.filters = [
            {
              operator: operator,
              value: this.evento,
              values: ['OrdenCompra.numOc', 'Proveedor.nombreFiscal', 'Departamento.nombre', 'Usuario.nombreUsuario'],
            },
          ];
          this.getNotificaciones();
        } else {
          this.filter.filters = [];
          this.getNotificaciones();
        }
      }
    }
    ordenar(property: string, sorts: any[] = []) {
      var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
      var index = 0;
      sorts.forEach((element: any) => {
        if (element['key'] == property) {
          sorts.splice(index, 1);
        }
        index++;
      });
      for (let clave in this.ordenarCampos) {
        if (property.includes(clave)) {
          this.ordenarCampos[clave] = !this.ordenarCampos[clave];
        } else {
          this.ordenarCampos[clave] = false;
        }
      }
      this.filter.sorts = [{ key: property, direction: orden }];
  
      this.getNotificaciones();
    }
  

 


  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: this.page, size: this.pageSize };
    this.getNotificaciones();
  }

    doFilter(event: any, type: any, operator: any, field_type: any) {
      
      let valueBool = null
      if (type == "estado") {
        if (event.target != null && event.target.value != "") {
          let value: string = event.target.value
          if (("vista").includes((value.toLowerCase()))) {
            valueBool = "true"
          }
          else if (("pendiente").includes((value.toLowerCase()))) {
            valueBool = "false"
          }
        }
      } else {
        valueBool = null
      }

    this.filter.filters = this.filterService.doFilter(
      event,
      type,
      operator,
      field_type,
      this.filter.filters,
      valueBool
    );

    this.getNotificaciones();
  }



  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()

    return text.charAt(0).toUpperCase() + text.slice(1)

  }

  nextPage(event: PageEvent) {
    this.page = event.pageIndex + '';
    this.pageSize = event.pageSize + '';

    this.getNotificaciones();
  }

  calcTextWidth(text: any, font: any) {
    let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 10;
  }

  irAOrdenDeCompra(idOc:any, idN:any){
   this.actualizarEstadoNotificaciones(idN);
   if(this.tableService.checkPermiso(12,3,this.interfazInicial.isSuperAdmin,this.interfazInicial.permisos)==false){
    this.router.navigate([`admin-contacine/editar-orden-compra/` + idOc]);
   }
   else{  
    this.router.navigate([`admin-contacine/ver-orden-compra/` + idOc]);
   }

  }


  actualizarEstadoNotificaciones(id:any) {
    this.notificacionesService.actualizarEstado(id).subscribe({
        next: (data: any) => {
          this.interfazInicial.comprobarNotificacionesPendientes();
        },
        error: (err) => {
        }
      })
  }
  aniadirOCNumber(numero: any): String {
    let num: string = numero + '';
    for (let i = num.length; i < 4; i++) {
      num = 0 + num;
    }
    return 'OC' + num;
  }

}

