<div class="whole_page d-flex confipantalla">
    <div class="page_content d-flex">
        <div class="h-100">
            <div class="sidebar  h-100" [ngClass]="{'close':!isExpanded}">
                <div [ngClass]="isExpanded?'logo-details':'logo-details-close'">
                    <img class="imagenLogo" src="assets/contacineLogos/Cc_logo.png" alt="Icono menú" />
                    <img class="textLogo" *ngIf="isExpanded" src="assets/contacineLogos/CC_logo_texto.png"
                        alt="Icono menú" />
                </div>
                <ul class="nav-links justify-content-center">
                    <span *ngFor="let menu of menuListado">
                    <li  class="selected" *ngIf="menu.nombre!='Informes'">
                        <div *ngIf="!isExpanded && menu.icono && !menu.perfil && menu.url" class="enl">
                            <img class="imgmenu" src="{{ environment.API_URL }}{{ menu.icono }}"
                                alt="Icono menú {{menu.nombre}}" />
                            <ul class="sub-menu blank">
                                <li><a class="colorLetMenu link_name pointer"
                                        *ngIf="menu.listadoHijos && menu.listadoHijos.length==0"
                                        (click)="navegar(menu.url,menu.idPermiso)">{{menu.nombre}}</a></li>
                                <span *ngIf="menu.id!=19">
                                    <span *ngFor="let child of menu.listadoHijos">
                                        <li *ngIf="checkExists(child) && child.url"
                                            (click)="navegar(child.url,child.idPermiso)"><a
                                                class="colorLetMenu link_name pointer">{{child.nombre}} </a></li>
                                    </span></span>
                                <span *ngIf="menu.id==19" class="ajuste_menu ">
                                    <span *ngFor="let child of menu.listadoHijos">
                                        <li *ngIf="checkExists(child) && child.url"
                                            (click)="navegar(child.url,child.idPermiso)"><a
                                                class="colorLetMenu link_name pointer">{{child.nombre}}</a></li>
                                    </span></span>

                            </ul>
                        </div>
                        <div *ngIf="menu.listadoHijos && menu.listadoHijos.length > 0 && isExpanded && !menu.perfil && menu.url"
                            class="w-100 pointer">
                            <mat-expansion-panel (opened)="isOpened = true" (closed)="isOpened = false">
                                <mat-expansion-panel-header class="specific-class" [collapsedHeight]="'50px'"
                                    [expandedHeight]="'50px'">
                                    <mat-panel-title class="d-flex justify-content-left">
                                        <img class="imgmenu" src="{{ environment.API_URL }}{{ menu.icono }}"
                                            alt="Icono menú {{menu.nombre}}" />

                                        <p class="colorLetMenu">{{menu.nombre}}</p>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <span *ngFor="let child of menu.listadoHijos">
                    <li *ngIf="checkExists(child) && child.url" (click)="navegar(child.url,child.idPermiso)"><a
                            class="colorLetMenu link_name">{{child.nombre}}</a></li>
                    </span>


                    </mat-expansion-panel>
            </div>

            <div *ngIf="!menu.listadoHijos?.length && menu.icono  && isExpanded && !menu.perfil && menu.url"
                class="linkWhitOutChild w-100 d-flex enl pointer" [ngClass]="{'closelinkWhitOutChild':!isExpanded}">
                <a (click)="navegar(menu.url,menu.idPermiso)" class="w-100">
                    <img class="imgmenu" src="{{ environment.API_URL }}{{ menu.icono }}"
                        alt="Icono menú {{menu.nombre}}" />
                    <span class="colorLetMenu link_name">{{menu.nombre}}</span>
                </a>
            </div>
            </li>
        </span>

            </ul>
        </div>
    </div>


    <div class="h-100 ajustewidth" [ngClass]="{'ajustewidthmenu': !isExpanded }">
        <div class="d-flex flex-row justify-content-between menu-superior w-100">
            <mat-toolbar class="control_mat_tolbar">
                <button class="button_burger" *ngIf="pantallaChiquita" type="button" (click)="chageExpanded()">
                    <mat-icon *ngIf="isExpanded" title="Reducir menu">menu</mat-icon>

                    <mat-icon *ngIf="!isExpanded" title="Expandir menu">menu</mat-icon>
                </button>
                <div class="fecha_actu">
                    <div> <label class="titFech">Fecha actual:</label><label> {{fechaActual}} </label></div>
                    <div> <label class="titFech">Semana:</label><label> {{semanaActual}} </label></div>
                </div>
                <div class="datos-personales ">
                    <div title="cambiar proyecto" class="selectorProyectos">
                        <mat-select placeholder="Sin proyectos" id="selectorProyChange" [value]="proyaux"
                            [compareWith]="compararObjetos">
                            <mat-option class="optProy" *ngFor="let proyecto of listadoProyectos"
                                (onSelectionChange)="searchPermisosProy($event,proyecto)"
                                [value]="proyecto">{{proyecto.titulo}}</mat-option>
                        </mat-select>
                    </div>
                    <div (click)="navegar('/notificaciones',undefined)" class="iconsMenuSup dis">
                        <mat-icon title="Notificaciones" *ngIf="numOfNotificaciones!=0" class="material-icons"
                            matBadge="{{numOfNotificaciones}}" matBadgeColor="accent" matBadgeSize="small">
                            notifications_none
                        </mat-icon>
                        <mat-icon title="Notificaciones" *ngIf="numOfNotificaciones==0" class="material-icons pointer"
                            matBadgeColor="accent" matBadgeSize="small">
                            notifications_none
                        </mat-icon>

                    </div>
                    <hr>
                    <div class="d-flex flex-row align-items-center pointer" data-bs-toggle="dropdown"
                        id="dropdownMenuButton">
                        <div class="user-wrapper">
                            <span *ngIf="" class="badge-wrapper position-relative" title="Tines notificaciones">
                                <span class="badge badge-element position-absolute"></span>
                            </span>

                            <div class="user-image-wrapper rounded-circle" type="button" aria-expanded="false">
                                <div class="dropdown-menu pt-3 pb-3" aria-labelledby="dropdownMenuButton">
                                    <div *ngFor="let menu of menuListado">
                                        <div *ngIf="menu.perfil">
                                            <a class="dropdown-item d-flex flex-row align-items-center"
                                                (click)="navegar(menu.url,menu.idPermiso)"
                                                attr.aria-label="{{ menu.nombre }}">
                                                <img class="iconPerfil" src="{{ environment.API_URL }}{{ menu.icono }}">
                                                <span class="ml-3">{{ menu.nombre }}</span>
                                            </a>


                                        </div>
                                    </div>
                                    <div>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item d-flex flex-row align-items-center" (click)="logout()"
                                            aria-label="Cerrar sesión">
                                            <img src="" class="iconoMenu align-self-end">
                                            <span class="ml-3">Cerrar Sesión</span>
                                        </a>
                                    </div>
                                    <div class="dropdown-item d-flex flex-row justify-content-around legal mt-2">
                                        <a (click)="navegar('politica-privacidad',null)">Privacidad</a>
                                        <a (click)="navegar('aviso-legal',null)">Aviso legal</a>
                                    </div>

                                </div>
                                <div class="iconName rounded-circle">
                                    <p *ngIf="currentUser?.imgPerfil==undefined || currentUser?.imgPerfil==null">
                                        {{getFirstLetter(currentUser.nombreUsuario)}}
                                    </p>
                                    <img *ngIf="currentUser?.imgPerfil!=undefined" src="data:image;base64,{{img}}"
                                        width="100%" height="100%" class="rounded-circle">
                                </div>
                            </div>
                        </div>

                        <div class="d-flex flex-column ml-3">
                            <span class="nombre-usuario"
                                title="{{ currentUser.nombreUsuario }}">{{currentUser.nombreUsuario}}</span>
                            <span class="email-usuario font-weight-light" title="{{tipoUsu}}">{{
                                tipoUsu.nombre
                                }}</span>
                        </div>
                    </div>
                </div>

            </mat-toolbar>

        </div>
        <div class="center-div" name="overflowblox">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
</div>