import { Component, ViewEncapsulation } from '@angular/core';
import { CuentaService } from 'src/app/services/cuenta.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PresupuestoService } from 'src/app/services/presupuesto.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { Proyecto } from 'src/app/models/proyecto';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RaizComponent } from '../../_raiz/raiz.component';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { ExportExcelService } from 'src/app/services/servicios-funcionalidad/export-excel.service';
import { ControlCostesService } from 'src/app/services/control-costes.service';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { isValid } from 'date-fns';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { LaboralService } from 'src/app/services/laboral.service';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';

@Component({
  selector: 'app-nuevo-control-coste',
  templateUrl: './nuevo-control-coste.component.html',
  styleUrls: ['./nuevo-control-coste.component.scss'],
  animations: [dropdownAnimation],
  encapsulation: ViewEncapsulation.None,
})
/**
 * Componente que para control de costes
 */
export class NuevoControlCosteComponent {

  proyecto: Proyecto = this.interfazInicial.proyaux;
  //TODO: Hacer objeto
  control: any[] = [];
  loading: boolean = false
  form!: FormGroup
  isReading: boolean = false
  private formBuilder: FormBuilder = new FormBuilder
  costesPorSubcapitulo: { [subcapitulo: string]: any[] } = {};
  costesPorCapitulo: any = {};
  array: any[] = []
  arrayCaps: any[] = []
  isOpened = false
  mostrarDetalle = 'in';
  dataExport: any = []

  totalPresAprobado: number = 0
  totalDesvAct: number = 0
  totalPoryCierre: number = 0
  totalPendienteGasto: number = 0
  totalSaldOC: number = 0
  totalCosActual: number = 0
  totalSemAnterior: number = 0
  totalPresEstimado: number = 0

  panelsOpen = true;
  currentUser = this.tokenService.getUser();
  isValidForClose = true;

  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Cerrar Semana"
  nombrePagina = "Control de Costes"
  tipoUsuario: any = this.interfazInicial.tipoUsu;
  isProductor = false;

  constructor(
    //private notificationService: NotificationService,
    private cuentaService: CuentaService,
    private presupuestoService: PresupuestoService,
    private interfazInicial: InterfazInicialComponent,
    public raiz: RaizComponent,
    private exportService: ExportExcelService,
    private tableService: TableService,
    private controlCostesService: ControlCostesService,
    private notificationService: NotificationService,    
    private gastoService: RegistroGastosService,
    private laboralService: LaboralService,
    private router: Router,
    private tokenService: TokenStorageService
  ) { }

  get codigos(): FormArray {
    return this.form.get("codigos") as FormArray;
  }

  ngOnInit() {

    if (this.tableService.checkAnyAccionByPermiso(4, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    }

    if (this.checkPermiso(4, 3) && this.checkPermiso(4, 2)) this.isValidForClose = false;
    if (this.tipoUsuario.id === 1) this.isProductor = true;

    this.form = new FormGroup({
      codigos: new FormArray([])
    })

    this.loading = true
    /*   if (this.proyecto.id) {
       this.chargePresu()
     }else{
       this.getCuentas()
     } */
    if (!this.interfazInicial.proyaux || !this.interfazInicial.proyaux.id) {
      this.notificationService.warning("ALERTA", "No hay proyecto seleccionado.", 5000)
      this.router.navigate(['/proyectos'])
    } else {
      this.tableService.subscribeToProyectoChanges(async () => {
        this.proyecto = this.interfazInicial.proyaux
        this.chargeControl()
      });

      this.chargeControl()
    }
  }

  /**
   * Verifica si un usuario tiene permiso para una acción.
   * 
   * @param idPermiso - El ID del permiso.
   * @param idAccion - El ID de la acción.
   * @returns `true` si el usuario tiene permiso para la acción, de lo contrario devuelve `false`.
   */
  checkPermiso(idPermiso: number, idAccion: number): boolean {
    if (this.interfazInicial.isSuperAdmin && this.currentUser.rol.id == 1) {
      return false;
    } else {
      return !this.interfazInicial.permisos.some(e =>
        idPermiso === e.permiso.id && e.accion.id === idAccion
      )
    }
  }

  /**
 * Cambia el estado de todos los paneles.
 */
  togglePanels() {
    this.panelsOpen = !this.panelsOpen;
  }

  /**
   * Metodo que agrupa todos los requerimentos para leer el control de costes tanto al cargar la pagina y al cambiar de proyecto
   */
  chargeControl() {
    this.loading = true
    this.proyecto = this.interfazInicial.proyaux
    this.costesPorSubcapitulo = {};
    this.costesPorCapitulo = {}
    this.array = []
    this.arrayCaps = []
    this.control = [];
    //this.subcapitulosDesplegados = [];
    this.codigos.clear()
    this.form.reset()
    this.getControl()
  }

  /**
   * Metodo que llama al servicio para la lectura de los costes por proyecto
   */
  getControl() {
    this.presupuestoService.getControlByProyectoId(this.proyecto.id.toString()).subscribe({
      next: (data) => {
        this.control = data;
        this.getCuentas()
      },
      error: (err) => {
        console.log("Error: ", err);
        this.getCuentas()
      },
    })
  }

  /**
  * Metodo que llama al servico para leer todas las cuentas para el control de costes
  */
  getCuentas() {
    console.log("Proyecto: ", this.proyecto);
    
    this.cuentaService.getAllCuentas(this.proyecto.versionCodigos.toString()).subscribe({
      next: (data) => {
        var aux: any = data
        this.totalPresAprobado = 0
        this.totalDesvAct = 0
        this.totalPoryCierre = 0
        this.totalPendienteGasto = 0
        this.totalSaldOC = 0
        this.totalCosActual = 0
        this.totalSemAnterior = 0
        this.totalPresEstimado = 0
        this.cuentaService.getAllCuentasByProyecto(this.interfazInicial.proyaux.id).subscribe({
          next: (codProy: any) => {
            if (codProy) {
              codProy.forEach((element: any) => {
                aux.push(element)
              });
            }

            // Ordenar por cuenta
            aux = aux.sort((a: any, b: any) => a.cuenta.localeCompare(b.cuenta));

            aux.forEach((cod: any) => {
              this.addForm(
                cod,
                "", "", "", "",
                "", "", "", "", this.proyecto
              );

            })
            this.organizarCostesPorSubcapitulo(this.codigos.value);
            this.loading = false
          },
          error: (error) => {
            console.log(error);
            aux.forEach((cod: any) => {
              this.addForm(
                cod,
                "", "", "", "",
                "", "", "", "", this.proyecto
              );

            })
            this.organizarCostesPorSubcapitulo(this.codigos.value);
            this.loading = false
          },
        });

        /*   this.organizarCostesPorSubcapitulo(this.codigos.value);
          this.loading = false */
      },

      error: (error) => {
        console.log(error);
        this.loading = false

      },
    });
  }

  /**
   * Método para agregar los costes al formulario.
   * @param cuenta El objeto que representa la cuenta.
   * @param presupuestoAprobado El valor del presupuesto aprobado.
   * @param presupuestoEstimado El valor del presupuesto estimado.
   * @param costeSemanaAnterior El valor del coste de la semana anterior.
   * @param costeActual El valor del coste actual.
   * @param saldoOrdenCompra El valor del saldo de la orden de compra.
   * @param restoPendienteGasto El valor del resto pendiente de gasto.
   * @param proyeccionCierre El valor de la proyección de cierre.
   * @param desviacionActual La desviación actual.
   * @param proyecto El proyecto al que pertenecen los costes.
   */
  addForm(cuenta: any, presupuestoAprobado: any, presupuestoEstimado: any, costeSemanaAnterior: any, costeActual: any, saldoOrdenCompra: any, restoPendienteGasto: any, proyeccionCierre: any, desviacionActual: string, proyecto: Proyecto) {
    const controlExistente = this.control.find(contro => contro.codigo.id === cuenta.id);
    if (controlExistente) {
      this.totalPresAprobado = this.totalPresAprobado + controlExistente.presupuestoAprobado
      this.totalDesvAct = this.totalDesvAct + controlExistente.desviacionActual
      this.totalPoryCierre = this.totalPoryCierre + controlExistente.proyeccionCierre
      this.totalPendienteGasto = this.totalPendienteGasto + controlExistente.restoPendienteGasto
      this.totalSaldOC = this.totalSaldOC + controlExistente.saldoOrdenCompra
      this.totalCosActual = this.totalCosActual + controlExistente.costeActual
      this.totalSemAnterior = this.totalSemAnterior + controlExistente.costeSemanaAnterior
      this.totalPresEstimado = this.totalPresEstimado + controlExistente.presupuestoEstimado
    }
    const metadato = this.formBuilder.group({
      cuenta: new FormControl({ value: cuenta.cuenta, disabled: this.isReading }),
      concepto: new FormControl({ value: cuenta.concepto, disabled: this.isReading }),
      presupuestoAprobado: new FormControl({ value: controlExistente ? controlExistente.presupuestoAprobado : presupuestoAprobado, disabled: this.isReading }),
      presupuestoEstimado: new FormControl({ value: controlExistente ? controlExistente.presupuestoEstimado : presupuestoEstimado, disabled: this.isReading }),
      costeSemanaAnterior: new FormControl({ value: controlExistente ? controlExistente.costeSemanaAnterior : costeSemanaAnterior, disabled: this.isReading }),
      costeActual: new FormControl({ value: controlExistente ? controlExistente.costeActual : costeActual, disabled: this.isReading }),
      saldoOrdenCompra: new FormControl({ value: controlExistente ? controlExistente.saldoOrdenCompra : saldoOrdenCompra, disabled: this.isReading }),
      restoPendienteGasto: new FormControl({ value: controlExistente ? controlExistente.restoPendienteGasto ?? 0 : restoPendienteGasto ?? 0, disabled: this.isReading }),
      proyeccionCierre: new FormControl({ value: controlExistente ? controlExistente.proyeccionCierre : proyeccionCierre, disabled: this.isReading }),
      desviacionActual: new FormControl({ value: controlExistente ? controlExistente.desviacionActual : desviacionActual, disabled: this.isReading }),
      proyecto: new FormControl({ value: controlExistente ? controlExistente.proyecto : proyecto, disabled: this.isReading }),
      codigo: new FormControl({ value: controlExistente ? controlExistente.codigo : cuenta, disabled: this.isReading }),
      id: new FormControl({ value: controlExistente ? controlExistente.id : '', disabled: this.isReading }),
    });

    this.codigos.push(metadato);
  }

  /**
   * Método para agrupar los costes por subcapítulo
   * @param costes 
   * @returns un objeto con los datos agrupados pos subCapitulos
   */
  organizarCostesPorSubcapitulo(costes: any[]): { [subcapitulo: string]: any[] } {
    this.dataExport = []

    costes.forEach((presupuesto: any) => {
      const nombreCapitulo: any = presupuesto.codigo.capitulo.nombre;
      const nombreSubcapitulo: any = [presupuesto.codigo.subcapitulo?.codigo, presupuesto.codigo.subcapitulo?.nombre];

      // Crear la estructura de objetos anidados si no existe
      if (!this.costesPorCapitulo[nombreCapitulo]) {
        this.costesPorCapitulo[nombreCapitulo] = {};
      }
      if (!this.costesPorCapitulo[nombreCapitulo][nombreSubcapitulo]) {
        this.costesPorCapitulo[nombreCapitulo][nombreSubcapitulo] = [];
      }

      // Agregar el presupuesto al array del subcapítulo
      this.costesPorCapitulo[nombreCapitulo][nombreSubcapitulo].push(presupuesto);
    });

    this.arrayCaps = Object.keys(this.costesPorCapitulo)

    costes.forEach((coste: any) => {
      if (coste.codigo?.etiquetas?.[0]?.descripcion) coste.concepto = coste.codigo.etiquetas[0].descripcion;
      this.dataExport.push(coste)
      const nombreSubcapitulo: any = [coste.codigo.subcapitulo?.codigo, coste.codigo.subcapitulo?.nombre]

      if (!this.costesPorSubcapitulo[nombreSubcapitulo]) {
        this.costesPorSubcapitulo[nombreSubcapitulo] = [];
      }

      coste.index = costes.indexOf(coste)
      this.costesPorSubcapitulo[nombreSubcapitulo].push(coste);
    });
    //console.error("Presupuestos por subcapitulo: ", this.costesPorSubcapitulo);
    this.array = Object.keys(this.costesPorSubcapitulo)
    this.array = this.array.sort();

    return this.costesPorSubcapitulo;
  }

  /**
   * Metodo del voton volver para ir a la pagina anterior
   */
  goBack() {
    this.raiz.goBack()
  }

  /**
   * Metodo que llama al servicio de exportacion para exportar los costes
   */
  export() {
    this.isClearForm = false
    this.exportService.export("Control de Costes", this.dataExport, "ControlCoste", this.interfazInicial.proyaux.titulo)
  }

  /**
   * Calcula el total del presupuesto aprobado para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total del presupuesto aprobado.
   */
  calcTotalPresAprobado(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.presupuestoAprobado) {
        aux = aux + parseFloat(element.presupuestoAprobado)
      }
    })
    return aux;
  }

  /**
   * Calcula el total de la desviación actual para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total de la desviación actual.
   */
  calcTotalDesvAct(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.desviacionActual) {
        aux = aux + parseFloat(element.desviacionActual)
      }
    })
    return aux;
  }

  /**
   * Calcula el total de la proyección de cierre para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total de la proyección de cierre.
   */
  calcTotalPoryCierre(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.proyeccionCierre) {
        aux = aux + parseFloat(element.proyeccionCierre)
      }
    })
    return aux;
  }

  /**
   * Calcula el total del resto pendiente de gasto para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total del resto pendiente de gasto.
   */
  calcTotalPendienteGasto(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.restoPendienteGasto) {
        aux = aux + parseFloat(element.restoPendienteGasto)
      }
    })
    return aux;
  }

  /**
   * Calcula el total del saldo de orden de compra para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total del saldo de orden de compra.
   */
  calcTotalSaldOC(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.saldoOrdenCompra) {
        aux = aux + parseFloat(element.saldoOrdenCompra)
      }
    })
    return aux;
  }
  /**
   * Calcula el total del coste actual para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total del coste actual.
   */
  calcTotalCosActual(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.costeActual) {
        aux = aux + parseFloat(element.costeActual)
      }
    })
    return aux;
  }
  /**
   * Calcula el total del coste de la semana anterior para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total del coste de la semana anterior.
   */
  calcTotalSemAnterior(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.costeSemanaAnterior) {
        aux = aux + parseFloat(element.costeSemanaAnterior)
      }
    })
    return aux;
  }

  /**
   * Calcula el total del presupuesto estimado para una lista de elementos.
   * @param {any[]} list - La lista de elementos sobre los cuales calcular el total.
   * @returns {number} El total del presupuesto estimado.
   */
  calcTotalPresEstimado(list: any[]) {
    var aux: number = 0
    list.forEach(element => {
      if (element.presupuestoEstimado) {
        aux = aux + parseFloat(element.presupuestoEstimado)
      }
    })
    return aux;
  }

  verDetalle() {
    if (this.mostrarDetalle == 'in') {
      this.mostrarDetalle = 'out'
    } else { this.mostrarDetalle = 'in' }
    console.log(this.mostrarDetalle)
  }

  /**
   * Método del botón para la acción de cerrar una cuenta semanal.
   * Llama al servicio 'controlCostesService' para cerrar el coste semanal del proyecto actual.
   */
  cerrarCuenta() {
    this.isSave = false
    if (!this.isValidForClose) {
      this.notificationService.info("INFO", "No tiene permisos para cerrar el coste.", 5000);
      return;
    }
    this.loading = true
    
    this.controlCostesService.cerrarCosteSemanal(this.proyecto.id.toString()).subscribe({
      next: (data: any) => {
        this.notificationService.success('ÉXITO', data.message, 5000);
        window.location.reload();
        this.loading = false

      },
      error: (error) => {
        this.notificationService.error('ERROR', error.error.message, 5000);
        this.loading = false

        console.log(error)
      }
    })
  }

  getConcepto(element: any): string {
    var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }

  mostrarTooltip(element: any): boolean {
    if(this.calculaLongitud(this.getConcepto(element)) >= 67) return true;
    return false;
  }

  calculaLongitud(text: string): number {
    let length = 0;
    for (let char of text) {
      if (char >= 'A' && char <= 'Z') length += 1.5;
      else length += 1;
    }
    return length;
  }

  getArrayByCap(element: any) {
    var listAux: any = []
    this.array.forEach((data) => {
      if (element[data]) {
        element[data].forEach((a: any) => {
          listAux.push(a)
        });
      }
    })
    return listAux;
  }

  navegarAOc(codigo: any) {
    console.log(codigo);
    var url: any
    if (codigo.codigoHijo) {
      url = "/admin-contacine/orden-compra?codigo=" + codigo.cuenta + "&codigoHijo=" + codigo.codigoHijo;

    } else {
      url = "/admin-contacine/orden-compra?codigo=" + codigo.cuenta;

    }
    const opcionesVentana = 'width=1701,height=801,scrollbars=yes';
    window.open(url, '_blank', opcionesVentana);
    //  this.router.navigate(['/admin-contacine/orden-compra'], { queryParams: params });
  }
  navegarAGasto(codigo: any) {
    console.log(codigo);
    var url: any
    const opcionesVentana = 'width=1701,height=801,scrollbars=yes';
    let filter: any = {
      filters: [],
      page: "0",
      size: "10",
      sorts: [],
    };
    filter.filters.push({
      field_type: 'STRING',
      key: 'conceptos.codigo.cuenta',
      operator: 'LIKE',
      value: codigo.cuenta + '',})
      filter.filters.push({
        field_type: 'STRING',
        key: 'proyecto.id',
        operator: 'LIKE',
        value: this.interfazInicial.proyaux.id,
      });
    if (codigo.codigoHijo) {
        
          filter.filters.push({
            field_type: 'STRING',
            key: 'conceptos.codigo.codigoHijo',
            operator: 'LIKE',
            value: codigo.codigoHijo + '',
          });
          filter.filters.push({
            field_type: 'STRING',
            key: 'num_pc',
            operator: 'IS_NULL',
          });
          this.gastoService.getRegistros(filter).subscribe((data: any) => {
            if(data.source.length!=0){
              url = "/admin-contacine/registro/gastos?codigo=" + codigo.cuenta + "&codigoHijo=" + codigo.codigoHijo;
              window.open(url, '_blank', opcionesVentana);
            }
            else{
              filter.filters[3] = 
              {
                field_type: 'STRING',
                key: 'num_pc',
                operator: 'IS_NOT_NULL',
                value: '',
              };
              this.gastoService.getRegistros(filter).subscribe((data: any) => {
                if(data.source.length!=0){
                  url = "/admin-contacine/justificaciones?codigo=" + codigo.cuenta + "&codigoHijo=" + codigo.codigoHijo;
                  window.open(url, '_blank', opcionesVentana);
                }
                else{
                  url = "/admin-contacine/registro/laboral?codigo=" + codigo.cuenta + "&codigoHijo=" + codigo.codigoHijo;
                  window.open(url, '_blank', opcionesVentana);
                }
              })
            }
          })
      
    } else {

      filter.filters.push({
        field_type: 'STRING',
        key: 'conceptos.codigo.codigoHijo',
        operator: 'IS_NULL',
      });

      filter.filters.push({
        field_type: 'STRING',
        key: 'num_pc',
        operator: 'IS_NULL',
      });
      this.gastoService.getRegistros(filter).subscribe((data: any) => {
        if(data.source.length!=0){
          url = "/admin-contacine/registro/gastos?codigo=" + codigo.cuenta;
          window.open(url, '_blank', opcionesVentana);
        }
        else{
          filter.filters[3] = 
          {
            field_type: 'STRING',
            key: 'num_pc',
            operator: 'IS_NOT_NULL',
            value: '',
          };
          this.gastoService.getRegistros(filter).subscribe((data: any) => {
            if(data.source.length!=0){
              url = "/admin-contacine/justificaciones?codigo=" + codigo.cuenta;
              window.open(url, '_blank', opcionesVentana);
            }
            else{
              url = "/admin-contacine/registro/laboral?codigo=" + codigo.cuenta;
              window.open(url, '_blank', opcionesVentana);
            }
          })
        }
      })

    }
    
  }
}
