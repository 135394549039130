


<app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
[mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
[nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento"
[canCreate]="tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
[canExport]="tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
[dataExport]="dataExport"></app-header-listados>
<div class="fondoblanco fondoblanco_tabla">

<div  class="mat-elevation-z8  " class="table_container">
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="Nº Oc">
             <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Nº OC"
                (keyup)="doFilter($event, 'ordenCompra.numOc', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Nº OC', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['ordenCompra.numOc'] ? 'fechaup':'fechadawn'" (click)=" ordenar('ordenCompra.numOc')"></a>
            </div>
          </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{aniadirOCNumber(element.ordenCompra.numOc)}}</span> </td>
    </ng-container>
    
    <ng-container matColumnDef="proveedor">
      <th mat-header-cell *matHeaderCellDef>
        <div class="thdisp">
          <input matInput type="text" class="searchinput uppercase" placeholder="PROVEEDOR"
            (keyup)="doFilter($event, 'ordenCompra.proveedor.nombreFiscal', 'LIKE', 'STRING')"
            [ngStyle]="{'min-width.px':  tableService.calcTextWidth('PROVEEDOR', '600 14px Montserrat, sans-serif')}">
          <a [ngClass]="ordenarCampos['ordenCompra.proveedor.nombreFiscal'] ? 'fechaup':'fechadawn'" (click)=" ordenar('ordenCompra.proveedor.nombreFiscal')"></a>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{element.ordenCompra.proveedor.nombreFiscal}}</span> </td>
    </ng-container> 

    <ng-container matColumnDef="solicitante">
      <th mat-header-cell *matHeaderCellDef>
        <div class="thdisp">
          <input matInput type="text" class="searchinput uppercase" placeholder="SOLICITANTE"
            (keyup)="doFilter($event, 'ordenCompra.solicitante.nombreUsuario', 'LIKE', 'STRING')"
            [ngStyle]="{'min-width.px':  tableService.calcTextWidth('SOLICITANTE', '600 14px Montserrat, sans-serif')}">
           <a [ngClass]="ordenarCampos['ordenCompra.solicitante.nombreUsuario'] ? 'fechaup':'fechadawn'" (click)=" ordenar('ordenCompra.solicitante.nombreUsuario')"></a>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{element.ordenCompra.solicitante.nombreUsuario}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="fecha captura">
      <th mat-header-cell *matHeaderCellDef>
        <div class="thdisp">
          <input matInput type="text" class="searchinput uppercase" placeholder="FECHA"
            (keyup)="doFilter($event, 'fecha_captura', 'LIKE', 'STRING')"
            [ngStyle]="{'min-width.px':  tableService.calcTextWidth('FECHA', '600 14px Montserrat, sans-serif')}">
          <a [ngClass]="ordenarCampos['fecha_captura'] ? 'fechaup':'fechadawn'" (click)=" ordenar('fecha_captura')"></a>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{element.fecha_captura}}</span> </td>
    </ng-container>
  
    <ng-container matColumnDef="departamento">
      <th mat-header-cell *matHeaderCellDef>
        <div class="thdisp">
          <input matInput type="text" class="searchinput uppercase" placeholder="DEPARTAMENTO"
            (keyup)="doFilter($event, 'departamento.nombre', 'LIKE', 'STRING')"
            [ngStyle]="{'min-width.px':  tableService.calcTextWidth('DEPARTAMENTO', '600 14px Montserrat, sans-serif')}">
          <a [ngClass]="ordenarCampos['departamento.nombre'] ? 'fechaup':'fechadawn'" (click)=" ordenar('departamento.nombre')"></a>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{element.departamento.nombre}}</span> </td>
    </ng-container>


    <ng-container matColumnDef="orden compra">
      <th mat-header-cell *matHeaderCellDef class="minwv"> <span class="fw"> ORDEN DE COMPRA </span></th>
      <td  mat-cell *matCellDef="let element" (click)="irAOrdenDeCompra(element.ordenCompra.id, element.id)"> <a  class="link pointer" ><!-- {{element.ordenCompra.id}} --> VISUALIZAR </a></td>
    </ng-container>

    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>
        <div class="thdisp">
          <input matInput type="text" class="searchinput uppercase" placeholder="ESTADO"
            (keyup)="doFilter($event, 'estado', 'LIKE', 'STRING')"
            [ngStyle]="{'min-width.px':  tableService.calcTextWidth('ESTADO', '600 14px Montserrat, sans-serif')}">
          <a [ngClass]="ordenarCampos['estado'] ? 'fechaup':'fechadawn'" (click)=" ordenar('estado')"></a>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{element.estado?"Vista": "Pendiente"}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="estadoOc">
      <th mat-header-cell *matHeaderCellDef>
        <div class="thdisp">
          <input matInput type="text" class="searchinput uppercase" placeholder="ESTADO OC"
            (keyup)="doFilter($event, 'estado', 'LIKE', 'STRING')"
            [ngStyle]="{'min-width.px':  tableService.calcTextWidth('ESTADO', '600 14px Montserrat, sans-serif')}">
          <a [ngClass]="ordenarCampos['estado'] ? 'fechaup':'fechadawn'" (click)=" ordenar('estado')"></a>
        </div>
      </th>
      <td mat-cell *matCellDef="let element"> <span class="inline_text">{{element.ordenCompra.estado.nombre}}</span> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table></div>
  <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
  (page)="nextPage($event)">
</mat-paginator>