import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManualService {
  API_URL = environment.API_URL;

  constructor(private http: HttpClient) { }

  /* 
  Servicio que conecta con el back para el listado de los textos del manual
  */
  getAllManual() {
    return this.http.get(`${this.API_URL}/manual/list`);
  }
}
