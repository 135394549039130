import { Component } from '@angular/core';

@Component({
  selector: 'app-listado-presupuestos',
  templateUrl: './listado-presupuestos.component.html',
  styleUrls: ['./listado-presupuestos.component.scss']
})
export class ListadoPresupuestosComponent {

}
