import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FilterService } from 'src/app/services/filter.service';
import { ProveedorService } from 'src/app/services/proveedor.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { Router } from '@angular/router';
import { ProveedorExportProy, ProveedorListar } from 'src/app/models/proveedor';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { catchError } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-lista-proveedores',
  templateUrl: './lista-proveedores.component.html',
  styleUrls: ['./lista-proveedores.component.scss']
})
export class ListaProveedoresComponent {
  dataExport: any
  filters: any[] = [];
  pageSize = "99999";
  page = "0";
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  isFirst = true
  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  columnsNames: string[] = [];
  columnsNamescompleta: string[] = [];
  mostrarCampos: { [clave: string]: boolean } = {};
  mostrarColumns: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  dataSource = new MatTableDataSource<ProveedorListar>();
  listaProv: any = []
  evento: any = '';
  nombrePagina = 'PROVEEDORES';
  nombreBoton = 'Nuevo Proveedor';
  rutaNuevo = 'admin-contacine/nuevo-proveedor';
  hiddenColumns: string[] = ["id"];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalElements = 0

  dialogVisible: boolean = false
  idEliminar: any = 0



  constructor(
    private proveedorService: ProveedorService,
    private filterService: FilterService,
    public interfazInicial: InterfazInicialComponent,
    private router: Router,
    public tableService: TableService,
    private notificationService: NotificationService

  ) { }

  ngOnInit() {
    if (this.tableService.checkAnyAccionByPermiso(19, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    }
    this.filters = [
      {
        key: "fechaBaja",
        operator: "IS_NULL",
      }];
    if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
      this.tableService.subscribeToProyectoChanges(() => {
            this.filters = [
      {
        key: "fechaBaja",
        operator: "IS_NULL",
      }];
        this.getFilterProyecto()
        this.getProveedores();
      });
      this.getFilterProyecto();
    }
    this.getProveedores();
  }

  getFilterProyecto() {
    this.filters.push(
      {
        field_type: "STRING",
        key: "proyectos.id",
        operator: "CONTAINS_ID",
        value: this.interfazInicial.proyaux.id
      });
  }

  iniciarListas() {
    this.displayedColumnscompleta = []
    if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
      var a: any = sessionStorage.getItem(this.nombrePagina)
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {

      this.displayedColumns.forEach((element) => {
        if(!this.hiddenColumns.includes(element)) {
          this.mostrarCampos[element] = true;
          this.displayedColumnscompleta.push(element);
        }
      });
    }

    console.log(this.displayedColumns)
    this.actualizarTabla();
  }

  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }

  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    this.actualizarTabla();
  }


  doGeneralFilter() {

    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
    } else {
      this.filters = this.filterService.doGeneralFilter(this.evento, this.filters);
    }
    this.paginator.pageIndex = 0
    this.page = "0"
    this.getProveedores()
  }

  ordenar(property: string, sorts: any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (clave == property) {
        this.ordenarCampos[property] = !this.ordenarCampos[property];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.sorts = [{ key: property, direction: orden }];

    this.getProveedores();
  }

  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: '0', size: this.pageSize };
    this.getProveedores();
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    this.filters = this.filterService.doFilter(event, type, operator, field_type, this.filters,null)
    this.paginator.pageIndex = 0
    this.page = "0"

    this.filter.filters = this.filters
    this.getProveedores()
  }



  getProveedores() {
    console.log("filters", this.filters);

    this.filter = {
      filters: this.filters,
      page: this.page,
      size: this.pageSize,
      sorts: this.sorts,
    };
    this.proveedorService
      .filter(this.filter)
      .subscribe({
        next: (data: any) => {
          console.log(data.source);

          if (this.ordenarCampos['id'] == undefined) {
            this.ordenarCampos = this.filterService.crearCampos(data)
          }
          this.dataExport = []
          data.content.forEach((element: any) => {
            this.dataExport.push(new ProveedorExportProy(element))
          });

          this.listaProv = []
          if (data && data.content && data.content != null && data.content.length > 0) {

            /* Este es el objeto de la lista cambiando los campos del 
            contructor de orden se cambia los campos de orden en la tabla */
            var aux = new ProveedorListar(data.content[0])
            if (this.isFirst) {
              this.displayedColumns = Object.keys(aux);

              this.displayedColumns.push("acciones")
              this.paginator._intl = this.interfazInicial.paginator

              this.isFirst = false
              this.iniciarListas();
            }


            data.content.forEach((prov: any) => {
              this.listaProv.push(new ProveedorListar(prov))
            });

            this.totalElements = data.totalElements;

            this.dataSource = this.listaProv
            this.paginator.pageIndex = data.pageable.pageNumber

            this.dataSource.paginator = this.paginator;
          } else {
            this.dataSource = new MatTableDataSource();
          }
        },
        error: (err) => {
          console.log(err);

        }
      });
  }




  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()

    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  navegar(id: number, accion: string) {
    this.router.navigate([`admin-contacine/${accion}-proveedor/${id}`]);
  }




  nextPage(event: PageEvent) {
    this.page = event.pageIndex + "";
    this.pageSize = event.pageSize + "";

    this.getProveedores();
  }

  download(element: any) {
    var idsNext: any = []
    console.log(element);

    if (element.documentos && element.documentos.length > 0) {

      element.documentos.forEach((element: any) => {
        idsNext.push(element.id_nextcloud)
      });

      this.proveedorService.download(idsNext).subscribe({
        next: (data: ArrayBuffer) => {
          var file = new Blob([data], { type: 'application/zip' })
          var fileUrl = URL.createObjectURL(file)
          var a = document.createElement('a')
          a.href = fileUrl
          a.download = "Proveedor_" + element.id + ".zip"
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)

        },
        error: (err) => {

        }
      }

      )
    } else {
      this.notificationService.warning("ALERTA", "Este proveedor no tiene documentos.", 5000)
    }
  }

  delete(id: any) {
    this.proveedorService.deleteById(id).subscribe({
      next: (data) => {
        console.log(data);

      },
      error: (err) => {
        if (err.error && err.error.text) {
          this.notificationService.success("ÉXITO", err.error.text, 5000);
        } else {
          this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
        }
        this.idEliminar = 0
        this.dialogVisible = false
        this.getProveedores()
      }
    })
  }

}
