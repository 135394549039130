import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../services/token/token-storage.service';

const TOKEN_HEADER_KEY = 'accessToken';
const TOKEN_HEADER_KEY_AUTH = 'Authorization'


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private token: TokenStorageService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authRequest = request;
    const token = this.token.getToken();
       
    if (token != null) {

      if (this.token.isExpired(token)) {
        this.token.signOutExpiration()
      }
      authRequest = request.clone({
        headers:
          request.headers
            .set(TOKEN_HEADER_KEY_AUTH, 'Bearer ' + token)
            .set(TOKEN_HEADER_KEY, token)
            .set("Access-Control-Allow-Headers", "accessToken,Authorization")
            .set("Access-Control-Allow-Origin", '*')
            .set("Cache-Control","no-cache")
      });


      // for Node.js Express back-end
      //authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }
    return next.handle(authRequest);
  }

}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, }
]