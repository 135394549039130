import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { NotificationService } from 'src/app/services/notification.service';
import { InterfazInicialComponent } from '../interfaz-inicial/interfaz-inicial.component';
import { RaizComponent } from '../_raiz/raiz.component';
import { ExportExcelService } from 'src/app/services/servicios-funcionalidad/export-excel.service';
import { OcGastoService } from 'src/app/services/servicios-funcionalidad/oc-gasto.service';

@Component({
  selector: 'app-header-listados',
  templateUrl: './header-listados.component.html',
  styleUrls: ['./header-listados.component.scss'],
  animations: [dropdownAnimation]
})
export class HeaderListadosComponent {


  @Input() nombre: any;
  @Input() dataExport: any;
  @Input() canCreate: boolean = false;
  @Input() canEdit: boolean = false;
  @Input() canExport: boolean = false;
  @Input() canImport: boolean = false;
  @Input() canSend: boolean = false;
  @Input() canClose: boolean = false;
  @Input() nombreBoton: any
  @Input() displayedColumnscompleta: any[] = [];
  @Input() mostrarCampos: { [clave: string]: boolean } = {};
  @Input() rutaNuevo!: string;
  @Input() displayedColumns: any[] = []
  @Input() columnsNames?: any[] = [];
  @Input() columnsNamescompleta?: any[] = [];
  @Input() mostrarColumns?: { [clave: string]: boolean } = {};
  @Output() displayedColumnsChange = new EventEmitter<any[]>()
  @Input() isCards?: boolean
  @Input() isCreatePage?: boolean = false;
  @Input() isViewPage?: boolean = false;
  @Output() isCardsChange = new EventEmitter<boolean>()

  @Input() isSaved?: boolean
  @Output() isSavedChange = new EventEmitter<boolean>()
  @Input() isClearForm?: boolean
  @Output() isClearFormChange = new EventEmitter<boolean>()
  @Input() isImport?: boolean
  @Output() isImportChange = new EventEmitter<boolean>()
  @Input() isSending?: boolean
  @Output() isSendingChange = new EventEmitter<boolean>()
  @Input() action?: any
  @Output() actionChange = new EventEmitter<any>()
  @Input() isPasarACoste?: any
  @Output() isPasarACosteChange = new EventEmitter<any>()

  @Input() exportPDF?: any
  @Output() exportPDFChange = new EventEmitter<boolean>()

  @Input() eventoSubTipo?: any
  @Output() eventoSubTipoChange = new EventEmitter<any>()

  @Input() eventoTipo?: any
  @Output() eventoTipoChange = new EventEmitter<any>()


  funcionNuevo!: Function;
  @Input() exportPdf?: String;
  @Output() exportPdfChange = new EventEmitter<any>()
  @Input() evento?: String;
  @Output() eventoChange = new EventEmitter<any>()
  @Output() doFilterPadre = new EventEmitter()
  @Output() columnsNamesChange = new EventEmitter<any[]>()
  @Output() generarPDF = new EventEmitter()
  @Input() totalFacturado: any;
  @Input() pendienteFacturar: any;
  @Input() totalIRPF: any;
  @Input() totalIva: any;
  @Input() totalBase: any;
  @Input() pendienteOc: any;
  @Input() totalGasto: any;
  @Input() subTipos: any

  firstTime: boolean = false;


  constructor(
    public router: Router,
    private raiz: RaizComponent,
    public interfazInicial: InterfazInicialComponent,
    public exportService: ExportExcelService,
    private ocGastoService: OcGastoService,
    private notificationService: NotificationService,


  ) { }
  displayedColumnsFiltred: any

  filters: any[] = [];
  pageSize = 10;
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: 0,
    size: this.pageSize,
    sorts: this.sorts,
  };
  mostrarPopup = 'out';

  /**
   * Muestra u oculta un modal según el parámetro proporcionado.
   * @param mostrar El valor que indica si se debe mostrar u ocultar el modal.
   */
  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
    this.router.url.toLowerCase().includes('ver-')
  }
  /**
   * Evalúa el estado del checkbox de verificación según el índice proporcionado.
   * @param index El índice del checkbox de verificación a evaluar.
   */
  evaluarCheckbox(index: any) {
    let i = 0;
    for (let clave in this.mostrarCampos) {
      console.log(clave, i)
      if (index == i) {

        this.mostrarCampos[clave] = !this.mostrarCampos[clave];
        break;
      }
      i++;
    }
    i = 0;
    if (this.mostrarColumns) {
      for (let clave in this.mostrarColumns) {
        console.log(clave, i)
        if (index == i) {
          this.mostrarColumns[clave] = !this.mostrarColumns[clave];
          break;
        }
        i++;
      }
    }


    this.actualizarTabla();
  }

  /**
   * Actualiza la tabla con las columnas mostradas según los filtros aplicados.
   */
  actualizarTabla() {
    /*   if (sessionStorage.getItem(this.nombre) && sessionStorage.getItem(this.nombre) != "undefined") {
        var a: any = sessionStorage.getItem(this.nombre)
        this.mostrarCampos = JSON.parse(a);
      } */
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    if (this.columnsNamescompleta != undefined) {
      this.columnsNames = [];
      this.columnsNames?.push(...this.columnsNamescompleta);
    }
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);

      }
    }
    if (this.mostrarColumns && this.columnsNames) {
      for (let clave in this.mostrarColumns) {
        console.log(clave)
        if (this.mostrarColumns[clave] == false) {
          this.columnsNames.splice(this.columnsNames.indexOf(clave), 1);
        }
      }
    }

    this.displayedColumnsChange.emit(this.displayedColumns)
    console.log(this.columnsNames, this.displayedColumns);
    if (this.columnsNames) {
      this.columnsNamesChange.emit(this.columnsNames)
    }

    sessionStorage.setItem(this.nombre, JSON.stringify(this.mostrarCampos))

  }

  /**
   * Muestra todas las columnas en la tabla.
   */
  vetTodo() {
    console.log("primero");
    console.log("nombre ->" + this.nombre)
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    if (this.mostrarColumns) {
      for (let columna in this.mostrarColumns) {
        if (this.mostrarColumns[columna] == false) {
          this.mostrarColumns[columna] = true;
        }
      }
    }
    this.actualizarTabla();
  }

  /**
   * Evalúa el tamaño de la página y actualiza el filtro en consecuencia.
   * @param event El evento del cambio de tamaño de la página.
   */
  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    console.log(event)
    console.log(event.value)
    if (event.value == null) {
      this.filter = { filter: this.filter, page: '0', size: null };
    }
    else {
      this.filter = { filter: this.filter, page: '0', size: this.pageSize };
    }
    this.eventoChange.emit({ pageSize: event.value })
    this.doFilterPadre.emit()
  }

  /**
   * Envía un string de filtro al componente padre.
   * @param event El evento que contiene el valor del filtro.
   */
  EnviarStringFilter(event: any) {
    this.eventoChange.emit(event.target.value)
    this.doFilterPadre.emit()

  }
  /**
   * Navega a una nueva página.
   */
  nuevo() {
    console.log(this.rutaNuevo);
    if (this.rutaNuevo == "admin-contacine/nuevo-gasto") {
      this.ocGastoService.setDocumento(undefined);
      this.ocGastoService.setOc(undefined)
    }
    this.router.navigateByUrl(this.rutaNuevo)
  }
  /**
   * Convierte la primera letra de cada palabra en mayúscula.
   * @param text El texto a formatear.
   * @returns El texto formateado con la primera letra de cada palabra en mayúscula.
   */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()
    return text.charAt(0).toUpperCase() + text.slice(1)

  }
  /**
  * Regresa a la página anterior en el historial del navegador (solo dentro de la app).
  */
  goBack() {
    this.raiz.goBack()
  }

  /**
   * Filtra las columnas de la tabla según el valor proporcionado.
   * @param event El evento del cambio en el campo de filtro.
   */
  filterCols(event: any) {
    var filterValue: any
    try {
      filterValue = event.target.value.toLowerCase().replaceAll(" ", "");
      console.log(
        event.target.value)
    } catch {
      filterValue = "";
    }

    if (filterValue) {
      this.displayedColumnsFiltred = this.displayedColumnscompleta.filter(option =>
        (option.toLowerCase().replaceAll(" ", "").includes(filterValue)));
    } else {
      this.displayedColumnsFiltred = this.displayedColumnscompleta
    }
  }

  notifExport() {
    console.log(this.canExport);
    this.notificationService.warning("ALERTA", "No tienes permisos para exportar.", 5000)
  }
  detalle: string = 'in'

  verDetalle2(detalle: string) {
    this.detalle = detalle;
  }

  generarPDFOc() {
    this.generarPDF.emit()
  }

  getHeaderClassColor(input: string) {
    if(input.toLowerCase().includes('proyecto')) return "headerClassProyecto";
    if(input.toLowerCase().includes('orden')) return "headerClassOC";
    if(input.toLowerCase().includes('gasto')) return "headerClassGasto";
    if(input.toLowerCase().includes('presu')) return "headerClassPresu";
    if(input.toLowerCase().includes('control')) return "headerClassControl";
    if(input.toLowerCase().includes('pago')) return "headerClassPago";
    if(input.toLowerCase().includes('laboral')) return "headerClassLaboral";
    if(input.toLowerCase().includes('justif')) return "headerClassJustificaciones";
    if(input.toLowerCase().includes('proveedor')) return "headerClassProveedores";
    if(input.toLowerCase().includes('informe')) return "headerClassInformes";
    return "headerClassProyecto"
  }
}
