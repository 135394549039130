import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RegistroGastosService {
  API_URL = environment.API_URL;
  /* 
  Servicio para las peticiones al back para la modificacion,guardado y listado de gastos
  */
  constructor(private http: HttpClient) {}

  getRegistros(request: any) {
    return this.http.post(`${this.API_URL}/gasto/filter`, request);
  }

  getPagos(request: any) {
    return this.http.post(`${this.API_URL}/pago/filter`, request);
  }

  /**
* Recibe un gasto del formulario y guarda el gasto en la base de datos
* @param  gasto    
* @param  concepto 
* @param  pago 
* @param  documento 
* @return devuelve el estado y el gasto creado
*/  
  save(gasto: any, concepto: any, pago: any, documento: any, userId: any, deparamentoId:any) {
   if(documento[0].nombre==undefined){
      documento=[]
   }
   concepto.forEach((element:any) => {
    element.codigo={id:element.codigo.id, cuenta: element.codigo.cuenta}
    element.conceptoOc={ id:element.conceptoOc.id}
   });

   pago.forEach((element:any) => {
    element.concepto.codigo={id:element.concepto.codigo.id, cuenta: element.concepto.codigo.cuenta}
    element.concepto.conceptoOc={ id:element.concepto.conceptoOc.id}
   });
    var request = {
      gasto,
      concepto: concepto,
      pago: pago,
      documentos:documento, 
      user:{id:userId},
      departamento:{id:deparamentoId}
    };
    console.log(request)
    return this.http.post(`${this.API_URL}/gasto/save`, request);
  }


    /**
* Recibe un gasto del formulario y actualiza el gasto en la base de datos
* @param  gasto    
* @param  concepto 
* @param  pago 
* @param  documento 
* @return devuelve el estado y el gasto actualizado
*/
  update(gasto: any, concepto: any, pago: any ,documento: any,  userId: any,deparamentoId:any) {  
      if(documento[0].nombre==undefined){
      documento=[]
   }
   concepto.forEach((element:any) => {
    element.codigo={id:element.codigo.id, cuenta: element.codigo.cuenta}
    element.conceptoOc={ id:element.conceptoOc.id}
   });

   pago.forEach((element:any) => {
    element.concepto.codigo={id:element.concepto.codigo.id, cuenta: element.concepto.codigo.cuenta}
    element.concepto.conceptoOc={ id:element.concepto.conceptoOc.id}
    element.concepto.ordenCompra={id: element.concepto.ordenCompra.id}

   });
    var request = {
      gasto,
      concepto: concepto,
      pago: pago,
      documentos:documento, 
      user:{id:userId},
      departamento:{id:deparamentoId}
    };
    return this.http.post(`${this.API_URL}/gasto/update`, request);
  }

  /**
   * @return devuelve una lista con todos los tipos de gasto
   */
  getAllTiposGasto() {
    return this.http.get(`${this.API_URL}/gasto/getAllTiposGasto`);
  }

   /**
   * @return devuelve una lista con todos los períodos de iva
   */
  getAllPeriodosIva() {
    return this.http.get(`${this.API_URL}/gasto/getAllPeriodosIva`);
  }

   /**
   * @return devuelve una lista con todos los tipos de pago
   */
  getAllTiposPagos() {
    return this.http.get(`${this.API_URL}/gasto/getAllTiposPagos`);
  }

   /**
    * Busca un gasto por su id
   * @param id 
   * @return devuelve un gasto asociado a la id que le pasas 
   */
  findGastoByid(id: any) {
    return this.http.get(`${this.API_URL}/gasto/findGastoByid/${id}`);
  }

  /**
  * Borra un gasto por su id
   * @param id 
   * @return devuelve el estado del borrado del gasto
   */
  deleteGasto(id: any) {
    return this.http.get(`${this.API_URL}/gasto/deleteGasto/${id}`);
  }

  /**
  * Borra un gastos por su id
   * @param idList 
   * @return devuelve el estado del borrado del gasto
   */
  deleteListGastos(idList: any) {
    return this.http.post(`${this.API_URL}/gasto/deleteList`, idList);
  }

  isValidFactura(idProveedor:any,idProyecto: any, numFactura:any){
  var request = {
    idProveedor: idProveedor,
    idProyecto: idProyecto,
    numFactura: numFactura,
  };
  return this.http.post(`${this.API_URL}/gasto/isValidFactura`, request);
}

importJustificaciones(file: File, usuarioid:any, proyectoid:any, departamentoid:any ) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('usuarioId', usuarioid);
  formData.append('proyectoId',proyectoid);
  formData.append('departamentoId', departamentoid);
  return this.http.post(`${this.API_URL}/gasto/import`,formData);
}
}
