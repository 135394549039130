<h1 mat-dialog-title>Doble factor</h1>
<div>
    <div mat-dialog-content id="qr" *ngIf="!tfaFlag">
        Se ha solicitado un reestablecimiento de Two Factor.
        Vuelva a escanear el código QR con la aplicación Authenticator en su móvil
        <div>
            <img [src]=qr.secretURL class="m-2" />


            <form [formGroup]="form2">
                <input type="text" name="totpkey" formControlName="totpkey"
                    class="col-sm-4 mr-2 text-center form-control" placeholder="Código Authenticator" maxlength="6"
                    [ngClass]="{'is-invalid': submitted && this.form2.controls['totpkey'].errors}">
                <div class="d-flex justify-content-center">
                    <button class="btn buttonSecondary btn-block" type="button" (click)="cerrar()">Cerrar</button>
                    <button type="button" class="btn btn-primary m-left" (click)="guardarQR()">
                        Activar 2FA
                    </button>
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                </div>
            </form>
        </div>
        <div *ngIf="errorQR" class="m-2">
            <p>Error al activar doble factor.
                Por favor vuelva a escaner el código QR e Introduzca los 6 dígitos.
            </p>
        </div>
    </div>
    <div mat-dialog-content id="qr" *ngIf="tfaFlag">
        <form [formGroup]="form2">
            <input type="text" name="totpkey" formControlName="totpkey" class="col-sm-4 mr-2 text-center form-control"
                placeholder="Código Authenticator" maxlength="6"
                [ngClass]="{'is-invalid': submitted && this.form2.controls['totpkey'].errors}">
            <div class="form-group">
                <button class="btn buttonSecondary btn-block" type="button" (click)="cerrar()">Cerrar</button>
                <button class="btn buttonPrimary btn-block" type="button" (click)="loginTfaEnabled()">Confirmar</button>
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            </div>
        </form>
    </div>
</div>