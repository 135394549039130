import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-raiz',
  templateUrl: './raiz.component.html',
  styleUrls: ['./raiz.component.scss']
})
export class RaizComponent {
  currentEnv: string;
  currentLocation:string = "";
  version: string;
  public reloadHeader:boolean = false;

  constructor(
    public location: Location,
  ) {
    this.currentEnv = environment.environment;    
    this.version = environment.version;
  }


  ngOnInit(): void {
  }

 public goBack(){
    this.location.back()
  }
}
