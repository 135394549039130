<app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
    [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
    [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento" [dataExport]="dataExport"
    [canCreate]="tableService.checkPermiso(12,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [canExport]="tableService.checkPermiso(12,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [totalFacturado]="totalFacturado" [pendienteFacturar]="pendienteFacturar" [totalIRPF]="totalIRPF"
    [totalIva]="totalIva" [totalBase]="totalBase" [totalGasto]="totalConceptos"></app-header-listados>
<div>
    <div class="fondoblanco fondoblanco_tabla">
        <div class="table_container data_table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
                <!-- numero Column -->
                <span *ngFor="let column of displayedColumns ">
                    <!-- Este es el listado de las cabeceras quitando el de acciones seria copiar y pegar, es importante saber que se va a poner
                            el mismo nombre que tenga en el objeto de la lista pero tanto el objeto de kla lista y el del back tienen que tener los mismos
                             nombres pero puedes quitar campos que no se quieren listar -->
                    <ng-container
                        *ngIf="column!='acciones'&& column!='cerrada' && column!='JC'&&column!='JD'&&column!='DP' "
                        matColumnDef="{{column}}">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="thdisp ">
                                <input *ngIf="tableService.checkPro(column,interfazInicial.isSuperAdmin)" matInput
                                    type="text" class="searchinput uppercase"
                                    placeholder="{{column=='totalIrpf'?'IRPF': column=='totalIva'?'IMPUESTO':column=='totalConceptos'?'TOTAL':column=='fechaModificacion'?'FECHA MODIFICACIÓN': column=='pendientePago'?'PENDIENTE FACTURA': column=='descripcionConcepto'?'DESCRIPCIÓN': column=='codigoConcepto'?'CONCEPTO': column=='conceptos'?'CUENTA' :primLetMay(column) }}"
                                    [ngClass]="column=='totalIrpf' || column=='totalIva' || column=='totalConceptos' || column=='pendientePago' || column=='fianza' || column=='base' || column=='facturado'? 'input-right':''"
                                    (keyup)="doFilter($event, 
                                column == 'proveedor'?'proveedor.nombreFiscal':
                                column == 'productora'?'productora.nombre':
                                column == 'estado'||column == 'departamento'?column+'.nombre':
                                column == 'conceptos'?'conceptos.codigo.cuenta':
                                column == 'codigoConcepto'?'conceptos.codigo.concepto':
                                column == 'descripcionConcepto'?'conceptos.descripcion':
                                column == 'gastos'?'gastos.id':column == 'solicitante'?'solicitante.nombreUsuario'
                                :column,
                                column == 'conceptos' || column == 'descripcionConcepto' || column == 'codigoConcepto' || column == 'gastos'? 'LIST_CONTAINS'
                                :'LIKE', 'STRING')"
                                    [ngStyle]="{'min-width.px': tableService.calcTextWidth(column=='pendientePago'?'PENDIENTE FACTURA': column=='totalConceptos'?'TOTAL': column=='conceptos'?'CUENTA' :column, '600 14px Poppins, sans-serif')}">
                                <a [ngClass]="
                            column == 'conceptos' || column == 'descripcionConcepto' || column == 'codigoConcepto' || column == 'gastos'?'': ordenarCampos[column] ? 'fechaup':'fechadawn'"
                                    (click)="column == 'conceptos' || column == 'descripcionConcepto' || column == 'codigoConcepto' || column == 'gastos'?'': column == 'proveedor'? ordenar('proveedor.nombreFiscal'): column == 'productora'? ordenar('productora.nombre'): column == 'solicitante'? ordenar('solicitante.nombreUsuario'): ordenar(column)"></a>
                            </div>
                        </th>
                        <tr>
                            <span *ngIf="column!='totalConceptos' && column!='totalIva' && column!='totalIrpf' && column!='base'&& column!='facturado' && 
                            column!='pendientePago' && column!='pendienteFacturar' &&column!='fianza'">
                                <td *matCellDef="let element; let i = index" class="celds">
                                    <!-- Aqui si todos los elementos fuesen unicops seria el  <span >{{element}}</span>  sino se 
                                    tendria que hacer como aqui las comprobaciones dependiendo el caso pero para listas que 
                                    no tienen objetos añadidos seria solo esa linea-->

                                    <span class="inline_text"
                                        *ngIf="column!='numOc' && column!='conceptos' && column!='gastos' &&column!='pagoOc' && column!='proveedor' && column!='productora' && column!='proyecto' && column!='estado'&& column!='departamento' && column!='totalIrpf' 
                                && column!='totalIva'&& column!='fianza' && column!='totalConceptos' && column!='base'&&column!='JC'&&column!='JD'&&column!='DP'&&column!='solicitante' &&column!='facturado' &&column!='pendientePago' && column!='pendienteFacturar' ">
                                        {{element[column]}}
                                    </span>

                                    <span class="inline_text" *ngIf="column == 'numOc'">
                                        {{aniadirOCNumber(element[column])}}
                                    </span>

                                    <span class="inline_text" *ngIf="column == 'conceptos'">
                                        {{element[column][0].codigo.cuenta}}
                                    </span>

                                    <span class="inline_text" *ngIf="column == 'codigoConcepto'" [matTooltip]="conceptosTooltip(i)" matTooltipClass="tooltip-class" matTooltipPosition="after">
                                        {{getConcepto(element['conceptos'][0].codigo)}}
                                    </span>

                                    <span class="inline_text" *ngIf="column == 'descripcionConcepto'">
                                        {{element['conceptos'][0].descripcion}}
                                    </span>

                                    <p class="inline_text numberInput" [ngClass]="element[column]<0?'letra-red':''"
                                        *ngIf="column=='totalConceptos' || column=='totalIva' || column=='totalIrpf' || column=='base'|| column=='facturado' || column=='pendientePago' || column=='pendienteFacturar' ">
                                        {{element[column]|numberEsp}}€
                                    </p>
                                    <span class="inline_text" *ngIf="column=='gastos'">
                                        <a class="pointer" (click)="navegarAGasto(gasto.id)"
                                            *ngFor="let gasto of element[column] ;let i = index">{{gasto.id}} <span
                                                *ngIf="i!=element[column].length-1">, </span> </a>
                                    </span>

                                    <!-- <span class="inline_text" *ngIf="column=='conceptos'">
                                        <span
                                            *ngFor="let concepto of element[column] ;let i = index">{{concepto.descripcion}}
                                            <span *ngIf="i!=element[column].length-1">, </span></span>
                                    </span> -->

                                    <!-- <span class="inline_text" *ngIf="column=='pagoOc'">
                                {{element[column].observaciones}}
                            </span> -->
                                    <span class="inline_text numberInput"
                                        [ngClass]="element.pagoOc.fianza<0?'letra-red':''" *ngIf="column=='fianza'">
                                        {{element.pagoOc.fianza|numberEsp}}€
                                    </span>
                                    <span class="inline_text" *ngIf="column=='proveedor'">
                                        {{element[column].nombreFiscal}}
                                    </span>
                                    <span class="inline_text" *ngIf="column=='productora' && element[column]">
                                        {{element[column].nombre}}
                                    </span>
                                    <span class="inline_text"
                                        *ngIf="column=='proyecto'&&tableService.checkPro(column,interfazInicial.isSuperAdmin)">
                                        {{element[column].titulo}}
                                    </span>
                                    <span class="inline_text" *ngIf="column=='estado' || column=='departamento'">
                                        {{element[column].nombre}}
                                    </span>
                                    <span class="inline_text" *ngIf="column=='solicitante'">
                                        {{element[column].nombreUsuario}}
                                    </span>

                                </td>
                            </span>
                            <span *ngIf="column=='totalConceptos' || column=='totalIva' || column=='totalIrpf' || column=='base'|| column=='facturado' || 
                            column=='pendientePago' || column=='pendienteFacturar' ||column=='fianza'">


                                <td *matCellDef="let element" class="numberInput"
                                    [ngClass]="element[column]<0?'letra-red':''">

                                    <!-- Aqui si todos los elementos fuesen unicops seria el  <span >{{element}}</span>  sino se 
                                    tendria que hacer como aqui las comprobaciones dependiendo el caso pero para listas que 
                                    no tienen objetos añadidos seria solo esa linea-->




                                    <span class="inline_text numberInput"
                                        *ngIf="column=='totalConceptos' || column=='totalIva' || column=='totalIrpf' || column=='base'|| column=='facturado' || column=='pendientePago' || column=='pendienteFacturar' ">
                                        {{element[column]|numberEsp}}€
                                    </span>



                                    <span class="inline_text numberInput"
                                        [ngClass]="element.pagoOc.fianza<0?'letra-red':''" *ngIf="column=='fianza'">
                                        {{element.pagoOc.fianza|numberEsp}}€
                                    </span>






                                </td>
                            </span>
                        </tr>
                    </ng-container>
                </span>
                <ng-container matColumnDef="JC">
                    <th mat-header-cell *matHeaderCellDef class="estadosClass"> <span>
                            J.C</span> </th>
                    <td *matCellDef="let element">

                        <div class="rounded-circle"
                            [ngClass]="element.aceptado_jefe_contabilidad.id==1?'div_circle-aceptada' : element.aceptado_jefe_contabilidad.id==3? 'div_circle_rechazada' :'div_circle_pendiente'">
                            <a
                                [ngClass]="element.aceptado_jefe_contabilidad.id==1?'done-button' : element.aceptado_jefe_contabilidad.id==3? 'cancel-button' :'warning-button'"></a>

                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="JD">
                    <th mat-header-cell *matHeaderCellDef class="estadosClass"> <span>
                            J.D</span> </th>
                    <td *matCellDef="let element">


                        <div class="rounded-circle"
                            [ngClass]="element.aceptado_jefe_departamento.id==1?'div_circle-aceptada' : element.aceptado_jefe_departamento.id==3? 'div_circle_rechazada' :'div_circle_pendiente'">
                            <a
                                [ngClass]="element.aceptado_jefe_departamento.id==1?'done-button' : element.aceptado_jefe_departamento.id==3? 'cancel-button' :'warning-button'"></a>
                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="DP">
                    <th mat-header-cell *matHeaderCellDef class="estadosClass"> <span>
                            D.P</span> </th>
                    <td *matCellDef="let element">

                        <div class="rounded-circle"
                            [ngClass]="element.aceptado_director_produccion.id==1?'div_circle-aceptada' : element.aceptado_director_produccion.id==3? 'div_circle_rechazada' :'div_circle_pendiente'">
                            <a
                                [ngClass]="element.aceptado_director_produccion.id==1?'done-button' : element.aceptado_director_produccion.id==3? 'cancel-button' :'warning-button'"></a>
                        </div>


                    </td>
                </ng-container>

                <ng-container matColumnDef="cerrada">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="thdisp inline_text">
                            <span class="textwe600 uppercase">Cerrar OC</span>
                        </div>
                    </th>
                    <td *matCellDef="let element"><span *ngIf="element.estado.id === 3">Cerrada</span>
                        <span *ngIf="element.estado.id !== 3">
                            <div class="activo pointer" (click)="dialogCerrar=true;idCerrar=element.id"> Cerrar OC</div>
                        </span>
                    </td>
                </ng-container>

                <!-- Aqui va la columna de acciones para que se pueda hacer fija -->
                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="accionesth"><span
                            class="correccionmargenAcciones uppercase">
                            Acciones</span>
                    </th>
                    <td *matCellDef="let element" class="accionesth">
                        <div class="acciones" stickyEnd>
                            <a title="Ver"
                                [ngClass]="tableService.checkPermiso(12,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'view-button pointer'"
                                (click)=" tableService.checkPermiso(12,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'ver')"></a>
                            <a title="Editar"
                                [ngClass]="tableService.checkPermiso(12,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)|| comprobarEditarBorrar(element)? 'display-edit-button':'edit-button pointer'"
                                (click)=" tableService.checkPermiso(12,3,interfazInicial.isSuperAdmin,interfazInicial.permisos) ||  comprobarEditarBorrar(element)?'':navegar(element.id,'editar')"></a>
                            <a title="Descargar"
                                [ngClass]="tableService.checkPermiso(12,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)?  'display-download-button':'download-button '"
                                (click)=" tableService.checkPermiso(12,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':download(element)"></a>
                            <a title="Borrar"
                                [ngClass]="tableService.checkPermiso(12,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)||comprobarEditarBorrar(element)? 'display-delete-button':'delete-button pointer'"
                                (click)=" tableService.checkPermiso(12,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)||comprobarEditarBorrar(element)?'':dialogVisible = true; idEliminar = element"></a>
                        </div>

                    </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                    (mouseenter)="tableService.marcarColumna(row,true)"
                    (mouseleave)="tableService.marcarColumna(row,false)" (click)="tableService.marcarColumna(row,null)"
                    *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
            (page)="nextPage($event)">
        </mat-paginator>
    </div>

    <div class="dialog-container" *ngIf="dialogVisible">
        <div class="dialog-content">
            <div class="contenedortextos">
                <div class="circle_document">
                    <a class="delete-div"></a>
                </div>
            </div>
            <div class="contenedortextos">
                <h4 class="colorh4">¿Desea eliminar la orden de compra {{idEliminar.numOc}}?</h4>
            </div>
            <div class="contenedortextos2">
                <p>Si se elimina la orden de compra se eliminarán los conceptos y documentos que tenga asociados</p>
            </div>
            <div class="dialog_buttons">
                <button (click)="dialogVisible = false" id="btcancel"
                    class="btn btn-primary mx-1 mt-3">Cancelar</button>
                <button id="btacept" class="btn btn-primary mx-1 mt-3">
                    <div></div>
                    <span (click)="delete(idEliminar.id)" class="align-middle"> Confirmar</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="dialog-container" *ngIf="dialogCerrar">
    <div class="dialog-content">
        <div class="contenedortextos">
            <div class="circle_document">
                <a class="warning-button-modal"></a>
            </div>
        </div>
        <div class="contenedortextos">
            <h4 class="colorh4"><b>¡Alerta! Estas a punto de cerrar la OC</b></h4>
        </div>
        <div class="contenedortextos2">
            <p class="text-center">Al cerrar esta orden de compra ya no se podra<br> editar ni generar gastos asociados
                a ella</p>
            <p class="text-center">¿Desea cerrar la Orden de Compra?</p>
        </div>


        <div class="contenedortextos">
            <!-- <mat-form-field *ngIf="mostrarConceptos.length!=0" appearance="outline" class="col-12 formField">
                <mat-select class="seleccionar_archivo_select" matInput (selectionChange)="cambiarCuenta($event)"
                    placeholder="Seleccione un concepto">
                    <mat-option *ngFor="let item of mostrarConceptos" [value]="item">
                        <span> <b>Cuenta:</b> {{ item.codigo.cuenta }}, <b>Concepto:</b> {{ item.codigo.concepto }},
                            <b> Tipo:</b>{{item.tipoConceptoOc.nombre}}, <b> Importe total: </b>{{item.suma}}
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mx-1 " (click)="cerrarOc(idCerrar)">Aceptar</button>

            <button (click)="dialogCerrar=false" class="btn btn-primary mx-1 ">Cancelar</button>
        </div>
    </div>
</div>