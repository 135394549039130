import { BooleanInput } from '@angular/cdk/coercion';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { AccionPermiso, Permiso, PermisoAccionList } from 'src/app/models/permiso';
import { Proyecto } from 'src/app/models/proyecto';
import { Departamento, Usuario } from 'src/app/models/usuario';
import { DepartamentoService } from 'src/app/services/departamento.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { PermisosService } from 'src/app/services/permisos.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { TipoUsuarioService } from 'src/app/services/usuario/tipo-usuario.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { ProductoraService } from 'src/app/services/productora.service ';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.scss'],
  animations: [dropdownAnimation],

})
export class NuevoUsuarioComponent {

  loading: boolean = false;
  showPass: boolean = false
  showRepPass: boolean = false

  mostrarPopup = 'out';

  constructor(
    private notificationService: NotificationService,
    private permisosService: PermisosService,
    private proyectoService: ProyectoService,
    private tipoUsuService: TipoUsuarioService,
    private tokenStorage: TokenStorageService,
    private permisoProyecto: PermisosProyectoService,
    private departamentoService: DepartamentoService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private router: Router,
    private interfazInicial: InterfazInicialComponent,
    private storrageService: StorageServiceService,
    private tableService: TableService,
    private productoraService: ProductoraService
  ) { }
  displayedColumns: string[] = ["Nombre", "Ver", "Crear", "Editar", "Aprobar", "Eliminar", "Todos"];

  isCreatePage = true
  isSave = false
  isClearForm = false
  nombreBoton = "Guardar Usuario"
  nombrePagina = "Usuario"


  form!: FormGroup;
  form2: FormGroup = new FormGroup({
    proyecto: new FormControl("", Validators.required),
    usuario: new FormControl("", Validators.required),
    tipoUsuario: new FormControl("", Validators.required),
    permisos: new FormControl("", Validators.required),
  })
  permisos?: PermisoAccionList[] = [];
  permisosAux?: Permiso[];
  permisosFinales: any[] = [];
  accionPermisos?: AccionPermiso[]

  proyectosList: any[] = [];
  everyProyect: any[] = [];

  proyAux: any = []
  proyectosSeleccionadosList: any[] = [];

  tiposUsuas: any
  usuario: Usuario = new Usuario()

  departamentos?: Departamento[]

  isUpdate: boolean = false;
  isReading: boolean = false;
  id!: number;

  isAdmin = false
  proyectosFinales: any = []


  private formBuilder: FormBuilder = new FormBuilder

  get infoProyecto(): FormArray {
    return this.form.get("infoProyecto") as FormArray;
  }

  ngOnInit(): void {

    if (this.interfazInicial.isSuperAdmin || this.interfazInicial.isLicencia) {
      this.isAdmin = true;
    }
    this.permisosFinales = [];
    if (this.tableService.checkAnyAccionByPermiso(22, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    } else {

      this.route.params.subscribe(params => {
        if (params['id']) {
          this.id = params['id'];


          this.route.url.subscribe(data => {
            if (data[0].path.includes("ver")) {
              this.isReading = true
              if (this.tableService.checkPermiso(22, 1, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }

            } else if (data[0].path.includes("editar")) {
              this.isUpdate = true

              if (this.tableService.checkPermiso(22, 3, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
                this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
                this.router.navigate(["admin-contacine/proyectos"])
              }
            }

          });

        } else {
          if (this.tableService.checkPermiso(22, 2, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
            this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
            this.router.navigate(["admin-contacine/proyectos"])
          }
        }

      })
    }
    var storedFormData: any = {}
    if (!this.isUpdate && !this.isReading) {
      try {
        storedFormData = JSON.parse(this.storrageService.get('userForm') ? this.storrageService.get('userForm') : "");
      } catch {

      }
    }

    console.log("storedFormData", storedFormData);

    this.form = new FormGroup({
      email: new FormControl({ value: storedFormData.email || "", disabled: this.isReading }, [Validators.required, Validators.email]),
      nombreUsuario: new FormControl({ value: storedFormData.nombreUsuario || "", disabled: this.isReading }, Validators.required),
      twoFactor: new FormControl("false", Validators.required),
      password: new FormControl({ value: storedFormData.password || "", disabled: this.isReading }),
      reppassword: new FormControl({ value: storedFormData.reppassword || "", disabled: this.isReading }),
/*       departamento: new FormControl({ value: storedFormData.email||"", disabled: this.isReading }, Validators.required),
 */      proyectos: new FormControl({ value: "", disabled: this.isReading }),
      cargo: new FormControl({ value: storedFormData.cargo || "", disabled: this.isReading }),
      direccion: new FormControl({ value: storedFormData.direccion || "", disabled: this.isReading }),
      codigoPostal: new FormControl({ value: storedFormData.codigoPostal || "", disabled: this.isReading }, [Validators.pattern('^[0-9]{5}$')]),
      ciudad: new FormControl({ value: storedFormData.ciudad || "", disabled: this.isReading }),
      pais: new FormControl({ value: storedFormData.pais || "", disabled: this.isReading }),
      dni: new FormControl({ value: storedFormData.dni || "", disabled: this.isReading }, Validators.required),
      telefono: new FormControl({ value: storedFormData.telefono || "", disabled: this.isReading }, [Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)]),
      infoProyecto: new FormArray([
        new FormGroup({
          proyecto: new FormControl({ value: this.isAdmin ? "" : { id: this.interfazInicial.proyaux.id }, disabled: this.isReading }, [Validators.required]),
          departamento: new FormControl({ value: "", disabled: this.isReading }, [Validators.required]),
          tipoUsuario: new FormControl({ value: "", disabled: this.isReading }, [Validators.required]),
          empresa: new FormControl({ value: "" || "", disabled: this.isReading }),

        }),
      ]),
    })
    if (!this.isUpdate && !this.isAdmin) {
      this.checkEmpresaList(this.interfazInicial.proyaux, 0)
    }
    if (storedFormData.infoProyecto) {
      this.infoProyecto.clear()
      storedFormData.infoProyecto.forEach((e: any) => {

        this.addInfoProyectoForUpdate(e.proyecto ? e.proyecto.id || "" : "", e.departamento ? e.departamento.id || "" : "", e.tipoUsuario || "", e.empresa ? e.empresa : "")
      })
    }
    console.log(this.form.value);

    if (!this.isReading && !this.isUpdate) {
      this.form.controls["password"].setValidators([Validators.required, Validators.minLength(6)])
      this.form.controls["reppassword"].setValidators([this.isSamePasword()])
    }
    this.departamentoService.getAllDepartamentos().subscribe({
      next: (data) => {
        this.departamentos = data
      },

    })
    if (this.isUpdate || this.isReading && this.id) {
      this.infoProyecto.clear()
      this.usuarioService.findById(this.id).subscribe((data) => {
        console.log(data);

        this.usuario = new Usuario(data)

        this.form.get('email')?.setValue(this.usuario.email)
/*         this.form.get('departamento')?.setValue(this.usuario.departamento)
 */        this.form.get('nombreUsuario')?.setValue(this.usuario.nombreUsuario)
        this.form.get('empresa')?.setValue(this.usuario.empresa)
        this.form.get('twoFactor')?.setValue(this.usuario.twoFactorEnabled)

        this.usuario.proyectos?.forEach((data) => {
          this.proyAux.push({ id: data.id })
        })
        console.log(this.proyAux);

        this.form.get('proyectos')?.setValue(this.proyAux)
        this.form.get('cargo')?.setValue(this.usuario.cargo)
        this.form.get('direccion')?.setValue(this.usuario.direccion)
        this.form.get('codigoPostal')?.setValue(this.usuario.codigoPostal ? this.usuario.codigoPostal : '')
        this.form.get('ciudad')?.setValue(this.usuario.ciudad)
        this.form.get('telefono')?.setValue(this.usuario.telefono ? this.usuario.telefono : '')
        this.form.get('pais')?.setValue(this.usuario.pais ? this.usuario.pais : '')
        this.form.get('dni')?.setValue(this.usuario.dni ? this.usuario.dni : '')
        if (this.usuario.proyectos && this.usuario.proyectos.length != 0) {
          if (this.isAdmin) {

            this.proyectosSeleccionadosList = this.usuario.proyectos
            this.usuario.proyectos.forEach((proy) => {
              this.permisoProyecto.getPermisosFormPreyectoAndUsu(proy.id, this.usuario.id).subscribe({
                next: (data) => {
                  console.log("perm", data);
                  this.addInfoProyectoForUpdate(proy.id, data.departamento ? data.departamento.id : "", data.tipoUsu, data.empresa ? data.empresa : "")
                }

              })
            })

          } else {

            if (this.usuario.proyectos.some(a => a.id == this.interfazInicial.proyaux.id)) {

              this.permisoProyecto.getPermisosFormPreyectoAndUsu(this.interfazInicial.proyaux.id, this.usuario.id).subscribe({
                next: (data) => {
                  console.log(data);
                  this.proyectosSeleccionadosList = [this.interfazInicial.proyaux]
                  this.permisosFinales = data.permisos
                  this.form2.get("proyecto")?.setValue(this.interfazInicial.proyaux)

                  this.checkEmpresaList(this.interfazInicial.proyaux, 0)
                  this.form2.get("tipoUsuario")?.setValue(data.tipoUsu)

                  this.addInfoProyectoForUpdate(this.interfazInicial.proyaux.id, data.departamento ? data.departamento.id : "", data.tipoUsu, data.empresa ? data.empresa : "")
                },
                error: (err) => {
                  console.log(err);

                }
              })
            } else {

              this.proyectosSeleccionadosList = [this.interfazInicial.proyaux]
              this.permisosFinales = data.permisos
              this.form2.get("proyecto")?.setValue(this.interfazInicial.proyaux)
              this.checkEmpresaList(this.interfazInicial.proyaux, 0)

              this.form2.get("tipoUsuario")?.setValue(data.tipoUsu)
              this.addInfoProyectoForUpdate(this.interfazInicial.proyaux.id, data.departamento ? data.departamento.id : "", data.tipoUsu, data.empresa ? data.empresa.id : "")


            }

          }
        } else {

          this.addInfoProyecto()
        }

        console.log(this.infoProyecto.value);


      })
      console.log(this.form.value);

    }


    this.permisosService.getAllPermisos().subscribe((data) => {


      this.permisosAux = data.permisos
      this.accionPermisos = data.acciones

      this.permisosAux?.forEach((permiso) => {
        this.accionPermisos?.forEach((accion) => {
          var permisoFinal: any = { permiso, accion }
          this.permisos?.push(new PermisoAccionList(permisoFinal));
        }


        )
      }
      )
    });
    this.proyectoService.getAllProyectos().subscribe(
      {
        next: (data) => {
          this.everyProyect = data;
          this.setListsProyectos();

        }
      }
    )
    this.tipoUsuService.getAllTipos().subscribe({
      next: (data) => {
        console.log(data);

        this.tiposUsuas = data;
      }
    })

    this.usuario = new Usuario(this.tokenStorage.getUser())

  }

  addInfoProyectoForUpdate(idProy: any, idDepartamento: any, cargo: any, empresa: any) {


    console.log(empresa);

    const metadato = this.formBuilder.group({
      proyecto: new FormControl({ value: { id: idProy }, disabled: this.isReading }),
      departamento: new FormControl({ value: idDepartamento ? { id: idDepartamento } : "", disabled: this.isReading }),
      tipoUsuario: new FormControl({ value: cargo, disabled: this.isReading }),
      empresa: new FormControl({ value: empresa || "", disabled: this.isReading }),

    });
    console.log("metadato.value", metadato.value);

    this.infoProyecto.push(metadato);
  }

  addInfoProyecto(): void {
    this.setListsProyectos();
    const metadato = this.formBuilder.group({
      proyecto: [''],
      departamento: [''],
      tipoUsuario: [''],
      empresa: [''],
    });
    this.infoProyecto.push(metadato);
  }

  removeInfoProyecto(index: number): void {
    this.infoProyecto.removeAt(index);
    this.setListsProyectos();
  }


  onSubmit(): any {
    this.isSave = false
    if (!this.isReading) {
      console.log("foprm", this.form.value);

      if (!this.form.valid) {
        this.notificationService.warning('Información', 'Rellene todos los campos por favor.', 5000);
      } else {
        this.loading = true;

        if (this.form.get("telefono")?.value.length < 5) {

          this.form.get("telefono")?.setValue("")
        }
        console.log(this.form.get("telefono")?.value);

        if (this.isUpdate) {
          var proyectos: any = []
          if (this.isAdmin) {

            this.infoProyecto.value.forEach((element: any) => {
              console.log(element.proyecto);
              proyectos.push(element.proyecto)

            });
          } else {
            proyectos = this.form.get("proyectos")?.value
          }


          console.log(this.form.get("infoProyecto")?.value);


          this.usuarioService.updateAdmin(this.usuario.id, this.form.get('nombreUsuario')?.value, this.form.get('email')?.value,
            this.form.get('departamento')?.value, proyectos, this.form.get('cargo')?.value
            , this.form.get('direccion')?.value, this.form.get('codigoPostal')?.value, this.form.get('ciudad')?.value, this.form.get('telefono')?.value, this.form.get("infoProyecto")?.value, this.form.get("pais")?.value, this.form.get("dni")?.value).subscribe({
              next: (data) => {
                if (this.isAdmin) {

                  this.proyectosSeleccionadosList = data.proyectos
                  this.permisosFinales = []
                  this.form2.get("tipoUsuario")?.setValue("")
                } else {
                  this.permisoProyecto.getPermisosFormPreyectoAndUsu(this.interfazInicial.proyaux.id, this.usuario.id).subscribe({
                    next: (data) => {
                      this.proyectosSeleccionadosList = [this.interfazInicial.proyaux]
                      this.permisosFinales = data.permisos
                      this.form2.get("proyecto")?.setValue(this.interfazInicial.proyaux)
                      this.form2.get("tipoUsuario")?.setValue(data.tipoUsu)
                    }
                  })
                }
                this.loading = false;
                this.storrageService.eliminar("userForm")

                this.notificationService.success('ÉXITO', 'Usuario actualizado con éxito.', 5000);
              },
              error: (err) => {
                this.loading = false;
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
                } else {
                  this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
                }
              }
            }

            )
        }
        else {

          var proyectos: any = []

          this.infoProyecto.value.forEach((element: any) => {
            console.log(element.proyecto);
            proyectos.push(element.proyecto)
          });
          this.form.get("proyectos")?.setValue(proyectos)

          if (!this.form.get('proyectos')?.value || this.form.get('proyectos')?.value.length == 0) {
            this.form.get('proyectos')?.setValue([])
          }
          this.loading = true;
          this.usuarioService.register(this.form.get('nombreUsuario')?.value, this.form.get('email')?.value, this.form.get('password')?.value,
            this.form.get('departamento')?.value, this.form.get('proyectos')?.value, this.form.get('cargo')?.value
            , this.form.get('direccion')?.value, this.form.get('codigoPostal')?.value, this.form.get('ciudad')?.value, this.form.get('telefono')?.value, this.form.get("infoProyecto")?.value, this.form.get("pais")?.value, this.form.get("dni")?.value).subscribe({

              next: (data) => {
                this.loading = false;
                this.router.navigateByUrl("/admin-contacine/editar-usuario/" + data.user.id)
                this.storrageService.eliminar("userForm")

                this.notificationService.success('ÉXITO', 'Usuario creado con éxito.', 5000);

              },
              error: (err) => {
                this.loading = false;
                if (err.error && Object.keys(err.error)[0]) {


                  this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
                } else {
                  this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
                }
              }
            }

            )
        }


      }
    } else {
      this.router.navigateByUrl("/admin-contacine/mantenimiento/usuarios")
    }
  }
  guardarPemisos() {

    this.form2.get("permisos")?.setValue(this.permisosFinales)
    this.form2.get("usuario")?.setValue(this.usuario)
    if (this.form2.valid) {
      this.loading = true;
      console.log(this.form2.get("usuario")?.value.id);

      this.permisoProyecto.savePermisosProyecto({ id: this.form2.get("usuario")?.value.id }, { id: this.form2.get("proyecto")?.value.id },
        this.form2.get("tipoUsuario")?.value, this.form2.get("permisos")?.value).subscribe({
          next: (data) => {
            this.loading = false;
            this.notificationService.success('ÉXITO', 'Los permisos se actualizarón correctamente.', 5000);

          },
          error: (data) => {
            this.loading = false;
            this.notificationService.warning('ERROR', 'No se pudo actualizar los permisos.', 5000);

          }
        })
    } else {
      this.notificationService.warning('ALERTA', 'Proyecto o tipo de usuario no seleccionados.', 5000);
    }

  }
  getPermiso(completed: boolean, accion: any, permiso: any) {

    if (completed == true) {
      this.permisos?.forEach((data) => {
        if (data.permiso.padre && data.permiso.id == permiso.id && data.accion.id == accion.id) {
          var permisoFinal: any = { permiso, accion }
          this.permisosFinales?.push(new PermisoAccionList(permisoFinal));
          permiso.listadoHijos.forEach((element: Permiso) => {
            permisoFinal = { permiso: element, accion }
            if (!this.permisosFinales.some(e =>
              e.permiso.id === element.id && e.accion.id === accion.id

            )) {
              this.permisosFinales?.push(new PermisoAccionList(permisoFinal));
            }

          });




        } else if (data.permiso.id == permiso.id && data.accion.id == accion.id) {
          var permisoFinal: any = { permiso, accion }
          this.permisosFinales?.push(new PermisoAccionList(permisoFinal));

        }
      })
    } else {
      var aux;


      if (permiso.padre) {

        permiso.listadoHijos.forEach((data: Permiso) => {
          aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === data.id && x.accion.id === accion.id)
          if (this.permisosFinales.some(e =>
            e.permiso.id === data.id && e.accion.id === accion.id

          )) {
            this.permisosFinales?.splice(aux, 1)

          }
        });
        aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === permiso.id && x.accion.id === accion.id);
        this.permisosFinales?.splice(aux, 1);
      } else {
        this.permisosFinales.forEach((aux1) => {
          if (aux1.permiso.padre && aux1.accion.id == accion.id) {
            aux1.permiso.listadoHijos.forEach((hijo: any) => {
              if (hijo.id == permiso.id) {
                aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === aux1.permiso.id && x.accion.id === accion.id);
                this.permisosFinales?.splice(aux, 1);
              }
            })
          }
        })
        aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === permiso.id && x.accion.id === accion.id);
        this.permisosFinales?.splice(aux, 1);
      }
    }



  }

  getAllPermiso(eliminar: boolean, permiso: any) {
    var permisoFinal: any
    if (!eliminar) {
      if (permiso.padre && permiso.listadoHijos.length > 0) {

        this.accionPermisos?.forEach((data) => {

          permisoFinal = { permiso, accion: data }
          if (!this.permisosFinales.some(e =>
            e.permiso.id == permisoFinal.permiso.id && e.accion.id == permisoFinal.accion.id
          )) {
            this.permisosFinales?.push(new PermisoAccionList(permisoFinal));
          }
          permiso.listadoHijos.forEach((element: Permiso) => {
            permisoFinal = { permiso: element, accion: data }
            if (!this.permisosFinales.some(e =>
              e.permiso.id == permisoFinal.permiso.id && e.accion.id == permisoFinal.accion.id

            )) {
              this.permisosFinales?.push(new PermisoAccionList(permisoFinal));
            }

          });
        })

      } else if (permiso.id == permiso.id) {
        this.accionPermisos?.forEach((data) => {
          permisoFinal = { permiso, accion: data }
          if (!this.permisosFinales.some(e => e.permiso.id == permisoFinal.permiso.id && e.accion.id == permisoFinal.accion.id)) {
            this.permisosFinales?.push(new PermisoAccionList(permisoFinal));
          }

        })


      }

    } else {
      var aux;

      if (permiso.padre && permiso.listadoHijos.length > 0) {
        this.accionPermisos?.forEach((accion) => {
          console.log(permiso.listadoHijos);

          permiso.listadoHijos.forEach((data: Permiso) => {
            console.log(data);

            aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === data.id && x.accion.id === accion.id)
            if (this.permisosFinales.some(e =>
              e.permiso.id === data.id && e.accion.id === accion.id

            )) {
              this.permisosFinales?.splice(aux, 1)
            }
          });

          aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === permiso.id && x.accion.id === accion.id);
          if (aux >= 0) {

            this.permisosFinales?.splice(aux, 1);
          }

        })
      } else {

        this.accionPermisos?.forEach((accion) => {
          this.permisosFinales.forEach((aux1) => {
            console.log(permiso.id);
            console.log(aux1.permiso.id);

            if (permiso.id == aux1.permiso.id && aux1.accion.id == accion.id) {
              aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === aux1.permiso.id && x.accion.id === accion.id);
              this.permisosFinales?.splice(aux, 1);
            }
          })
          /* aux = this.permisosFinales.findIndex((x: any) => x.permiso.id === permiso.id && x.accion.id === accion.id);
          this.permisosFinales?.splice(aux, 1);  */
        })
      }
    }

  }

  mostrarModal(mostrar: string) {

    this.mostrarPopup = mostrar;
  }
  evaluarCheckbox(columna: any, event: any) {


    if (event) {
      this.proyectosSeleccionadosList.push(columna)


    } else {
      for (let index = 0; index < this.proyectosSeleccionadosList.length; index++) {
        if (this.proyectosSeleccionadosList[index] == columna) {
          this.proyectosSeleccionadosList.splice(index, 1);
        }
      }

    }


  }
  searchPermisos(event: any, proyecto: Proyecto) {


    if (event.isUserInput) {
      this.permisosFinales = []
      this.form2.get("tipoUsuario")?.setValue(null)
      this.permisoProyecto.getPermisosFormPreyectoAndUsu(proyecto.id, this.usuario.id).subscribe({
        next: (data) => {

          if (data.permisos) {

            this.permisosFinales = data.permisos
            console.log(this.permisosFinales);
            this.form2.get("tipoUsuario")?.setValue(data.tipoUsu)

          }

        },
        error: (data) => {

        }
      })
    }

  }
  checkPermiso(accion: any, permiso: any): BooleanInput {
    var aux = false
    if (accion != "todo") {
      this.permisosFinales?.forEach((data) => {

        if (data.permiso.id == permiso.id && data.accion.id == accion.id) {
          aux = true
        }
      })
    }

    return aux
  }

  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id || obj1 === obj2.nombre || obj1.nombre === obj2
  }
  primLetMay(text: String): String {
    text = text.toLowerCase()
    text = text.replaceAll("_", " ")

    return text.charAt(0).toUpperCase() + text.slice(1)
  }


  isSamePasword(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      if (this.form) {
        console.log(this.form.get('password')?.value != this.form.get('reppassword')?.value);

        const forbidden = (this.form.get('password')?.value != this.form.get('reppassword')?.value)
        return forbidden ? { forbiddenName: { value: control.value } } : null;

      }
      return null;
    };
  }
  getPermisosTipo(event: any, tipo: any) {
    if (event.isUserInput) {
      this.permisosFinales = []
      this.permisosFinales = [...tipo.permisosTipoUsuarios]
    }
  }
  existUser: any
  modalExistUser = false
  checkEmailExists() {

    if (this.form.get("email")?.value && this.form.get("email")?.valid && !this.isAdmin && !this.isUpdate && !this.isReading) {

      this.usuarioService.findByEmail(this.form.get("email")?.value).subscribe({
        next: (data) => {
          console.log("DATA: ", data);
          this.existUser = data
          this.modalExistUser = true
        },
        error: (error) => {
          if (error.error && Object.keys(error.error)[0]) {
            if (error.error[Object.keys(error.error)[0]] != "Usuario no encontrado") {
              this.notificationService.warning("ERROR", error.error[Object.keys(error.error)[0]], 5000);
            }
          } else {
            this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
          }
          console.log(error);

        }
      })
    }
  }
  asociarProyExit() {
    this.usuarioService.asociarProyUsuExit(this.existUser.id, this.interfazInicial.proyaux.id).subscribe({
      next: (data: any) => {

        this.loading = false;
        this.modalExistUser = false
        this.router.navigateByUrl("/admin-contacine/editar-usuario/" + data.id)
        this.notificationService.success('ÉXITO', 'Usuario asociado con éxito.', 5000);
      },
      error: (err) => {
        this.modalExistUser = false

        if (err.error[Object.keys(err.error)[0]] != "Usuario no encontrado") {
          this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
        } else {
          this.notificationService.error('ERROR', 'Usuario no asociado.', 5000);
        }

      }
    }

    )
  }

  volver() {
    this.router.navigate(['admin-contacine/mantenimiento/usuarios'])
  }

  onFormChange() {

    if (!this.isUpdate && !this.isReading) {
      this.storrageService.guardar('userForm', JSON.stringify(this.form.value));
    }
  }
  limparFormulario() {
    this.isClearForm = false
    this.storrageService.eliminar('userForm');
    this.form.reset()
  }

  empresasFiltradas: any[][] = [];

  checkEmpresaList(proy: any, idice: number) {
    this.empresasFiltradas[idice] = [];
    console.log(proy);
    if (proy && proy.id) {


      const filter = {
        filters: [{
          field_type: "STRING",
          key: "proyectos.id",
          operator: "CONTAINS_ID",
          value: proy.id
        }],
      };

      this.productoraService.filter(filter).subscribe({
        next: (data) => {
          this.empresasFiltradas[idice] = data.content;

        },
        error: (err) => {
          console.log(err);

        }
      })
      if (this.isAdmin) {
        this.setListsProyectos(proy, idice);
      }
    }
  }

  setListsProyectos(proy?: any, index?: any) {
    let infoProyectoToFilter: any[] = this.infoProyecto.value;
    if (proy && proy.id) {
      infoProyectoToFilter[index].proyecto = { id: proy.id };
    }
    this.proyectosList = [];
    let filteredProyect: any[] = [];
    infoProyectoToFilter.forEach(element => {
      filteredProyect = this.everyProyect.filter((option) =>
        !infoProyectoToFilter.some(el => el.proyecto.id === option.id && option.id !== element.proyecto.id))
      this.proyectosList.push(filteredProyect);
    });
    filteredProyect = this.everyProyect.filter((option) =>
      !infoProyectoToFilter.some(el => el.proyecto.id === option.id))
    this.proyectosList.push(filteredProyect);
  }
}

