import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FilterService } from 'src/app/services/filter.service';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ControlCostesService } from 'src/app/services/control-costes.service';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { JustificacionExport } from 'src/app/models/gasto';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listado-justificaciones',
  templateUrl: './listado-justificaciones.component.html',
  styleUrl: './listado-justificaciones.component.scss',
})
export class ListadoJustificacionesComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('inputFile')
  input!: ElementRef;
  isForImport = false;
  canvas: any;
  loading: boolean = false;
  dataExport: any;
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  filters: any[] = [];
  pageSize: any = 99999;
  page: any = 0;
  isForCoste = false;
  totalElements = 0;
  nombrePagina = 'Justificaciones';
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  nombreBoton = 'Pasar a Coste';
  rutaNuevo = '';
  exportar = 'true';
  idEliminar: any = 0;
  displayedColumns = [
    'Select',
    'Id',
    'Nº',
    'Fecha Factura',
    'Factura',
    'nif',
    'codigoPostal',
    'Proveedor',
    'codigo',
    'Concepto',
    'Descripcion',
    /*'tieneCoste',*/
    'Base',
    'Iva',
    'Irpf',
    'Total',
    'Departamento',
    'Nombre',
    /*'Creado',*/
    /*'Modificado',*/
    /*'Fecha modificación',*/
    'Período de Impuesto',
    'Forma de Pago',
    /*'Acciones',*/
  ];
  evento: any = '';
  codigo: any;
  codigoHijo: any;
  map: { [id: number]: boolean; } = {};
  justificacionesSeleccionadas = 0;


  dataSource = new MatTableDataSource();
  displayedColumnscompleta: string[] = [];
  listadoJustifica: any;
  dialogVisible: boolean = false;
  dialogListVisible: boolean = false;
  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('codigo')) {
      console.log('dentro');

      this.codigo = this.route.snapshot.queryParamMap.get('codigo');
      console.log('codigo', this.codigo);
      if (this.route.snapshot.queryParamMap.get('codigoHijo')) {
        console.log('dentro');

        this.codigoHijo = this.route.snapshot.queryParamMap.get('codigoHijo');
        console.log('codigo', this.codigoHijo);
      }
      this.initFilters();
    }
    this.getJustificaciones();
  }

  constructor(
    private registroGastosService: RegistroGastosService,
    public tableService: TableService,
    private filterService: FilterService,
    public interfazInicial: InterfazInicialComponent,
    private controlCostesService: ControlCostesService,
    private notificationService: NotificationService,
    private gastoService: RegistroGastosService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  initFilters() {
    if (this.codigo) {
      this.filter.filters.push({
        field_type: 'STRING',
        key: 'conceptos.codigo.cuenta',
        operator: 'LIKE',
        value: this.codigo + '',
      });
      if (this.codigoHijo) {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'conceptos.codigo.codigoHijo',
          operator: 'LIKE',
          value: this.codigoHijo + '',
        });
      } else {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'conceptos.codigo.codigoHijo',
          operator: 'IS_NULL',
        });
      }
    }
  }

  getJustificaciones() {
    this.filter.filters.forEach((element: any) => {
      if (element.key == 'num_pc' && element.operator == 'IS_NOT_NULL') {
        this.filter.filters.shift();
      }
      if (element.key == 'proyecto.id') {
        this.filter.filters.shift();
      }
    });
    this.filter.filters.unshift({
      field_type: 'STRING',
      key: 'num_pc',
      operator: 'IS_NOT_NULL',
      value: '',
    });

    this.filter.filters.unshift({
      field_type: 'STRING',
      key: 'proyecto.id',
      operator: 'LIKE',
      value: this.interfazInicial.proyaux.id,
    });


    this.registroGastosService
      .getRegistros(this.filter)

      .subscribe({
        next: (data: any) => {
          this.dataExport = [];
          this.listadoJustifica = data.soruce;
          this.dataSource = data.pageList;
          this.totalElements = data.source.length;
          data.source.forEach((element: any) => {
            this.dataExport.push(new JustificacionExport(element));
            if (!element.tieneCoste) {
              this.map[element.id] = false;
            }
          });
          if (this.ordenarCampos['id'] == undefined) {
            this.paginator._intl = this.interfazInicial.paginator;
            let nombre: string = '';
            if (data['source'].length != 0) {
              var mapa = Object.entries(data['source'][0]);
              mapa.forEach((element) => {
                nombre = element[0];
                this.ordenarCampos[nombre] = false;
              });
            }
          }

          this.displayedColumnscompleta = [];
          if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
            var a: any = sessionStorage.getItem(this.nombrePagina)
            this.mostrarCampos = JSON.parse(a);
            this.displayedColumns.forEach((element) => {
              this.displayedColumnscompleta.push(element);
            });
          } else {

            this.displayedColumns.forEach((element) => {
              this.mostrarCampos[element] = true;
              this.displayedColumnscompleta.push(element);
            });
          }
          this.totalElements = data.source.length;
          this.dataSource.paginator = this.paginator;
        },
        error: (error) => { },
      });
  }

  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.filter = {
        filters: [],
        page: this.page,
        size: this.pageSize,
        sorts: this.sorts,
      };
      this.getJustificaciones();
    } else {
      let operator = 'ALL_FIELDS_CLASS';

      if (this.evento != '') {
        this.filter.filters = [
          {
            operator: operator,
            value: this.evento,
            values: ['PeriodoIva.nombre'],
          },
        ];
        this.getJustificaciones();
      } else {
        this.filter.filters = [];
        this.getJustificaciones();
      }
    }
  }
  ordenar(property: string, sorts: any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (property.includes(clave)) {
        this.ordenarCampos[clave] = !this.ordenarCampos[clave];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.filter.sorts = [{ key: property, direction: orden }];

    this.getJustificaciones();
  }

  calcTextWidth(text: any, font: any) {
    let canvas =
      this.canvas || (this.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 10;
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    this.filter.filters = this.filterService.doFilter(
      event,
      type,
      operator,
      field_type,
      this.filter.filters,
      null
    );

    this.getJustificaciones();
  }

  nextPage(event: PageEvent) {
    this.filter.page = event.pageIndex + '';
    this.filter.pageSize = event.pageSize + '';
    this.getJustificaciones();
  }

  pasarCoste() {
    this.loading = true;
    this.controlCostesService.pasarJustificaciones(this.interfazInicial.proyaux.id).subscribe({
      next: (data: any) => {
        console.log(data);

        this.notificationService.success('ÉXITO', 'Registro pasado a coste correctamente.', 5000);
        this.getJustificaciones()
        this.isForCoste = false;
        this.loading = false;
      },
      error: (error) => {
        console.log(error);

        this.notificationService.error('ERROR', error.error ? error.error.message ?? 'Ha ocurrido un error' : 'Ha ocurrido un error', 5000);
        this.isForCoste = false;
        this.loading = false;
      },
    });
  }

  borrarElemento(id: any) {
    this.registroGastosService.deleteGasto(id).subscribe({
      next: (data) => {
        this.getJustificaciones();
        this.notificationService.success(
          'ÉXITO',
          'Gasto borrado correctamente.',
          5000
        );
      },
      error: (error) => { },
    });
    this.dialogVisible = false;
  }
  /**
   * Obtiene el documento y realiza la importación de las justificacines
   *
   * @param event
   */
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.loading = true;
      const file = event.target.files[0];
      this.gastoService
        .importJustificaciones(
          file,
          this.interfazInicial.currentUser.id,
          this.interfazInicial.proyaux.id,
          this.interfazInicial.depatamentoUsu.id
        )
        .subscribe({
          next: (data: any) => {
            //falta actualizar la lista al crear las justificaciones
            this.loading = false;
            this.notificationService.success(
              'HECHO',
              'Justificaciones importadas con éxito.',
              5000
            );
            this.input.nativeElement.value = '';
            this.isForImport = false;
            this.getJustificaciones();
          },
          error: (err) => {
            this.notificationService.error("ERROR", err.error.message, 5000)
            console.log(err);
          },
        });
    }
  }

  /**
   * Descarga la plantilla Excel para poder importar las justificaciones
   */
  downloadTemplate() {
    const fileName = 'Plantilla_Justificaciones.xlsx';
    const filePath = './assets/templates/Plantilla_Justificaciones.xlsx';

    this.http.get(filePath, { responseType: 'blob' }).subscribe((blob) => {
      saveAs(blob, fileName);
    });
  }
  notifExport() {
    this.notificationService.warning(
      'ALERTA',
      'No tienes permisos para exportar.',
      5000
    );
  }

  changeCheckedStatus(event: any, changeAll: boolean, id?: number) {
    if (changeAll) {
      for (let i in this.map) {
        this.map[i] = event.checked;
      }
    }
    else {
      if (id != undefined) this.map[id] = event.checked;
    }

    const values = Object.values(this.map);
    this.justificacionesSeleccionadas = values.filter(value => value === true).length;
  }

  borrarElementos() {
    let listId: any[] = [];
    for (let i in this.map) {
      if (this.map[i]) {
        listId.push(Number(i));
      }
    }
    console.log(listId)
    this.registroGastosService.deleteListGastos(listId).subscribe({
      next: (data) => {
        this.getJustificaciones();
        this.notificationService.success(
          'ÉXITO',
          'Justicicaciones borradas correctamente.',
          5000
        );
      },
      error: (error) => { },
    });
    this.dialogListVisible = false;
    this.map = {};
  }

  getConcepto(element: any): string {
    var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }

}
