import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { Departamento, Usuario } from 'src/app/models/usuario';
import { DepartamentoService } from 'src/app/services/departamento.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { TipoUsuarioService } from 'src/app/services/usuario/tipo-usuario.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { environment } from 'src/environments/environment';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { PermisosService } from 'src/app/services/permisos.service';

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.scss']
})
export class MiPerfilComponent {

  showPass: boolean = false
  showRepPass: boolean = false


  constructor(
    private notificationService: NotificationService,
    private tokenStorage: TokenStorageService,
    private departamentoService: DepartamentoService,
    private usuarioService: UsuarioService,
    private interfazInicial: InterfazInicialComponent,
    private permisosService: PermisosProyectoService
  ) { }


  form: FormGroup = new FormGroup({
    email: new FormControl({ value: "", disabled: true }),
    nombreUsuario: new FormControl({ value: "", disabled: true }),
    empresa: new FormControl({ value: "", disabled: true }),
    twoFactor: new FormControl("false", Validators.required),
    password: new FormControl(""),
    reppassword: new FormControl(""),
    departamento: new FormControl({ value: "", disabled: true }),
    proyectos: new FormControl({ value: "", disabled: true }),
    cargo: new FormControl({ value: "", disabled: true }),
    direccion: new FormControl(""),
//  codigoPostal: new FormControl("", [Validators.required, Validators.pattern('^[0-9]{5}$')]),
    ciudad: new FormControl(""),
    telefono: new FormControl("",  [Validators.pattern(/^\+?[0-9]{1,3}[\d\-()\s]+$/)]),
  })
  proyectosList: any[] = []
  usuario: Usuario = new Usuario()
  departamentos: Departamento[] = []
  isPassChan = false
  isTwoFaActivo: boolean = false
  isChangePhoto: boolean = false
  img = window.sessionStorage.getItem(environment.IMG_PERFIL)

  ngOnInit(): void {
    this.departamentoService.getAllDepartamentos().subscribe({
      next: (data) => {
        this.departamentos = data
      },

    })
    this.permisosService.getPermisosFormPreyectoAndUsu(this.interfazInicial.proyaux.id, this.tokenStorage.getUser().id).subscribe({
      next: (data) => {
        console.log(data);
        this.form.get('cargo')?.setValue(data.cargo)
        this.form.get('departamento')?.setValue(data.departamento)
      }

    })

    this.usuarioService.findById(this.tokenStorage.getUser().id).subscribe((data) => {
      this.usuario = new Usuario(data)
      console.log("usuario", this.usuario);

      this.form.get('email')?.setValue(this.usuario.email)
      this.form.get('nombreUsuario')?.setValue(this.usuario.nombreUsuario)
      this.form.get('empresa')?.setValue(this.usuario.empresa)
      this.form.get('twoFactor')?.setValue(this.usuario.twoFactorEnabled)
      this.form.get('proyectos')?.setValue(this.usuario.proyectos)
      this.form.get('direccion')?.setValue(this.usuario.direccion)
      this.form.get('codigoPostal')?.setValue(this.usuario.codigoPostal)
      this.form.get('ciudad')?.setValue(this.usuario.ciudad)
      this.form.get('telefono')?.setValue(this.usuario.telefono)
      this.isTwoFaActivo = this.usuario.twoFactorEnabled
      if (this.usuario.proyectos) this.proyectosList = this.usuario.proyectos

    })

  }
  compararObjetos(obj1: any, obj2: any): boolean {
    return obj1 === obj2.id || obj1 === obj2 || obj1.id === obj2.id
  }
  getFirstLetter(nombre: string) {
    if (nombre) {
      return nombre.charAt(0).toUpperCase();
    }
    return
  }
  actuUsu() {
    console.log(this.form

    );
    
    if (this.form.valid) {
      if (this.form.get('password')?.value && this.isPassChan) {
        if (this.form.get('password')?.value.length < 6) {
          this.notificationService.warning('ALERTA', 'La contraseña no es superior a 6 caracteres.', 5000);

        }

        else if (this.form.get('password')?.value != this.form.get('reppassword')?.value) {
          this.notificationService.warning('ALERTA', 'Las contraseñas no coinciden.', 5000);

        } else {
          this.usuarioService.update(this.usuario.id, this.form.get('direccion')?.value, this.form.get('codigoPostal')?.value,
            this.form.get('ciudad')?.value, this.form.get('telefono')?.value, this.form.get('password')?.value).subscribe({
              next: (data) => {
                if (data) {
                  this.notificationService.success('ÉXITO', 'Usuario actualizado con éxito.', 5000);

                }
              },
              error: (err) => {
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
                } else {
                  this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
                }
              }
            })
        }

      } else {
        this.usuarioService.update(this.usuario.id, this.form.get('direccion')?.value, this.form.get('codigoPostal')?.value,
          this.form.get('ciudad')?.value, this.form.get('telefono')?.value, undefined).subscribe({
            next: (data) => {
              if (data) {
                this.notificationService.success('ÉXITO', 'Usuario actualizado con éxito.', 5000);

              }
            },
            error: (err) => {
              if (err.error && Object.keys(err.error)[0]) {
                this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
              } else {
                this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
              }
            }
          })
      }

    }

  }
  updateTwoFa() {
    this.usuarioService.updateTwofa(this.usuario.id, !this.isTwoFaActivo).subscribe({
      next: (data) => {
        this.isTwoFaActivo = !this.isTwoFaActivo

        if (data) {
          this.notificationService.success('ÉXITO', 'Usuario actualizado con éxito.', 5000);

        }
      },
      error: (err) => {
        if (err.error && Object.keys(err.error)[0]) {
          this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
        } else {
          this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
        }
      }
    })
  }

  onFileChange(event: any) {
    if (event) {
      if (
        event.target?.files[0]?.type.split("/")[0] == "image"
      ) {
        const file = event.target.files[0];
        const bits = file.size * 8;
        const bytes = bits / 8;
        const kb = bytes / 1000;
        const mb = kb / 1000;;
        if(mb < 20){
          this.convertFile(file).subscribe((base64) => {
            let document = {
              nombre: event.target.files[0].name,
              mime: event.target.files[0].type,
              base64: base64,
            };
            this.isChangePhoto = false
            this.usuarioService.updateImgPerfil(this.usuario.id, document.base64, document.mime, false).subscribe({
              next: (data) => {
                console.log(data);
  
                if (data) {
                  window.sessionStorage.setItem(environment.IMG_PERFIL, document.base64);
                  this.tokenStorage.setUser(data);
                  window.location.reload()
                  this.notificationService.success('ÉXITO', 'Imagen actualizada con éxito.', 5000);
  
  
                }
              },
              error: (err) => {
                if (err.error && Object.keys(err.error)[0]) {
                  this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
                } else {
                  this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
                }
              }
            })
          });
        }
        else{
          this.notificationService.warning(
            'Información',
            'Solo se admite documnetos con tamaño menor a 20 MB.',
            5000
          );
        }
      } else {
        this.notificationService.warning(
          "Informacion",
          "Solo se admiten archivos de imagen.",
          5000
        );
      }
    }

  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => {
      if (event && event.target && event.target.result) {
        result.next(btoa(event.target.result.toString()));
      }

    }
    return result;
  }
  deleteFoto() {
    this.isChangePhoto = false

    if (!this.usuario.imgPerfil) {
      this.notificationService.info("ALERTA", "Este usuario no tiene foto de perfil.", 5000)
    } else {
      this.usuarioService.updateImgPerfil(this.usuario.id, "", "", true).subscribe({
        next: (data) => {
          console.log(data);

          if (data) {
            window.sessionStorage.setItem(environment.IMG_PERFIL, "");
            this.tokenStorage.setUser(data);
            window.location.reload()
            this.notificationService.success('ÉXITO', 'Imagen borrada con éxito.', 5000);

          }
        },
        error: (err) => {
          if (err.error && Object.keys(err.error)[0]) {
            this.notificationService.warning("ERROR", err.error[Object.keys(err.error)[0]], 5000);
          } else {
            this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
          }
        }
      })
    }
  }
}
