<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [isViewPage]="isReading"></app-header-listados>
<div>
    <div class="topInfo" [ngClass]="{'topInfo2': this.isFormProyExpanded != 'in'}">
        <label class="titulosPaginas">{{isUpdate?'':isReading?'':'NUEVO'}} PROYECTO</label>

        <div class="text-buttons" [ngClass]="{'text-buttons2': this.isFormProyExpanded != 'in'}">
            <p (click)="this.isFormProyExpanded = this.isFormProyExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer" [ngClass]="{'close':this.isFormProyExpanded === 'in'}">Mostrar Datos
                Proyecto
            </p>
            <p (click)="this.isFormProyExpanded = this.isFormProyExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer">/</p>
            <p (click)="this.isFormProyExpanded = this.isFormProyExpanded === 'out' ? 'in' : 'out'"
                class="text-button pointer" [ngClass]="{'close':this.isFormProyExpanded === 'out'}">Ocultar</p>
        </div>
    </div>
    <div class="form-surrouded" [@slideInOut]="isFormProyExpanded"
        [ngClass]="{'form-surrouded2': this.isFormProyExpanded != 'in'}">
        <form class="rowForm" [formGroup]="form" (change)="onFormChange()">



            <div class="col-12 formMaterial">
                <label class="col-2 formLabel">Título<span class="red">*</span></label>
                <mat-form-field appearance="outline" class="col-10 formField" [ngClass]="isReading?'desactivado':''">
                    <input matInput formControlName="titulo" placeholder="Título...">
                    <mat-error>Título requerido</mat-error>
                </mat-form-field>
            </div>

            <div class="col-12 d-flex rowForm ">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Fecha Inicio Rodaje</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput [matDatepicker]="picker" [max]="form.get('fechaFinRodaje')?.value"
                            formControlName="fechaInicioRodaje" (click)="picker.open()"
                            placeholder="Fecha Inicio Rodaje..." (dateChange)="checkSemP();checFechIn()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"
                            disabled="{{isReading}}"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="false"></mat-datepicker>
                        <mat-error>Fecha Inicio requerida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Fecha Fin Rodaje</label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput [matDatepicker]="picker2" [min]="form.get('fechaInicioRodaje')?.value"
                            formControlName="fechaFinRodaje" (click)="picker2.open()" placeholder="Fecha Fin Rodaje..."
                            (dateChange)="checkSemP();checFechFin()">
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"
                            disabled="{{isReading}}"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 disabled="false"
                            [startAt]="form.get('fechaInicioRodaje')?.value"></mat-datepicker>
                        <mat-error>Fecha Fin requerida</mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class=" col-12 d-flex rowForm">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Fecha Inicio</label>
                    <mat-form-field appearance="outline" class="col-8 formField auto padingCol8"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput [matDatepicker]="picker3" formControlName="fechaInicio" (click)="picker3.open()"
                            placeholder="Fecha Inicio...">
                        <mat-datepicker-toggle matIconSuffix [for]="picker3"
                            disabled="{{true}}"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                        <mat-error>Fecha requerida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Fecha Fin</label>
                    <mat-form-field appearance="outline" class="col-8 formField auto"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput [matDatepicker]="picker4" formControlName="fechaFin" (click)="picker4.open()"
                            placeholder="Fecha Fin...">
                        <mat-datepicker-toggle matIconSuffix [for]="picker4"
                            disabled="{{true}}"></mat-datepicker-toggle>
                        <mat-datepicker #picker4 disabled="false"></mat-datepicker>
                        <mat-error>Fecha requerida</mat-error>
                    </mat-form-field>
                </div>

            </div>








            <div class="col-12 d-flex">


                <div class="col-4 formMaterial">
                    <label class="col-6 formLabel">Semanas P</label>
                    <mat-form-field appearance="outline" class="col-6 formField padingCol6"
                        [ngClass]="isReading?'desactivado':''">
                        <input type="text" mask="separator.2" thousandSeparator="."
                            (keyup)="changeDec($event,form.get('semanasPreproducción'));" decimalMarker="," matInput
                            formControlName="semanasPreproducción" (input)="checFechIn()" placeholder="Semanas P...">
                        <mat-error
                            *ngIf="this.form.controls['semanasPreproducción'].errors?.['required']!= undefined">Semanas
                            Preproducción requeridas</mat-error>
                        <mat-error
                            *ngIf="this.form.controls['semanasPreproducción'].errors?.['pattern']!= undefined">Introduzca
                            menos de 2 decimales
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4 formMaterial">
                    <label class="col-6 formLabel">Semanas R</label>
                    <mat-form-field appearance="outline" class="col-6 formField auto"
                        [ngClass]="isReading?'desactivado':''">
                        <input type="text" mask="separator.2" thousandSeparator="." decimalMarker="," matInput
                            formControlName="semanasRodaje" appTwoDecimals placeholder="Semanas R...">
                        <mat-error>Semanas Rodaje requeridas</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4 formMaterial">
                    <label class="col-6 formLabel">Semanas C</label>
                    <mat-form-field appearance="outline" class="col-6 formField" [ngClass]="isReading?'desactivado':''">
                        <input type="text" mask="separator.2" thousandSeparator="."
                            (keyup)="changeDec($event,form.get('semanasCierre'))" decimalMarker="," matInput
                            formControlName="semanasCierre" (input)="checFechFin()" placeholder="Semanas C...">
                        <mat-error *ngIf="this.form.controls['semanasCierre'].errors?.['required']!= undefined">Semanas
                            Cierre requeridas</mat-error>
                        <mat-error
                            *ngIf="this.form.controls['semanasCierre'].errors?.['pattern']!= undefined">Introduzca menos
                            de 2 decimales
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="col-12 d-flex">
                <div class="col-4 formMaterial">
                    <label class="col-6 formLabel">IVA</label>
                    <mat-form-field appearance="outline" class="col-6 formField padingCol6"
                        [ngClass]="isReading?'desactivado':''">
                        <mat-select formControlName="hasIva" placeholder="IVA...">
                            <mat-option value="true">SI</mat-option>
                            <mat-option value="false">NO</mat-option>
                        </mat-select>
                        <mat-error>IVA requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-4 formMaterial">
                    <label class="col-6 formLabel">IGIC</label>
                    <mat-form-field appearance="outline" class="col-6 formField" [ngClass]="isReading?'desactivado':''">
                        <mat-select formControlName="hasIgic" placeholder="IGIC...">
                            <mat-option value="true">SI</mat-option>
                            <mat-option value="false">NO</mat-option>
                        </mat-select>
                        <mat-error>IGC requerido</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-4 formMaterial">
                    <label class="col-6 formLabel">Intracomunitario</label>
                    <mat-form-field appearance="outline" class="col-6 formField" [ngClass]="isReading?'desactivado':''">
                        <mat-select formControlName="hasIntracomunitario" placeholder="Intracomunitario...">
                            <mat-option value="true">SI</mat-option>
                            <mat-option value="false">NO</mat-option>
                        </mat-select>
                        <mat-error>Intracomunitario requerido</mat-error>
                    </mat-form-field>
                </div>

            </div>
            <!--   <div class="col-12 formMaterial">
                <label class="col-2 formLabel">Productoras</label>
                <mat-form-field appearance="outline" class="col-10 formField" [ngClass]="isReading?'desactivado':''">
                    <mat-select matInput placeholder="Seleccione productora.." formControlName="productoras" multiple
                        [compareWith]="compararObjetos">
                        <mat-option *ngFor="let productora of productorasList" [value]="{id:productora.id}">
                            <span class="columnas"> {{productora.nombre}}</span></mat-option>
                    </mat-select>
                    <mat-error>Productora requerida</mat-error>
                </mat-form-field>
            </div>  -->



            <!--  <div class="col-12 ">
                <div formArrayName="monedaCambio" class="col-12">
                    <form class="concepto">
                        <div *ngFor="let item of monedaCambio.controls; let i = index" [formGroupName]="i"
                            class="col-12">
                            <div class="col-12 d-flex">

                                <div class="col-6 formMaterial">
                                    <label class="col-4 formLabel">Moneda
                                    </label>
                                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input matInput formControlName="moneda" placeholder="Moneda...">
                                        <mat-error>Moneda requerido</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-1 formMaterial">
                                    <mat-form-field appearance="outline" class="col-10 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <mat-select matInput placeholder="Tipo Moneda..." formControlName="tipoMoneda"
                                            [compareWith]="compararObjetos">
                                            <mat-option *ngFor="let tipoMoneda of tiposMoneda"
                                                [value]="{id: tipoMoneda.id}">
                                                <span class="columnas"> {{tipoMoneda.signo}}</span></mat-option>
                                        </mat-select>
                                        <mat-error>Tipo moneda requerido</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="col-5 formMaterial">
                                    <label class="col-4 formLabel">Cambio</label>
                                    <mat-form-field appearance="outline" class="col-8 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input type="text" matInput mask="separator.2" thousandSeparator="."
                                            (keyup)="changeDec($event,monedaCambio.controls[i].get('cambio'));"
                                            decimalMarker="," formControlName="cambio" placeholder="Cambio...">
                                        <mat-icon *ngIf="!isReading && monedaCambio.length!=1"
                                            class="material-icons pointer eye-b" (click)="removeMoneda(i)">
                                            highlight_off
                                        </mat-icon>
                                        <mat-error>Cambio requerido</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-12 formMaterial">
                                <div class="col-12 cuadroBotones" *ngIf="i == monedaCambio.length-1 && !isReading">
                                    <button type="button" class="buttonSecondary aniadir"
                                        (click)="addMoneda()"><mat-icon class="material-symbols-outlined">
                                            add
                                        </mat-icon> Añadir fila
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div> -->































            <div class="col-12 ">
                <div formArrayName="lugarDecorado" class="col-12">
                    <form class="concepto">
                        <div *ngFor="let item of lugarDecorado.controls; let i = index" [formGroupName]="i"
                            class="col-12">
                            <div class="col-12 d-flex">
                                <div class="col-6 formMaterial">
                                    <label class="col-4 formLabel">Lugar
                                    </label>
                                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input matInput formControlName="lugar" placeholder="Lugar...">
                                        <mat-error>Lugar requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- TODO: ver como desactivar esto -->
                                <div class="col-6 formMaterial">
                                    <label class="col-4 formLabel">Decorado</label>
                                    <mat-form-field appearance="outline" class="col-8 formField"
                                        [ngClass]="isReading?'desactivado':''">
                                        <input matInput formControlName="decorado" placeholder="Decorado...">
                                        <mat-icon *ngIf="!isReading && lugarDecorado.length!=1"
                                            class="material-icons pointer eye-b" (click)="removeLugar(i)">
                                            highlight_off
                                        </mat-icon>
                                        <mat-error>Decorado requerido</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-12 formMaterial">
                                <div class="col-12 cuadroBotones" *ngIf="i == lugarDecorado.length-1 && !isReading">
                                    <button type="button" class="buttonSecondary aniadir" (click)="addLugar()"><mat-icon
                                            class="material-symbols-outlined">
                                            add
                                        </mat-icon> Añadir fila
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

























































            <!-- <div class="col-6" formArrayName="lugares">
                <div class="col-12 formMaterial" *ngFor="let tel of lugares.controls; let i = index">
                    <label class="col-4 formLabel ">Lugares</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                        [ngClass]="isReading?'desactivado':''">
                        <input matInput [formControlName]="i" placeholder="Introduzca el lugar">
                        <mat-icon *ngIf="!isReading && lugares.length != 1" class="material-icons pointer eye-b"
                            (click)="removeLugar(i)">
                            highlight_off
                        </mat-icon>
                        <mat-error>Lugar requerido</mat-error>
                    </mat-form-field>

                    <div class="col-12">
                        <div class="col-12 cuadroBotones" *ngIf="i == lugares.length-1 && !isReading">
                            <button type="button" class="aniadir buttonSecondary" (click)="addLugar()"><mat-icon
                                    class="material-symbols-outlined">
                                    add
                                </mat-icon>Añadir
                                fila</button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-6" formArrayName="decorados">
                <div class="col-12 formMaterial" *ngFor="let tel of decorados.controls; let i = index">
                    <label class="col-4 formLabel ">Decorados</label>
                    <mat-form-field appearance="outline" class="col-8 formField" [ngClass]="isReading?'desactivado':''">
                        <input matInput [formControlName]="i" placeholder="Introduzca el decorado">
                        <mat-icon *ngIf="!isReading && decorados.length != 1" class="material-icons pointer eye-b"
                            (click)="removeDecorado(i)">
                            highlight_off
                        </mat-icon>
                        <mat-error>Decorado requerido</mat-error>
                    </mat-form-field>

                    <div class="col-12">
                        <div class="col-12 cuadroBotones" *ngIf="i == decorados.length-1 && !isReading">
                            <button type="button" class="aniadir buttonSecondary" (click)="addDecorado()"><mat-icon
                                    class="material-symbols-outlined">
                                    add
                                </mat-icon>Añadir
                                fila</button>
                        </div>

                    </div>
                </div>

            </div> -->


            <h6 class="padded-col cuentas">Cuentas</h6>

            <div class=" col-12 d-flex rowForm">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Vehículos</label>
                    <mat-form-field  appearance="outline" class="col-8 formField" [ngClass]="isReading ||  (proyecto.vehiculos&&proyecto.vehiculos.cuenta) ?'desactivado':''">
                        <input matInput mask="XX.XX.XX" formControlName="vehiculos" placeholder="00.00.00">
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Complementos Material</label>
                    <mat-form-field  appearance="outline" class="col-8 formField" [ngClass]="isReading || (proyecto.complementosMaterial&&proyecto.complementosMaterial.cuenta) ?'desactivado':''">
                        <input matInput mask="XX.XX.XX" formControlName="complementosMaterial" placeholder="00.00.00">
                    </mat-form-field>
                </div>

            </div>

            <div class=" col-12 d-flex rowForm">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Horas Extras</label>
                    <mat-form-field  appearance="outline" class="col-8 formField" [ngClass]="isReading || (proyecto.horasExtras&&proyecto.horasExtras.cuenta) ?'desactivado':''">
                        <input matInput mask="XX.XX.XX" formControlName="horasExtras" placeholder="00.00.00">
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Horas Extras Festivas</label>
                    <mat-form-field  appearance="outline" class="col-8 formField" [ngClass]="isReading || (proyecto.horasExtrasFestivas&&proyecto.horasExtrasFestivas.cuenta) ?'desactivado':''">
                        <input matInput mask="XX.XX.XX" formControlName="horasExtrasFestivas" placeholder="00.00.00">
                    </mat-form-field>
                </div>
            </div>

            <div class=" col-12 d-flex rowForm">
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Dietas Actores</label>
                    <mat-form-field  appearance="outline" class="col-8 formField" [ngClass]="isReading || (proyecto.dietasActores&&proyecto.dietasActores.cuenta) ?'desactivado':''">
                        <input matInput mask="XX.XX.XX" formControlName="dietasActores" placeholder="00.00.00">
                    </mat-form-field>
                </div>
                <div class="col-6 formMaterial">
                    <label class="col-4 formLabel">Dietas Técnicos</label>
                    <mat-form-field  appearance="outline" class="col-8 formField" [ngClass]="isReading|| (proyecto.dietasTecnicos&&proyecto.dietasTecnicos.cuenta) ?'desactivado':''">
                        <input matInput mask="XX.XX.XX" formControlName="dietasTecnicos" placeholder="00.00.00">
                    </mat-form-field>
                </div>
            </div>

            <div class="col-12 cuadroBotones formMaterial">
                <!--     <span *ngIf="!isReading" [ngClass]="(!isReading && !isUpdate)?'':'formMaterial'">
                    <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                    </button>
                </span> -->
                <button *ngIf="isClearForm?limparFormulario():''">
                </button>

                <button *ngIf="isSave?onSubmit():''">

                </button>
            </div>
        </form>
    </div>







</div>


<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>







    <!--   <div class="col-6 formMaterial">
                        <label class="col-12 formLabel">%IVA</label>
                        <mat-form-field appearance="outline" class="col-12 formField"
                            [ngClass]="isReading?'desactivado':''">
                            <input type="number" formControlName="porcentajeIva" matInput placeholder="0">
                            <mat-error *ngIf="this.form.controls['porcentajeIva'].errors?.['pattern']!= undefined">El
                                IVA
                                tiene que estar entre 0 y 100</mat-error>
                            <mat-error *ngIf="this.form.controls['porcentajeIva'].errors?.['required']!= undefined">IVA
                                requerido</mat-error>
                        </mat-form-field>
                    </div> -->