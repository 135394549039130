import { Proyecto } from "./proyecto"

export class Cuenta {
    id?: number
    cuenta!: String
    concepto!: String
    capitulo?: Capitulo
    subcapitulo?: Subcapitulo
    proyecto?: any[]

    public constructor(data?: any, init?: Partial<Cuenta>) {
        if (data) {
            console.log(data);
            
            this.id = data.id;
            //   this.fechaAlta = data.fechaAlta
            this.capitulo = data.capitulo;
            this.subcapitulo = data.subcapitulo;
            this.cuenta = data.cuenta+""+(data.codigoHijo?data.codigoHijo:"");
            this.concepto = data.concepto;
            this.proyecto = data.proyectos;
        
        } else {
            Object.assign(this, init);
        }
    }
}
export class CuentaListar {
    cuenta!: String
    concepto!: String
    capitulo?: Capitulo
    subCapitulo?: Subcapitulo
    proyecto?: any[]

    public constructor(data?: any, init?: Partial<CuentaListar>) {
        if (data) {
            console.log(data);
            
            //   this.fechaAlta = data.fechaAlta
            this.capitulo = data.capitulo;
            this.subCapitulo = data.subcapitulo;
            this.cuenta = data.cuenta;
            this.concepto = data.concepto;
            this.proyecto = data.proyectos;
        
        } else {
            Object.assign(this, init);
        }
    }
}

export class Subcapitulo {
    id?: number
    nombre!: string
    codigo!: string
    capitulo!: Capitulo

    public constructor(data?: any, init?: Partial<Subcapitulo>) {
        if (data) {
            this.id = data.id;
            this.nombre = data.nombre;
            this.codigo = data.codigo;
            this.capitulo = data.capitulo;

        } else {
            Object.assign(this, init);
        }


    }
}
export class Capitulo {
    id?: number
    nombre!: string

    public constructor(data?: any, init?: Partial<Capitulo>) {
        if (data) {
            this.id = data.id;
            this.nombre = data.nombre;

        } else {
            Object.assign(this, init);
        }


    }
}

