<span class="titulosPaginas ">Manual de usuario </span>

<div class="contenedor">
    <div class="col-2 backgr">
        <div (click)="mostrar('inicio')" [ngClass]="mostrarCampos['inicio'] ? 'pointer mi_card'  : 'pointer mi_cardclosed'">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['inicio'] }">Inicio</span>
            <a [ngClass]="!mostrarCampos['inicio'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>

        <div (click)="mostrar('productora')" [ngClass]="mostrarCampos['productora'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['productora'] }">Productoras</span>
            <a [ngClass]="!mostrarCampos['productora'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>
        <div (click)="mostrar('proyecto')" [ngClass]="mostrarCampos['proyecto'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['proyecto'] }">Proyectos</span>
            <a [ngClass]="!mostrarCampos['proyecto'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>

        <div (click)="mostrar('usuario')" [ngClass]="mostrarCampos['usuario'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['usuario'] }">Usuarios</span>
            <a [ngClass]="!mostrarCampos['usuario'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>
        <div (click)="mostrar('proveedor')" [ngClass]="mostrarCampos['proveedor'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['proveedor'] }">Proveedores</span>
            <a [ngClass]="!mostrarCampos['proveedor'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>
        <div (click)="mostrar('cuentas')" [ngClass]="mostrarCampos['cuentas'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['cuentas'] }">Cuentas</span>
            <a [ngClass]="!mostrarCampos['cuentas'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>

        <div (click)="mostrar('presupuesto')" [ngClass]="mostrarCampos['presupuesto'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['presupuesto'] }">Presupuestos</span>
            <a [ngClass]="!mostrarCampos['presupuesto'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>

        <div (click)="mostrar('coste')" [ngClass]="mostrarCampos['coste'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['coste'] }">Control de costes</span>
            <a [ngClass]="!mostrarCampos['coste'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>
        <div (click)="mostrar('ordenDeCompra')"
            [ngClass]="mostrarCampos['ordenDeCompra'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['ordenDeCompra'] }">Órdenes de
                compra</span>
            <a [ngClass]="!mostrarCampos['ordenDeCompra'] ? 'fechaup_manual':'fechadawn_manual'">a</a>
        </div>

        <div (click)="mostrar('gasto')" [ngClass]="mostrarCampos['gasto'] ? 'pointer mi_card'  : 'pointer mi_cardclosed' ">
            <span class="card_text_closed " [ngClass]="{'card_text':!mostrarCampos['gasto'] }">Gastos</span>
            <a [ngClass]="!mostrarCampos['gasto'] ?'fechaup_manual':'fechadawn_manual'">a</a>
        </div>
    </div>

    <div class="col-10">
        <div *ngIf="mostrarCampos['inicio']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['inicio'] }">

            <p>{{manualInicio.get("1")}} <mat-icon>menu</mat-icon></p>
            <img src="/assets/manual//Inicio_1.png" width="250px" height="260px" />
            <p>{{manualInicio.get("2")}}</p>
            <img src="/assets/manual//Inicio_2.png" width="100%" />

            <!--<img class="mar_l" src="/assets/manual//Inicio_3.png" width="250" height="200"/></div>-->
            <p>{{manualInicio.get("3")}}</p>
            <img src="/assets/manual//Inicio_4.png" />
            <p>{{manualInicio.get("4")}}</p>
            <img src="/assets/manual//Inicio_5.png" />

        </div>
        <div *ngIf="mostrarCampos['productora']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['productora']}">

            <p>{{manualProductora.get("1")}}</p>
            <img src="/assets/manual//Productora_0.png" height="80px" />
            <img src="/assets/manual//Productora_1.png" height="80px" />
            <p>{{manualProductora.get("2")}}</p>
            <img src="/assets/manual//Productora_2.png" width="100%" />

            <p>{{manualProductora.get("3")}}</p>
            <img src="/assets/manual//Productora_3.png" />
            <p>{{manualProductora.get("4")}}</p>
            <img src="/assets/manual//Productora_4.png" />

        </div>


        <div *ngIf="mostrarCampos['proyecto']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['proyecto'] }">
            <p>{{manualProyecto.get("1")}}</p>
            <img src="/assets/manual//Proyecto_1.png" height="80px" />
            <p>{{manualProyecto.get("2")}}</p>
            <img src="/assets/manual//Proyecto_2.png" width="100%" />

            <p>{{manualProyecto.get("3")}}</p>
            <img src="/assets/manual//Proyecto_3.png" />
            <p>{{manualProyecto.get("4")}}</p>
            <img src="/assets/manual//Proyecto_4.png" />


        </div>


        <div *ngIf="mostrarCampos['usuario']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['usuario'] }">

            <p>{{manualUsuario.get("1")}}</p>
            <img src="/assets/manual//Productora_0.png" height="80px" />
            <img src="/assets/manual//Usuario_1.png" height="80px" />
            <p>{{manualUsuario.get("2")}}</p>
            <img src="/assets/manual//Usuario_2.png" width="100%" />

            <p>{{manualUsuario.get("3")}}</p>
            <img src="/assets/manual//Usuario_3.png" />
            <img src="/assets/manual//Usuario_4.png" />
            <p>{{manualUsuario.get("4")}}</p>
            <img src="/assets/manual//Usuario_5.png" />
            <p>{{manualUsuario.get("5")}}</p>
            <img src="/assets/manual//Usuario_6.png" />
            <p>{{manualUsuario.get("6")}}</p>
            <img src="/assets/manual//Usuario_7.png" />
            <p>{{manualUsuario.get("7")}}</p>
            <img src="/assets/manual//Usuario_8.png" />

            <p>{{manualUsuario.get("8")}}</p>
            <img src="/assets/manual//Usuario_9.png" />


        </div>


        <div *ngIf="mostrarCampos['proveedor']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['proveedor'] }">

            <p>{{manualProveedor.get("1")}}</p>
            <img src="/assets/manual//Proveedor_1.png" height="80px" />
            <p>{{manualProveedor.get("2")}}</p>
            <img src="/assets/manual//Proveedor_2.png" width="100%" />

            <p>{{manualProveedor.get("3")}}</p>
            <img src="/assets/manual//Proveedor_3.png" />
            <p>{{manualProveedor.get("4")}}</p>
            <img src="/assets/manual//Usuario_6.png" />
            <p>{{manualProveedor.get("5")}}</p>

            <img src="/assets/manual//Proveedor_4.png" />
            <p>{{manualProveedor.get("6")}}</p>
            <img src="/assets/manual//Proveedor_5.png" />

        </div>
        <div *ngIf="mostrarCampos['cuentas']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['cuentas'] }">
            <p>{{manualCuenta.get("1")}}</p>
            <img src="/assets/manual//Productora_0.png" height="80px" />
            <img src="/assets/manual//Cuenta_1.png" height="80px" />
            <p>{{manualCuenta.get("2")}}</p>
            <img src="/assets/manual//Cuenta_2.png" width="100%" />

            <p>{{manualCuenta.get("3")}}</p>
            <img src="/assets/manual//Cuenta_3.png" />

            <p>{{manualCuenta.get("4")}}</p>
            <img src="/assets/manual//Cuenta_4.png" />


        </div>


        <div *ngIf="mostrarCampos['presupuesto']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['presupuesto'] }">
            <p>{{manualPresupuesto.get("1")}}</p>
            <img src="/assets/manual//Presupuesto_1.png" height="80px" />
            <img src="/assets/manual//Presupuesto_2.png" height="80px" />

            <p>{{manualPresupuesto.get("2")}}</p>
            <img src="/assets/manual//Presupuesto_3.png" />

            <p>{{manualPresupuesto.get("3")}}</p>
            <img src="/assets/manual//Presupuesto_4.png" />



        </div>

        <div *ngIf="mostrarCampos['presupuesto']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['presupuesto'] }">
            <p>{{manualPresupuesto.get("1")}}</p>
            <img src="/assets/manual//Presupuesto_1.png" height="80px" />
            <img src="/assets/manual//Presupuesto_2.png" height="80px" />

            <p>{{manualPresupuesto.get("2")}}</p>
            <img src="/assets/manual//Presupuesto_3.png" />

            <p>{{manualPresupuesto.get("3")}}</p>
            <img src="/assets/manual//Presupuesto_4.png" />

        </div>
        <div *ngIf="mostrarCampos['coste']" class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['coste'] }">
            <p>{{manualCoste.get("1")}}</p>
            <img src="/assets/manual//Presupuesto_1.png" height="80px" />
            <img src="/assets/manual//Costes_1.png" height="80px" />
            <p>{{manualCoste.get("2")}}</p>
            <img src="/assets/manual//Costes_2.png" />
            <p>{{manualCoste.get("3")}}</p>
            <img src="/assets/manual//Costes_3.png" />
            <p>{{manualCoste.get("4")}}</p>
            <img src="/assets/manual//Costes_4.png" />
           

        </div>


        <div *ngIf="mostrarCampos['ordenDeCompra'] " class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['ordenDeCompra'] }">
            <p>{{manualOrdenDeCompra.get("1")}}</p>
            <img src="/assets/manual//Productora_0.png" height="80px" />
            <img src="/assets/manual//OrdenDeCompra_1.png" height="80px" />
            <p>{{manualOrdenDeCompra.get("2")}}</p>
            <img src="/assets/manual//OrdenDeCompra_2.png" width="100%" />

            <p>{{manualOrdenDeCompra.get("3")}}</p>
            <img src="/assets/manual//OrdenDeCompra_3.png" />

            <p>{{manualOrdenDeCompra.get("4")}}</p>
            <img src="/assets/manual//OrdenDeCompra_4.png" />
            <p>{{manualOrdenDeCompra.get("5")}}</p>
            <img src="/assets/manual//OrdenDeCompra_5.png" />
            <p>{{manualOrdenDeCompra.get("6")}}</p>
            <img src="/assets/manual//OrdenDeCompra_6.png" />
            <p>{{manualOrdenDeCompra.get("7")}}</p>
            <img src="/assets/manual//OrdenDeCompra_7.png" />
            <p>{{manualOrdenDeCompra.get("8")}}</p>
            <img src="/assets/manual//OrdenDeCompra_8.png" />

        </div>


        <div *ngIf="mostrarCampos['gasto'] " class="form-surrouded"
            [ngClass]="{'form-surrouded2': !mostrarCampos['gasto'] }">
            <p>{{manualGasto.get("1")}}</p>
            <img src="/assets/manual//Gasto_1.png" height="80px" />
            <img src="/assets/manual//Gasto_2.png" height="80px" />
            <p>{{manualGasto.get("2")}}</p>
            <img src="/assets/manual//Gasto_3.png" />
            <p>{{manualGasto.get("3")}}</p>
            <img src="/assets/manual//Gasto_4.png" />
            <p>{{manualGasto.get("4")}}</p>
            <img src="/assets/manual//Gasto_5.png" />
            <p>{{manualGasto.get("5")}}</p>
            <img src="/assets/manual//Gasto_6.png" />
            <p>{{manualGasto.get("6")}}</p>
            <img src="/assets/manual//Gasto_7.png" />


        </div>
    </div>
</div>