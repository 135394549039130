import { Productora, ProductoraExportProy } from "./productora"
import { Proveedor, ProveedorExportProy } from "./proveedor"

export class Proyecto {
    id!: number
    // fechaAlta!:String
    fechaFin!: String
    fechaFinRodaje!: String
    fechaInicio!: String
    fechaInicioRodaje!: String
    hasIgic!: boolean
    hasIntracomunitario!: boolean
    hasIva!: boolean
    //TODO: OBjetos de los typos any
    lugarDecorado!: any
    monedaCambio!: MonedaCambio[]
    productoras!: Productora[]
    proveedores!: Proveedor[]
    semanasCierre!: number
    semanasPreproducción!: number
    semanasRodaje!: number
    titulo!: string
    // lugares!: string[]
    // decorados!: string[]
    isPresupuestoClosed!: boolean
    vehiculos!: any;
    horasExtras!: any;
    dietasActores!: any;
    complementosMaterial!: any;
    horasExtrasFestivas!: any;
    dietasTecnicos!: any;
    versionCodigos!: number;


    public constructor(data?: any, init?: Partial<Proyecto>) {
        if (data) {
            this.id = data.id;
            //   this.fechaAlta = data.fechaAlta
            this.fechaFin = data.fechaFin;
            this.fechaFinRodaje = data.fechaFinRodaje;
            this.fechaInicio = data.fechaInicio;
            this.fechaInicioRodaje = data.fechaInicioRodaje;
            this.hasIgic = data.hasIgic
            this.hasIntracomunitario = data.hasIntracomunitario
            this.hasIva = data.hasIva
            this.lugarDecorado = data.lugarDecorado
            this.monedaCambio = data.monedaCambio
            this.productoras = data.productoras
            this.proveedores = data.proveedores
            this.semanasCierre = data.semanasCierre
            this.semanasPreproducción = data.semanasPreproducción
            this.semanasRodaje = data.semanasRodaje
            this.titulo = data.titulo
            // this.lugares = data.lugares
            // this.decorados = data.decorados
            this.isPresupuestoClosed = data.isPresupuestoClosed
            this.vehiculos = data.vehiculos;
            this.horasExtras = data.horasExtras;
            this.dietasActores = data.dietasActores;
            this.complementosMaterial = data.complementosMaterial;
            this.horasExtrasFestivas = data.horasExtrasFestivas;
            this.dietasTecnicos = data.dietasTecnicos;
            this.versionCodigos = data.versionCodigos;
        } else {
            Object.assign(this, init);
        }
    }
}

export class ProyectoListar {

    id!: number
    // fechaAlta!:String
    fechaFin!: String
    finRodaje!: String
    fechaInicio!: String
    inicioRodaje!: String
    igic!: boolean
    intra!: boolean
    iva!: boolean
    //TODO: OBjetos de los typos any
    lugarDecorado!: any
    //monedaCambio!:any
    productoras!: Productora[]
    proveedores!: Proveedor[]
    semCierre!: number
    semPre!: number
    semRod!: number
    titulo!: String
    // decorados!: String[]
    // lugares!: String[]

    public constructor(data?: any, init?: Partial<Proyecto>) {
        if (data) {
            this.id = data.id;
            this.titulo = data.titulo
            // this.fechaAlta = data.fechaAlta
            this.fechaInicio = data.fechaInicio;
            this.fechaFin = data.fechaFin;
            this.inicioRodaje = data.fechaInicioRodaje;
            this.finRodaje = data.fechaFinRodaje;
            this.semPre = data.semanasPreproducción
            this.semRod = data.semanasRodaje
            this.semCierre = data.semanasCierre
            this.iva = data.hasIva
            this.igic = data.hasIgic
            this.intra = data.hasIntracomunitario
            /*             this.lugarDecorado = data.lugarDecorado
             */            //this.monedaCambio = data.monedaCambio
            /*   this.productoras = data.productoras
              this.proveedores = data.proveedores */
            // this.lugares = data.lugares
            // this.decorados = data.decorados
        } else {
            Object.assign(this, init);
        }
    }
}

export class ProyectoExport {

    id!: number
    fechaFin!: String
    fechaFinRodaje!: String
    fechaInicio!: String
    fechaInicioRodaje!: String
    igic!: string
    intracomunitario!: string
    iva!: string
    monedaCambio!: MonedaCambio[]
    lugarDecorado!: LugarDecorado[]
    productoras!: ProductoraExportProy[]
    proveedores!: ProveedorExportProy[]
    semanasCierre!: number
    semanasPreproducción!: number
    semanasRodaje!: number
    titulo!: String
    // lugares!: any[]
    // decorados!: any[]
    vehiculos!: string;
    horasExtras!: string;
    dietasActores!: string;
    complementosMaterial!: string;
    horasExtrasFestivas!: string;
    dietasTecnicos!: string;

    public constructor(data?: any, init?: Partial<ProyectoExport>) {
        if (data) {
            this.id = data.id;
            this.titulo = data.titulo
            // this.fechaAlta = data.fechaAlta
            this.fechaFin = data.fechaFin;
            this.fechaFinRodaje = data.fechaFinRodaje;
            this.fechaInicio = data.fechaInicio;
            this.fechaInicioRodaje = data.fechaInicioRodaje;
            this.igic = data.hasIgic ? "Si" : "No"
            this.intracomunitario = data.hasIntracomunitario ? "Si" : "No"
            this.iva = data.hasIva ? "Si" : "No"
            this.lugarDecorado = data.lugarDecorado
            //this.monedaCambio = data.monedaCambio
            this.productoras = []
            this.monedaCambio = data.monedaCambio
            data.productoras.forEach((element: any) => {
                this.productoras.push(new ProductoraExportProy(element))
            });
            this.lugarDecorado = data.lugarDecorado
            data.productoras.forEach((element: any) => {
                this.productoras.push(new ProductoraExportProy(element))
            });
            this.proveedores = []
            data.proveedores.forEach((element: any) => {
                this.proveedores.push(new ProveedorExportProy(element))
            });
            // this.decorados = []
            // data.decorados.forEach((element: any, index: number) => {
            //     this.decorados.push({ id: index, decorados: element })
            // });
            // this.lugares = []
            // data.lugares.forEach((element: any, index: number) => {
            //     this.lugares.push({ id: index, lugares: element })
            // });
            this.semanasCierre = data.semanasCierre
            this.semanasPreproducción = data.semanasPreproducción
            this.semanasRodaje = data.semanasRodaje
            this.vehiculos = data.vehiculos ? data.vehiculos.cuenta : '';
            this.horasExtras = data.horasExtras ? data.horasExtras.cuenta : '';
            this.dietasActores = data.dietasActores ? data.dietasActores.cuenta : '';
            this.complementosMaterial = data.complementosMaterial ? data.complementosMaterial.cuenta : '';
            this.horasExtrasFestivas = data.horasExtrasFestivas ? data.horasExtrasFestivas.cuenta : '';
            this.dietasTecnicos = data.dietasTecnicos ? data.dietasTecnicos.cuenta : '';

        } else {
            Object.assign(this, init);
        }
    }
}

export class MonedaCambio {
    id?: number
    moneda!: string
    cambio!: number
    tipoMoneda!: TipoMoneda;

    public constructor(data?: any, init?: Partial<MonedaCambio>) {
        if (data) {
            this.id = data.id;
            this.moneda = data.moneda;
            this.cambio = data.cambio;
            this.tipoMoneda = data.tipoMoneda;
        } else {
            Object.assign(this, init);
        }
    }
}

export class LugarDecorado {
    id?: number
    lugar!: string
    decorado!: string

    public constructor(data?: any, init?: Partial<LugarDecorado>) {
        if (data) {
            this.id = data.id;
            this.lugar = data.lugar;
            this.decorado = data.decorado;
        } else {
            Object.assign(this, init);
        }
    }
}

export class TipoMoneda {
    id?: number
    nombre!: string
    signo!: number

    public constructor(data?: any, init?: Partial<TipoMoneda>) {
        if (data) {
            this.id = data.id;
            this.nombre = data.nombre;
            this.signo = data.signo;
        } else {
            Object.assign(this, init);
        }
    }
}