<div class="form-surrouded">
    <button (click)="visibility()" class="buttonRounded buttonPrimary">
        Boton redondeado
    </button>
    <div [@slideInOut]="visible" class="visible">
        <div class="formMaterial">
            <mat-form-field appearance="outline" class=" col-12 formField">
                <input matInput placeholder="un algo">
            </mat-form-field>
        </div>
        <div class="formMaterial">
            <mat-form-field appearance="outline" class="col-12 formField">
                <mat-select placeholder="Selecciona"></mat-select>
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="col-4 formMaterial">
            <mat-select class="col-4" placeholder="Selecciona"></mat-select>
        </mat-form-field>
        <div class="col-4 rowForm formMaterial">
            <label class="col-12 formLabel">Un Label</label>
            <mat-form-field appearance="outline" class=" col-12 formField">
                <input matInput placeholder="un algo">
            </mat-form-field>
        </div>
    </div>
        <div class="col-4 rowForm formMaterial">
            <label class="col-12 formLabel">Un Label</label>
            <mat-form-field appearance="outline" class="col-12 formField">
                <mat-select placeholder="Selecciona"></mat-select>
            </mat-form-field>
        </div>
        <mat-form-field appearance="outline" class="col-4 formMaterial">
            <mat-select class="col-4" placeholder="Selecciona"></mat-select>
        </mat-form-field>

    <button class="buttonRounded buttonPrimary">
        Boton redondeado
    </button>
    <button class="buttonSquared buttonPrimary">
        Boton cuadrado
    </button>

    <div class="archive-manager mt-3">
        <div appDrag >
                <input id="file" value="file" name="file"
                onclick="event.target.value=null" type="file" />
            <div class="cloudContainer">   
                <div>
                    <a>ICON</a>
                </div>
            </div>
            <h6>Arrastre aquí los archivos o haga clic para seleccionar</h6>

        </div>
    </div>

</div>
