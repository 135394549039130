
<app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
    [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
    [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento"
    [canCreate]="tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [canExport]="tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
    [dataExport]="dataExport" [pendienteOc]="pendienteOc" [totalGasto]="totalGasto" [totalIRPF]="totalIRPF"
    [totalIva]="totalIva" [totalBase]="totalBase"></app-header-listados>


<div>

  <div class="fondoblanco fondoblanco_tabla">


    <div class="mat-elevation-z8  " class="table_container data_table">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Nº">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">

              <input matInput type="text" class="searchinput uppercas" placeholder="Nº GASTO"
                (keyup)="doFilter($event, 'numGasto', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Nº', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> <span class="inline_text">{{element.numGasto}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="tieneCoste">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <select class="searchinput selectinput" (change)="doFilter($event, 'tieneCoste', 'EQUAL', 'BOOLEAN')" onblur="if(this.value==undefined){this.type='text'}">
                <option class="selectinput" [value]="''">
                  COSTE
                </option>
                <option class="selectinput" [value]="true">
                    EN COSTE
                </option>
                <option class="selectinput" [value]="false">
                    PASAR A COSTE
                </option>
            </select>
              <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
            </div>
          </th>
          <td *matCellDef="let element"><span *ngIf="element.tieneCoste">En coste</span>
            <span class="inlineText" *ngIf="!element.tieneCoste">
              <div class="activo pointer" (click)="pasarCoste(element)"> Pasar a coste</div>
            </span>
          </td>
        </ng-container>


        <ng-container matColumnDef="Fecha Factura">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Fecha Factura"
                (keyup)="doFilter($event, 'fecha_factura', 'LIKE', 'DATE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Fecha Factura', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['fecha_factura'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('fecha_factura')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> <span class="inline_text">{{element.fecha_factura}}</span></td>

        </ng-container>

        <ng-container matColumnDef="Código cuenta">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Cuenta"
                (keyup)="doFilter($event, 'conceptos.codigo.cuenta', 'LIST_CONTAINS', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Cuenta', '600 14px Montserrat, sans-serif')}">
            </div>
          </th>
          <td *matCellDef="let element"><span class="inline_text">{{element.conceptos[0]?.codigo?.cuenta}}</span> </td>

        </ng-container>

        <ng-container matColumnDef="Descripción cuenta">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Concepto"
                (keyup)="doFilter($event, 'conceptos.codigo.concepto', 'LIST_CONTAINS', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Concepto', '600 14px Montserrat, sans-serif')}">
            </div>
          </th>
          <td *matCellDef="let element"><span class="inline_text">{{getConcepto(element.conceptos[0]?.codigo)}}</span> </td>

        </ng-container>

        <ng-container matColumnDef="Concepto">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Descripción"
                (keyup)="doFilter($event, 'conceptos.descripcion', 'LIST_CONTAINS', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Descripción', '600 14px Montserrat, sans-serif')}"> 
            </div>
          </th>
          <td *matCellDef="let element; let i = index"  [matTooltip]="conceptosTooltip(i)" matTooltipClass="tooltip-class" matTooltipPosition="after">
            <span class="inline_text">{{element.conceptos[0]?.descripcion}}</span> 
          </td>
        </ng-container>

        <ng-container matColumnDef="Factura">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Nº Factura"
                (keyup)="doFilter($event, 'numeroFactura', 'LIKE', 'LONG')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Nº Factura', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['numeroFactura'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('numeroFactura')"></a>
            </div>
          </th>
          <td *matCellDef="let element"> <span class="inline_text">{{element.numeroFactura}}</span> 
          </td>
        </ng-container>

        <ng-container matColumnDef="NIF">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="NIF"
                (keyup)="doFilter($event, 'proveedor.cif', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('NIF', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['proveedor.cif'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('proveedor.cif')"></a>
            </div>
          </th>
          <td *matCellDef="let element"><span class="inline_text">{{element.proveedor.cif}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="Proveedor">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder=" Proveedor"
                (keyup)="doFilter($event, 'proveedor.nombreFiscal', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Proveedor', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['proveedor.nombreFiscal'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('proveedor.nombreFiscal')"></a>
            </div>
          </th>
          <td *matCellDef="let element"><span class="inline_text">{{element.proveedor.nombreFiscal}}</span> </td>
        </ng-container>





        <ng-container matColumnDef="Base">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase input-right" placeholder="Base"
                (keyup)="doFilter($event, 'base', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Base', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['base'] ? 'fechaup':'fechadawn'" (click)=" ordenar('base')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput">
            <span class="inline_text">{{element.base|numberEsp}}€</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="Irpf" >
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase input-right" placeholder="IRPF"
                (keyup)="doFilter($event, 'irpf', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Irpf', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['irpf'] ? 'fechaup':'fechadawn'" (click)=" ordenar('irpf')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput">
            <span class="inline_text">{{element.irpf|numberEsp}}€</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Iva">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase input-right" placeholder="IVA"
                (keyup)="doFilter($event, 'iva', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Iva', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['iva'] ? 'fechaup':'fechadawn'" (click)=" ordenar('iva')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput"> 
            <span class="inline_text">{{element.iva|numberEsp}}€</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="Departamento">
          <th mat-header-cell *matHeaderCellDef class="th_large">
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Departamento"
                (keyup)="doFilter($event, 'departamento.nombre', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Departamento', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['departamento.nombre'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('departamento.nombre')"></a>
            </div>
          </th>
          <td *matCellDef="let element">
            <span class="inline_text">{{element.departamento.nombre}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Creado">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Creado"
                (keyup)="doFilter($event, 'createdBy.nombreUsuario', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Creado', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['createdBy.nombreUsuario'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('createdBy.nombreUsuario')"></a>
            </div>
          </th>
          <td *matCellDef="let element">
            <span class="inline_text">{{element.createdBy.nombreUsuario}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Modificado">
          <th mat-header-cell *matHeaderCellDef class="th_large">
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Modificado"
                (keyup)="doFilter($event, 'modifyBy.nombreUsuario', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Modificado', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['modifyBy.nombreUsuario'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('modifyBy.nombreUsuario')"></a>
            </div>
          </th>
          <td *matCellDef="let element">
            <span class="inline_text">{{element.modifyBy== undefined? '':element.modifyBy.nombreUsuario }}</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="Fecha modificación">
          <th mat-header-cell *matHeaderCellDef class="th_large">
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Fecha modificación"
                (keyup)="doFilter($event, 'fechaModificacion', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Fecha modificación', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['fechaModificacion'] ? 'fechaup':'fechadawn'"
                (click)=" ordenar('fechaModificacion')"></a>
            </div>
          </th>
          <td *matCellDef="let element">
            <span class="inline_text">{{element.fechaModificacion==undefined?'': element.fechaModificacion}}</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="Total">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase input-right" placeholder="TOTAL"
                (keyup)="doFilter($event, 'total', 'LIKE', 'DOUBLE')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Total', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['total'] ? 'fechaup':'fechadawn'" (click)=" ordenar('total')"></a>
            </div>
          </th>
          <td *matCellDef="let element" class="numberInput">
            <span class="inline_text">{{element.total|numberEsp}}€</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Período de Impuesto">
          <th mat-header-cell *matHeaderCellDef>
            <div class="thdisp">
              <input matInput type="text" class="searchinput uppercase" placeholder="Período de Impuesto"
                (keyup)="doFilter($event, 'periodo_iva.nombre', 'LIKE', 'STRING')"
                [ngStyle]="{'min-width.px':  tableService.calcTextWidth('Período de Impuesto', '600 14px Montserrat, sans-serif')}">
              <a [ngClass]="ordenarCampos['periodo_iva.nombre'] ? 'fechaup':'fechadawn'" (click)=" ordenar('periodo_iva.nombre')"></a>
            </div>
          </th>
          <td *matCellDef="let element">
            <span class="inline_text">{{element.periodo_iva.nombre}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef class="accionesth"><span class="correccionmargenAcciones uppercase">
              Acciones
            </span>
          </th>
          <td class=" " *matCellDef="let element" class="accionesth">
            <div class="acciones">
              <a title="Ver"
                [ngClass]="tableService.checkPermiso(8,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'view-button pointer'"
                (click)=" tableService.checkPermiso(8,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegarAEditar(element.id,'ver')"></a>
              <a title="Editar"
                [ngClass]="tableService.checkPermiso(8,3,interfazInicial.isSuperAdmin,interfazInicial.permisos) || (element.tieneCoste && (element.total == element.total_pagado)) ? 'display-edit-button':'edit-button pointer'"
                (click)=" tableService.checkPermiso(8,3,interfazInicial.isSuperAdmin,interfazInicial.permisos) || (element.tieneCoste && (element.total == element.total_pagado))? '':navegarAEditar(element.id,'editar')"></a>
              <a title="Descargar"
                [ngClass]="tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-download-button':'download-button pointer'"
                (click)=" tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':download(element)"></a>
              <a title="Borrar"
                [ngClass]="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)  || element.tieneCoste? 'display-delete-button':'delete-button pointer'"
                (click)="tableService.checkPermiso(8,5,interfazInicial.isSuperAdmin,interfazInicial.permisos) || element.tieneCoste?'':dialogVisible = true; idEliminar = element"></a>


            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
          (mouseenter)="tableService.marcarColumna(row,true)" (mouseleave)="tableService.marcarColumna(row,false)"
          (click)="tableService.marcarColumna(row,null)" *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>
    <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
      (page)="nextPage($event)">
    </mat-paginator>

    <div class="dialog-container" *ngIf="dialogVisible">
      <div class="dialog-content">
        <div class="contenedortextos">
          <div class="circle_document">
            <a class="delete-div"></a>
          </div>
        </div>
        <div class="contenedortextos">
        <h4 class="colorh4">¿Desea eliminar el Gasto {{idEliminar.numGasto}}?</h4>
        </div>
        <div class="contenedortextos2">
          <p>Si se elimina el gasto se eliminarán los conceptos, pagos y documentos que tenga asociados</p>
        </div>
        <div class="dialog_buttons">
          <button (click)="dialogVisible = false" id="btcancel" class="btn btn-primary mx-1 mt-3">Cancelar</button>
          <button id="btacept" class="btn btn-primary mx-1 mt-3">
            <div></div>
            <span (click)="borrarElemento(idEliminar.id)" class="align-middle"> Confirmar</span>
          </button>
        </div>
      </div>
    </div>
  </div>