import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Proveedor } from '../models/proveedor';
import { Observable } from 'rxjs';
import { BancosIban, Productora } from '../models/productora';

@Injectable({
  providedIn: 'root'
})
export class ProductoraService {
  url: string = environment.API_URL + "/productora";
  constructor(private http: HttpClient) { }

  /* 
  Servicio para las peticiones para el guardado, modificado y listado de productoras
  */

  getAllProductoras() {
    return this.http.get(`${this.url}/list`);
  }

  filter(filter: any): Observable<any> {
    return this.http.post(`${this.url}/filter`, filter);
  }
  save(productora: Productora): Observable<any> {
    productora.bancosIban = this.solveIban(productora.bancosIban)
    return this.http.post(`${this.url}`, productora);
  }
  update(id: any, productora: Productora): Observable<any> {
    console.log("productora", productora);
    productora.bancosIban = this.solveIban(productora.bancosIban)
    return this.http.post(`${this.url}/${id}`, productora);
  }
  findById(id: any): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  solveIban(bancosIban: BancosIban[]): BancosIban[] {
    var bacosNuev: BancosIban[] = []
    bancosIban.forEach((data) => {
      data.iban = data.iban.replaceAll(" ", "")
      bacosNuev.push(data)
    })
    return bacosNuev
  }
  deleteById(id: number) {
    return this.http.post(`${this.url}/delete`, { id: id });
  }



  findByNifCif(value: any) {
    let params = new HttpParams().set("nifCif", value)
    return this.http.get(`${this.url}/findByNifCif`, { params: params });
  }

}
