
<app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
[mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
[nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento"
[canCreate]="!isAdmin"
[canExport]="tableService.checkPermiso(1,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
[dataExport]="dataExport"></app-header-listados>
<div>
    <div class="fondoblanco fondoblanco_tabla">

        <div class="table_container data_table mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">
                <!-- numero Column -->
                <span class="tableContent" *ngFor="let column of displayedColumns ">
                    <!-- Este es el listado de las cabeceras quitando el de acciones seria copiar y pegar, es importante saber que se va a poner
                            el mismo nombre que tenga en el objeto de la lista pero tanto el objeto de kla lista y el del back tienen que tener los mismos
                             nombres pero puedes quitar campos que no se quieren listar -->
                    <ng-container *ngIf="column!='acciones'&&column!='id'" matColumnDef="{{column}}">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="thdisp " [ngClass]="column == 'titulo'?'thdisp-big':''">

                                <input matInput type="text" class="searchinput uppercase"
                                    placeholder="{{primLetMay(column)}}" (keyup)="doFilter($event, column == 'productoras'?'productoras.ciudad':
                                                    column == 'proveedores'?'proveedores.nombreComercial':
                                                    column == 'lugarDecorado' ? 'lugarDecorado.lugar' : 
                                                    column, 
                                                    column == 'proveedores'?'LIST_CONTAINS':
                                                    'LIKE', 'STRING')"
                                    [ngStyle]="{'min-width.px': tableService.calcTextWidth(primLetMay(column), '600 14px Poppins, sans-serif')}">
                                <a [ngClass]="column == 'lugarDecorado' || column == 'proveedores' ? '':
                            ordenarCampos[column] ? 'fechaup':'fechadawn'"
                                    (click)=" column == 'proveedores' || column == 'lugarDecorado'?'':ordenar(column)"></a>
                            </div>
                        </th>
                        <tr>

                            <td *matCellDef="let element" class="celds">
                                <!-- Aqui si todos los elementos fuesen unicops seria el  <span >{{element}}</span>  sino se 
                                    tendria que hacer como aqui las comprobaciones dependiendo el caso pero para listas que 
                                    no tienen objetos añadidos seria solo esa linea-->

                                <span class="inline_text"
                                    *ngIf="column != 'productoras' && column != 'proveedores'&& column != 'lugarDecorado'
                                    && column != 'igic' && column != 'intra' && column != 'iva'&& column != 'lugares' && column != 'decorados'&&
                                    column != 'semRodaje' && column != 'semPreproducción'&& column != 'semCierre'">
                                    {{element[column] }}
                                </span>
                                <span class="inline_text" *ngIf="column == 'semRodaje' || column == 'semPreproducción'|| column == 'semCierre'
                                  ">
                                    {{element[column]?(element[column] |numberEsp):""}}
                                </span>
                                <span class="inline_text"
                                    *ngIf="column == 'igic' || column == 'intra' || column == 'iva'">
                                    {{element[column]?'Si':'No'}}
                                </span>

                                <span class="inline_text" *ngIf="column == 'productoras'">
                                    <label>{{tableService.checkLength(element[column],"nombre")}}</label>
                                </span>
                                <!-- <span class="inline_text" *ngIf="column == 'lugares'|| column == 'decorados'">
                                <label>{{tableService.checkLength(element[column],"")}}</label>
                            </span> -->
                                <span *ngIf="column == 'lugarDecorado'">
                                    <label>{{tableService.checkLength(element[column],"lugar")}}</label>
                                </span>
                                <span class="inline_text" *ngIf="column == 'proveedores'">
                                    <label>{{tableService.checkLength(element[column],"nombreComercial")}}</label>
                                </span>

                                <span class="inline_text" *ngIf="column == 'nombreUsuario'">
                                    <label class=" mb-0"><b>{{element[column]}}</b><br>{{element.cargo}}</label>
                                </span>
                                <!--  <span *ngIf="column == 'productoras'">
                                    <button (click)="updateActivo(element.id,element.active)"
                                        [ngClass]="element[column]?'activo': 'inactivo'">
                                        <b>{{element[column]?"Activo":"Inactivo"}}</b>
                                    </button>
                                </span> -->
                            </td>
                        </tr>
                    </ng-container>
                </span>

                <!-- Aqui va la columna de acciones para que se pueda hacer fija -->
                <ng-container matColumnDef="acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef class="accionesth"><span
                            class="correccionmargenAcciones uppercase">
                            Acciones</span>
                    </th>
                    <td *matCellDef="let element" class="accionesth">
                        <div class="acciones" stickyEnd>
                            <a title="Ver"
                                [ngClass]="tableService.checkPermiso(1,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-view-button':'view-button pointer'"
                                (click)=" tableService.checkPermiso(1,1,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'ver')"></a>
                            <a title="Editar"
                                [ngClass]="tableService.checkPermiso(1,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-edit-button':'edit-button pointer'"
                                (click)=" tableService.checkPermiso(1,3,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':navegar(element.id,'editar')"></a>
                            <a title="Descargar"
                                [ngClass]="tableService.checkPermiso(1,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-download-button':'display-download-button'"
                                (click)=" tableService.checkPermiso(1,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)?'':''"></a>
                            <a title="Borrar"
                                [ngClass]="tableService.checkPermiso(1,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)? 'display-delete-button':'delete-button pointer'"
                                (click)=" tableService.checkPermiso(1,5,interfazInicial.isSuperAdmin,interfazInicial.permisos)? '':dialogVisible = true; idEliminar = element"></a>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="id" stickyEnd class="id_oculta">
                    <th mat-header-cell *matHeaderCellDef class="id_oculta">
                    </th>
                    <td *matCellDef="let element" class="id_oculta">
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row
                    [ngClass]="{'marcado': tableService.columnaMarcada === row|| tableService.columnaMarcadaClicada === row}"
                    (mouseenter)="tableService.marcarColumna(row,true)"
                    (mouseleave)="tableService.marcarColumna(row,false)" (click)="tableService.marcarColumna(row,null)"
                    *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize" [length]="totalElements"
            (page)="nextPage($event)">
        </mat-paginator>

        <div class="dialog-container" *ngIf="dialogVisible">
            <div class="dialog-content">
                <div class="contenedortextos">
                    <div class="circle_document">
                        <a class="delete-div"></a>
                    </div>
                </div>
                <div class="contenedortextos">
                    <h4 class="colorh4">¿Desea eliminar el proyecto {{idEliminar.titulo}}?</h4>
                    </div>
                    <div class="contenedortextos2">
                      <p>Se eliminará de forma permanente la información del proyecto</p>
                    </div>
                <div class="dialog_buttons">
                    <button (click)="dialogVisible = false" id="btcancel"
                        class="btn btn-primary mx-1 mt-3">Cancelar</button>
                    <button id="btacept" class="btn btn-primary mx-1 mt-3">
                        <div></div>
                        <span (click)="delete(idEliminar.id)" class="align-middle"> Confirmar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>