import { Component, ElementRef } from '@angular/core';
import { PresupuestoService } from 'src/app/services/presupuesto.service';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { Proyecto } from 'src/app/models/proyecto';
import { RaizComponent } from '../../_raiz/raiz.component';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { MatSelectChange } from '@angular/material/select';
import { PdfExportService } from 'src/app/services/servicios-funcionalidad/pdf-export.service';
import { CodigosCuentasPipe } from 'src/app/services/servicios-funcionalidad/codigos-cuentas.pipe';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-nuevo-informe',
  templateUrl: './nuevo-informe.component.html',
  styleUrls: ['./nuevo-informe.component.scss']
})
export class NuevoInformeComponent {

  loading = false
  totalElements = 0
  page = "0";
  filters: any[] = [];
  pageSize = "10";
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  proyecto: Proyecto = this.interfazInicial.proyaux
  array: any = []
  informe: { [proveedor: string]: any[] } = {};
  informeCapAux: { [proveedor: string]: any[] } = {};
  informeSubCapAux: { [proveedor: string]: any[] } = {};
  nombrePagina = "Informes";
  informeTipo = "";
  informeSubtipo = "";
  informeFecha = "";

  eventoTipo = undefined
  eventoSubTipo = undefined
  export = false

  constructor(
    private gastoService: RegistroGastosService,
    private presupuestoService: PresupuestoService,
    private tableService: TableService,
    private interfazInicial: InterfazInicialComponent,
    private raiz: RaizComponent,
    private pdfService: PdfExportService,
    private router: Router,
    private notificationService: NotificationService
  ) {

  }
  ngOnInit(): void {
    if (this.tableService.checkAnyAccionByPermiso(20, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    }

    this.filters = [
      {
        field_type: "STRING",
        key: "proyecto.id",
        operator: "CONTAINS_ID",
        value: this.proyecto.id
      }];
    this.tableService.subscribeToProyectoChanges(async () => {
      this.proyecto = this.interfazInicial.proyaux

      this.filters = [
        {
          field_type: "STRING",
          key: "proyecto.id",
          operator: "CONTAINS_ID",
          value: this.proyecto.id
        }];
      this.array = []
      this.subTipos = []
    });

  }
  subTipos: any = []
  /**
   * Verifica la información después de que cambia el valor del MatSelect de tipo.
   * @param eve El evento de cambio de valor en el MatSelect de tipo.
   */
  checkInfoSub(eve: MatSelectChange) {
    console.log(eve);
    
    this.array = []
    this.informe = {}
    if (eve.source.value == 1) {
      this.subTipos = [{ id: 1, nombre: "Capitulos" }, { id: 2, nombre: "SubCapitulos" }, { id: 3, nombre: "Codigos" }]
    } else if (eve.source.value == 2) {
      this.subTipos = [{ id: 4, nombre: "Capitulos" }, { id: 5, nombre: "SubCapitulos" }, { id: 6, nombre: "Codigos" }]
    } else if (eve.source.value == 3) {
      this.subTipos = [{ id: 7, nombre: "Proveedor" }, { id: 8, nombre: "Codigos" }]
    }
    this.eventoTipo = undefined
  }

  subData: any = {}
  /**
   * Verifica la información después de que cambia el valor del MatSelect de subtipo.
   * @param eve El evento de cambio de valor en el MatSelect de subtipo.
   */
  checkInfoData(eve: MatSelectChange) {
    this.array = []
    this.informe = {}
    this.subData = eve.source.value
    this.loading = true
    this.informeSubtipo = eve.source.value.nombre;
    if (eve.source.value.id == 1 || eve.source.value.id == 2 || eve.source.value.id == 3) {
      this.informeTipo = "Costes";
      this.getInformesCostes(eve.source.value.id);
    } else if (eve.source.value.id == 4 || eve.source.value.id == 5 || eve.source.value.id == 6) {
      this.informeTipo = "Presupuesto";
      this.getInformesPresus(eve.source.value.id);
    } else if (eve.source.value.id == 7 || eve.source.value.id == 8 || eve.source.value.id == 9) {
      this.informeTipo = "Facturas";
      this.getInformesFacturas(eve.source.value.id);
    }
    this.informeFecha = this.getCurrentDateTime();
    this.eventoSubTipo = undefined
  }
  /**
   * Obtiene los informes de costes según el ID proporcionado.
   * @param id El ID del tipo de informe de costes.
   */
  getInformesCostes(id: number) {
    this.presupuestoService.getControlByProyectoId(this.proyecto.id + "").subscribe({
      next: (data) => {
        data = data.sort((a: any, b: any) => (parseInt(a.codigo.cuenta) > parseInt(b.codigo.cuenta)) ? 1 : -1);

        if (id == 1) {
          this.getByCapitulo(data)
        } else if (id == 2) {
          this.getBySubCapitulo(data)
        } else if (id == 3) {
          this.getByCodigo(data)
        }
        this.calcularTotalesCostesConceptos()
        this.loading = false

      },
      error(err) {
        console.log("Error: ", err);
      },
    })
  }
  /**
   * Obtiene los informes de presupuestos según el ID proporcionado.
   * @param id El ID del tipo de informe de presu.
   */
  getInformesPresus(id: number) {
    this.presupuestoService.getPresupuestosByProyectoId(this.proyecto.id + "").subscribe({
      next: (data) => {
        data = data.sort((a: any, b: any) => (parseInt(a.codigo.cuenta) > parseInt(b.codigo.cuenta)) ? 1 : -1);

        if (id == 4) {
          this.getByCapitulo(data)
        } else if (id == 5) {
          this.getBySubCapitulo(data)
        } else if (id == 6) {
          this.getByCodigo(data)
        }
        this.calcularTotalesPresuConceptos()
        this.loading = false
      },
      error(err) {
        console.log("Error: ", err);
      },
    })
  }

  /**
   * Obtiene los informes de facturas según el ID proporcionado.
   * @param id El ID del tipo de informe de factura.
   */
  getInformesFacturas(id: number) {
    this.filter.filters = this.filters
    this.gastoService.getRegistros(this.filter).subscribe({
      next: (data: any) => {
        data = data.source.sort((a: any, b: any) => (parseInt(a.conceptos[0].codigo.cuenta) > parseInt(b.conceptos[0].codigo.cuenta)) ? 1 : -1);

        if (id == 7) {
          data = data.filter((a: any) => a.proveedor);
          this.getByProveedor(data)
        } else if (id == 8) {
          this.getFactByCodigo(data)
        }
        this.calcularTotalesFact()
        this.loading = false
      }
    })
  }

  /**
   * Prepara los datos para mostrar los informes por proveedor.
   *
   * @param data Los datos de los informes.
   */
  getByProveedor(data: any) {
    this.array = []
    this.informe = {}
    data.forEach((fact: any) => {
      const nombreSubcapitulo = fact.proveedor.nombreFiscal;

      if (!this.informe[nombreSubcapitulo]) {
        this.informe[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informe[nombreSubcapitulo].push(fact);
    });
    console.error("Facts by Proveedor ", this.informe);
    this.array = Object.keys(this.informe)

  }
  /**
   * Prepara los datos para mostrar los informes por código.
   * 
   * @param data Los datos de los informes.
   */
  getFactByCodigo(data: any) {
    this.array = []
    this.informe = {}

    data.forEach((fact: any) => {
      const nombreSubcapitulo = fact.conceptos[0].codigo.cuenta + this.checkTipo(fact);

      if (!this.informe[nombreSubcapitulo]) {
        this.informe[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informe[nombreSubcapitulo].push(fact);
    });
    console.error("Facts by Proveedor ", this.informe);
    this.array = Object.keys(this.informe)

  }

  checkTipo(element: any): any {
    console.log("aaaaa",element.etiquetas);
    
    if(!element.etiquetas){
      return ""
    }else{
      var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
      if (etiqueta && etiqueta.descripcion) {
        return " - " + etiqueta.descripcion
      } else {
        return ""
      }
    }
  }
  
  getConcepto(element: any): string {
    var etiqueta = element.etiquetas?.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }
  /**
   * Prepara los datos para mostrar los informes por código.
   * 
   * @param data Los datos de los informes.
   */
  getByCodigo(data: any) {

    console.log("data",
      data.sort
    );
    data = data.sort((a: any, b: any) => a.id - b.id);
    console.log("data",
      data.sort
    );
    this.array = []
    this.informe = {}
    console.log("una data de codigo", data);

    data.forEach((fact: any) => {

      let filterPipe = new CodigosCuentasPipe()
      console.log();

      const nombreSubcapitulo = (filterPipe.transform(fact.codigo.cuenta + "" + (fact.codigo.codigoHijo ? fact.codigo.codigoHijo : ""))) + " - " + this.getConcepto(fact.codigo);

      if (!this.informe[nombreSubcapitulo]) {
        this.informe[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informe[nombreSubcapitulo].push(fact);
    });
    console.error("Presupuestos por codigo: ", this.informe);
    this.array = Object.keys(this.informe)
    console.log(this.array);

    this.informeSubCapAux = {}

    data.forEach((fact: any) => {
      let filterPipe = new CodigosCuentasPipe()

      const nombreSubcapitulo = filterPipe.transform(fact.codigo.subcapitulo.codigo) + " - " + fact.codigo.subcapitulo.nombre;

      if (! this.informeSubCapAux[nombreSubcapitulo]) {
        this.informeSubCapAux[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informeSubCapAux[nombreSubcapitulo].push(fact);
    });

    console.log( this.informeSubCapAux);
     
    this.informeCapAux = {}
    data.forEach((fact: any) => {
      const nombreSubcapitulo = fact.codigo.capitulo.nombre;

      if (!this.informeCapAux[nombreSubcapitulo]) {
        this.informeCapAux[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informeCapAux[nombreSubcapitulo].push(fact);
    });

  }

  /**
   * Prepara los datos para mostrar los informes por capítulo.
   * 
   * @param data Los datos de los informes.
   */
  getByCapitulo(data: any) {
    this.array = []
    this.informe = {}
    data.forEach((fact: any) => {
      const nombreSubcapitulo = fact.codigo.capitulo.nombre;

      if (!this.informe[nombreSubcapitulo]) {
        this.informe[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informe[nombreSubcapitulo].push(fact);
    });
    console.error("Presupuestos por : ", this.informe);
    this.array = Object.keys(this.informe)

  }

  /**
   * Prepara los datos para mostrar los informes por subcapítulo.
   * 
   * @param data Los datos de los informes.
   */
  getBySubCapitulo(data: any) {
    this.array = []
    this.informe = {}
    let filterPipe = new CodigosCuentasPipe()

    data.forEach((fact: any) => {
      const nombreSubcapitulo = filterPipe.transform(fact.codigo.subcapitulo.codigo) + " - " + fact.codigo.subcapitulo.nombre;

      if (!this.informe[nombreSubcapitulo]) {
        this.informe[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informe[nombreSubcapitulo].push(fact);
    });
    console.error("Presupuestos por subcapitulo: ", this.informe);
    this.array = Object.keys(this.informe)

    console.error("Presupuestos por subcapitulo: this.array ", this.array);

    this.informeCapAux = {}
    data.forEach((fact: any) => {
      const nombreSubcapitulo = fact.codigo.capitulo.nombre;

      if (!this.informeCapAux[nombreSubcapitulo]) {
        this.informeCapAux[nombreSubcapitulo] = [];
      }

      fact.index = data.indexOf(fact)
      this.informeCapAux[nombreSubcapitulo].push(fact);
    });
    console.log("informeCapAux", this.informeCapAux);

  }
  /**
   * Regresa a la página anterior.
   */
  goBack() {
    this.raiz.goBack()
  }
  chargeDoc = false

  /**
   * Genera un PDF del componente proporcionado.
   * 
   * @param component El componente del que se generará el PDF.
   */
  async generarPDF(component: any) {
    this.export = false
    this.chargeDoc = true
    const componente = new ElementRef(component);
    const cabecera = componente.nativeElement.querySelector('#cabecera-informe');
    const datos = componente.nativeElement.querySelector('#datos-informe');
    try {
      await this.pdfService.exportToPdf(cabecera, datos, "Informe:_" + this.proyecto.titulo + "_" + this.subData.nombre + ".pdf");
    } catch {
      this.chargeDoc = false

    }
    this.chargeDoc = false

  }


  /* 
  Calculos costes
  */
  calcularTotalCostesCuentas(a: any, index: number) {
    var total = 0
    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.presupuestoAprobado//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.presupuestoAprobado//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.presupuestoAprobado//TODO
      })
    }
    return total
  }
  calcularPresuCosteCuentas(a: any, index: number) {
    var total = 0
   
    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.presupuestoAprobado//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.presupuestoAprobado//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.presupuestoAprobado//TODO
      })
    }
    return total
  }
  calcularOCCosteCuentas(a: any, index: number) {
    var total = 0
    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.saldoOrdenCompra//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.saldoOrdenCompra//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.saldoOrdenCompra//TODO
      })
    }
    return total
  }
  calcularCosteCosteCuentas(a: any, index: number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.costeActual//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.costeActual//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.costeActual//TODO
      })
    }
    return total
  }
  calcularPrevistoCosteCuentas(a: any, index: number) {
    var total = 0
  
    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.presupuestoEstimado//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.presupuestoEstimado//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.presupuestoEstimado//TODO
      })
    }
    return total
  }
  calcularProyeccionCierreCosteCuentas(a: any, index: number) {
    var total = 0
   
    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.proyeccionCierre//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.proyeccionCierre//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.proyeccionCierre//TODO
      })
    }
    return total
  }
  calcularDesviacionCosteCuentas(a: any, index: number) {
    var total = 0
  
    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.desviacionActual//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.desviacionActual//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.desviacionActual//TODO
      })
    }
    return total
  }
  totalCuentas: any;
  presuCuentas: any;
  costeCuentas: any;
  oCCuentas: any;
  previstoCuentas: any;
  proyeccionCierreCuentas: any;
  desviacionCuentas: any;
  calcularTotalesCostesConceptos() {
    this.totalCuentas = 0
    this.presuCuentas = 0
    this.costeCuentas = 0
    this.oCCuentas = 0
    this.previstoCuentas = 0
    this.proyeccionCierreCuentas = 0
    this.desviacionCuentas = 0

    this.array.forEach((element: any) => {

      this.totalCuentas = this.totalCuentas + this.calcularTotalCostesCuentas(element, 0)
      this.presuCuentas = this.presuCuentas + this.calcularPresuCosteCuentas(element,0)
      this.costeCuentas = this.costeCuentas + this.calcularCosteCosteCuentas(element,0)
      this.oCCuentas = this.oCCuentas + this.calcularOCCosteCuentas(element,0)
      this.previstoCuentas = this.previstoCuentas + this.calcularPrevistoCosteCuentas(element,0)
      this.proyeccionCierreCuentas = this.proyeccionCierreCuentas + this.calcularProyeccionCierreCosteCuentas(element,0)
      this.desviacionCuentas = this.desviacionCuentas + this.calcularDesviacionCosteCuentas(element,0)

    });
  }


  /* 
  Metodos calculos presu
  */

  calcularPresuPCuentas(a: any,index:number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.semanasPreparacion//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.semanasPreparacion//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.semanasPreparacion//TODO
      })
    }
    return total
  }
  calcularPresuRCuentas(a: any,index:number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.semanasRodaje//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.semanasRodaje//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.semanasRodaje//TODO
      })
    }
    return total
  }
  calcularPresuCCuentas(a: any,index:number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.semanasCierre//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.semanasCierre//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.semanasCierre//TODO
      })
    }
    return total
  }
  calcularPresuTUnidCuentas(a: any,index:number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.totalSemanas//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.totalSemanas//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.totalSemanas//TODO
      })
    }
    return total
  }
  calcularPresuCantidadCuentas(a: any,index:number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.cantidadUnidad//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.cantidadUnidad//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.cantidadUnidad//TODO
      })
    }
    return total
  }
  calcularPresuTotalCuentas(a: any,index:number) {
    var total = 0

    if (index == 0) {

      this.informe[a].forEach((data: any) => {
        total = total + data.totalPresupuesto//TODO
      })
    } else if (index == 1) {

      this.informeSubCapAux[a].forEach((data: any) => {
        total = total + data.totalPresupuesto//TODO
      })
    }
    else if (index == 2) {

      this.informeCapAux[a].forEach((data: any) => {
        total = total + data.totalPresupuesto//TODO
      })
    }
    return total
  }

  semanasP: any;
  semanasR: any;
  semanasC: any;
  totalUnid: any;
  cantidadPresu: any;
  totalPresu: any;
  calcularTotalesPresuConceptos() {
    this.semanasP = 0
    this.semanasR = 0
    this.semanasC = 0
    this.totalUnid = 0
    this.cantidadPresu = 0
    this.totalPresu = 0

    this.array.forEach((element: any) => {

      this.semanasP = this.semanasP + this.calcularPresuPCuentas(element,0)
      this.semanasR = this.semanasR + this.calcularPresuRCuentas(element,0)
      this.semanasC = this.semanasC + this.calcularPresuCCuentas(element,0)
      this.totalUnid = this.totalUnid + this.calcularPresuTUnidCuentas(element,0)
      this.cantidadPresu = this.cantidadPresu + this.calcularPresuCantidadCuentas(element,0)
      this.totalPresu = this.totalPresu + this.calcularPresuTotalCuentas(element,0)

    });
  }

  /* 
  Calculos facturas
  */
  calcularFactBase(a: any) {
    var total = 0
    this.informe[a].forEach((data: any) => {
      total = total + data.base
    })
    return total
  }
  calcularFactIva(a: any) {
    var total = 0
    this.informe[a].forEach((data: any) => {
      total = total + data.iva
    })
    return total
  }
  calcularFactIrpf(a: any) {
    var total = 0
    this.informe[a].forEach((data: any) => {
      total = total + data.irpf
    })
    return total
  }
  calcularFactTotal(a: any) {
    var total = 0
    this.informe[a].forEach((data: any) => {
      total = total + data.total_pagado?data.total_pagado:0  + data.total
    })
    return total
  }

  factBaseTot: any;
  factIvaTot: any;
  factIrpfTot: any;
  factTotalTot: any;

  calcularTotalesFact() {
    this.factBaseTot = 0
    this.factIvaTot = 0
    this.factIrpfTot = 0
    this.factTotalTot = 0

    this.array.forEach((element: any) => {

      this.factBaseTot = this.factBaseTot + this.calcularFactBase(element)
      this.factIvaTot = this.factIvaTot + this.calcularFactIva(element)
      this.factIrpfTot = this.factIrpfTot + this.calcularFactIrpf(element)
      this.factTotalTot = this.factTotalTot + this.calcularFactTotal(element)

    });
  }

  checkCap(a: any, index: number) {

    var keys = Object.keys(this.informe)

    if (this.subData.id != 1 && this.subData.id != 4) {
      if(index==0){
        return true
      }
      else if (keys[index].split(".")[0] != keys[index - 1].split(".")[0]) {
        return true
      }
    }
    return false
  }

  checkSubCap(a: any, index: number) {
    var keys = Object.keys(this.informe)

    if (this.subData.id != 1 && this.subData.id != 4 && this.subData.id != 2 && this.subData.id != 5) {
      if(index==0){
        return true
      }
      else if (keys[index].split(".")[1] != keys[index - 1].split(".")[1]) {
        return true
      }
    }
    return false
  }

  getCurrentDateTime(): string {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
}

/* async generarPDF(component: any) {
    const componente = new ElementRef(component);

    const boundingRect = componente.nativeElement.getBoundingClientRect();
    console.log(boundingRect);
    
    // Captura el contenido del componente utilizando html2canvas
    const canvas = await html2canvas(componente.nativeElement, {
      width: boundingRect.width,
      height: boundingRect.height,
    });

    // Crea un nuevo documento PDF
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: [boundingRect.width, boundingRect.height],
    });

    // Convierte el lienzo a una imagen y agrega la imagen al PDF
    const imgData = canvas.toDataURL('image/jpeg', 1.0);
    const imgWidth = 210;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    let position = 0;

    // Agrega páginas al PDF hasta que se haya capturado todo el contenido
    while (position < canvas.height) {
      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      position -= 297;
      if (position < canvas.height) {
        doc.addPage();
      }
    }

    // Guarda el PDF
  
  } */

