<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm" [(action)]="action" [(isImport)]="isForImport"
    [canImport]="!isReading&&isControllerOrDirProd&&disabeImport" [canEdit]="!isReading&&isControllerOrDirProd"
    [canCreate]="isControllerOrDirProd" [canExport]="canExport"></app-header-listados>

<div>

    <button *ngIf="action?setAction(action):''"></button>
    <button *ngIf="isSave?onSubmit():''" [hidden]="this.isReading && !isControllerOrDirProd"></button>
    <button *ngIf="isClearForm?export():''"></button>

    <!-- <div class="contenedor-boton1">
        <h6 class="mih6">Total presupuesto </h6>
        <p class="mostrar pointer" (click)="verDetalle()">Mostrar totales presupuesto / </p>
        <p class="ocultar pointer" (click)="verDetalle()">Ocultar</p>
    </div> -->

    <!--  <div class="text-buttons mar-presu" [ngClass]="{'text-buttons2': this.isPresupuestoTotalExpanded != 'in'}">
 --><!--         <div class="contenedor-boton1">

        <p (click)="this.isPresupuestoTotalExpanded = this.isPresupuestoTotalExpanded === 'out' ? 'in' : 'out'"
            class="mostrar pointer" [ngClass]="{'close':this.isPresupuestoTotalExpanded === 'in'}">Mostrar
            Totales
        </p>
        <p (click)="this.isPresupuestoTotalExpanded = this.isPresupuestoTotalExpanded === 'out' ? 'in' : 'out'"
            class="mostrar pointer">/</p>
        <p (click)="this.isPresupuestoTotalExpanded = this.isPresupuestoTotalExpanded === 'out' ? 'in' : 'out'"
            class="ocultar pointer" [ngClass]="{'close':this.isPresupuestoTotalExpanded === 'out'}">Ocultar</p>
        
        </div>
    </div> -->
    <div class="contenedor-boton1">
        <h6 class="mih6">Total presupuesto </h6>

        <p (click)="this.isPresupuestoTotalExpanded = this.isPresupuestoTotalExpanded === 'out' ? 'in' : 'out'"
            class="mostrar pointer">Mostrar totales / </p>
        <p (click)="this.isPresupuestoTotalExpanded = this.isPresupuestoTotalExpanded === 'out' ? 'in' : 'out'"
            class="ocultar pointer">Ocultar</p>
    </div>

    <div [@slideInOut]="isPresupuestoTotalExpanded" [@.disabled]="true" class="scrollable-div">
        <div class="content">

            <div class="form-surrouded">
                <div class="cardsblueIni">
                    <p class="cardtext">Presupuesto</p>
                    <p class="cardtextbig" [ngClass]="totalPresupuesto<0?'letra-red':''">
                        {{totalPresupuesto?(totalPresupuesto|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue  ">
                    <p class="cardtext">Desviación</p>
                    <p class="cardtextbig" [ngClass]="totalDesviacion<0?'letra-red':''">
                        {{totalDesviacion?(totalDesviacion|numberEsp):'0'}} €</p>
                </div>
                <div class="cardsblue">
                    <p class="cardtext">Presupuesto estimado</p>
                    <p class="cardtextbig" [ngClass]="totalEstimado<0?'letra-red':''">
                        {{totalEstimado?(totalEstimado|numberEsp):'0'}} €</p>
                </div>
            </div>
        </div>
    </div>

    <div class="table_container">
        <label class="titulosPaginas"> PRESUPUESTOS </label>
        <p class="mostrar pointer" (click)="panelsOpen=true">Mostrar Conceptos / </p>
        <p class="ocultar pointer" (click)="panelsOpen=false">Ocultar</p>
    </div>

    <div style="overflow-y: hidden;">
        <div class="fondoblanco">
            <!-- <div class="contenedor-principal">
                <div class="contenedor-principal-izq">
                    <div appearance="outline" class="contenedor-boton3">
                         <mat-form-field appearance="outline" class="w8 formField">
                            <mat-select placeholder="Mostrar 10" disabled="true">
                                <mat-option value="10">Mostrar 10</mat-option>
                                <mat-option value="25">Mostrar 25</mat-option>
                                <mat-option value="50">Mostrar 50</mat-option>
                                <mat-option value="100">Mostrar 100</mat-option>
                                <mat-option value="150">Mostrar 150</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="buscadorHeader formField">
                            <input disabled="true" matInput placeholder="Filtrar todo:">
                        </mat-form-field>
                    </div>
                    <div class="contenedor-boton2">
                        <button *ngIf="isClearForm?export():''">
                        </button>
                        <button class="buttonsAccions buttonSquaredWhiteright col-5" (click)="(isForImport = true)"
                            [disabled]="this.isReading">Importar</button>
                    </div>
                </div>

                <div class="contenedorButtonPres">
                    <button *ngIf="isSave?onSubmit():''" [disabled]="this.isReading && !isControllerOrDirProd"></button>
                    <button *ngIf="action?setAction(action):''"></button>


                    <mat-form-field class="select-button">
                        <mat-select [(value)]="opcionSeleccionada" (selectionChange)="setAction($event)" hidden>
                            <mat-option value="cerrarPresupuesto">Cerrar presupuesto</mat-option>
                            <mat-option value="validarCambios">Validar cambios</mat-option>
                            <mat-option value="eliminarPresupuesto">Eliminar presupuesto</mat-option>
                            <mat-option value="pasarACastflow">Pasar presupuesto a CashFlow</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>

                </div>
            </div> -->


            <div class="form-surrouded-presup">
                <div class="rowForm tabla">
                    <form class="col-12" [formGroup]="form" (change)="onFormChange()">

                        <div class="col-12 data_table" formArrayName="codigos">

                            <div class="col-12 formMaterial  d-flex headerPresuList" style="min-width: 1300px">
                                <div class="col-12 d-flex justify-content-between">
                                    <label class="uppercase col-1 descp">Cuenta</label>
                                    <label class="uppercase col-2 descp">Descripción</label>
                                    <label class="uppercase col-1 centro">Nombre</label>
                                    <label class="uppercase tipoWith centro">Tipo</label>
                                    <label class="uppercase semanas centro">P</label>
                                    <label class="uppercase semanas centro">R</label>
                                    <label class="uppercase semanas centro">C</label>
                                    <label class="uppercase semanas centro">Total</label>
                                    <label class="uppercase cantUnit centro text-center">Cant. Unit</label>
                                    <!-- <label class="uppercase col-1 centro">Fin de Obra</label> -->
                                    <label class="uppercase withTotEstDev centro text-right">Total Pres.</label>
                                    <label class="uppercase withTotEstDev centro text-right">Desviación</label>
                                    <label class="uppercase withTotEstDev centro text-right">Pres. estimado</label>

                                </div>
                            </div>


                            <div *ngFor="let cap of arrayCaps" class="w-100 pointer" style="min-width: 1300px">
                                <mat-expansion-panel [expanded]="panelsOpen" (opened)="isOpened = true"
                                    (closed)="isOpened = false" [@.disabled]="true">

                                    <mat-expansion-panel-header class="specific-class color-head-cap"
                                        [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
                                        <mat-panel-title class="d-flex col-12 justify-content-between">
                                            <p class="let-head fontCapitulos col-3">{{cap}}</p>
                                            <label class="col-1"></label>
                                            <label class="with_sinSemanas"></label>
                                            <label class="semanas2"></label>
                                            <label class="semanas2"></label>
                                            <label class="semanas pad_sem"
                                                style="padding-left: 1rem;"></label>
                                            <label class="cantUnit pad_sem2 numberInput"></label>

                                            <!--  <label
                                                [ngClass]="calcTotalSemanasP(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="semanas">{{(calcTotalSemanasP(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalSemanasP(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                :
                                                ''}}</label>

                                            <label
                                                [ngClass]="calcTotalSemanasR(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="semanas2">{{(calcTotalSemanasR(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalSemanasR(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                :
                                                ''}}</label>

                                            <label
                                                [ngClass]="calcTotalSemanasC(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="semanas2">{{(calcTotalSemanasC(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalSemanasC(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                :''}}</label>

                                            <label
                                                [ngClass]="calcTotalSemanasTotales(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="semanas pad_sem"
                                                style="padding-left: 1rem;">{{(calcTotalSemanasTotales(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalSemanasTotales(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp):''}}</label>

                                            <label
                                                [ngClass]="calcCantUnit(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="col-1 pad_sem2 numberInput">{{(calcCantUnit(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcCantUnit(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)+'€':''}}</label> -->

                                            <label
                                                [ngClass]="calcTotalPresupuesto(getArrayByCap(this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="withTotEstDev fontCapitulos numberInput pad_sem2 pad_cap">{{(calcTotalPresupuesto(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalPresupuesto(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)+'€':''}}</label>

                                            <label
                                                [ngClass]="calcTotalDesviacion(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="withTotEstDev fontCapitulos numberInput pad_sem2 pad_cap">{{(calcTotalDesviacion(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcTotalDesviacion(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)+'€':''}}</label>

                                            <label
                                                [ngClass]="calcPresEstimado(getArrayByCap( this.presupuestosPorCapitulo[cap]))<0?'letra-red':''"
                                                class="withTotEstDev fontCapitulos pad_sem2  numberInput"
                                                style="padding-left: 1.5rem;">{{(calcPresEstimado(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)
                                                ?(calcPresEstimado(getArrayByCap(
                                                this.presupuestosPorCapitulo[cap]))|numberEsp)+'€':''}}</label>

                                        </mat-panel-title>
                                    </mat-expansion-panel-header>


                                    <div class="col-12">
                                        <span *ngFor="let subcap of this.array">

                                            <span
                                                *ngIf="this.presupuestosPorCapitulo[cap] && this.presupuestosPorCapitulo[cap][subcap]">


                                                <mat-expansion-panel [expanded]="panelsOpen" (opened)="isOpened = true"
                                                    (closed)="isOpened = false" [@.disabled]="true">
                                                    <!-- <mat-expansion-panel (opened)="isOpened = true" (closed)="isOpened = false" style="background-color: #c7d5f9;"> -->
                                                    <mat-expansion-panel-header class="specific-class color-head"
                                                        [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
                                                        <mat-panel-title class="d-flex col-12 justify-content-between">
                                                            <!-- <mat-panel-title class="d-flex justify-content-left col-12"> -->
                                                            <!-- <p class="let-head col-1">{{a.split(",")[0]| codigosCuentas}}</p> -->
                                                            <label
                                                                class=" col-1 let-head fontCapitulos">{{subcap.split(",")[0]|
                                                                codigosCuentas}}</label>
                                                            <label
                                                                class=" col-2 let-head fontCapitulos">{{subcap.split(",")[1]+""}}</label>
                                                            <!-- <p class="let-head col-3">{{a.split(",")[1]+""}}</p> -->

                                                            <label class="col-1"></label>
                                                            <label class="with_sinSemanas"></label>
                                                            <label class="semanas2"></label>
                                                            <label class="semanas2"></label>
                                                            <label class="semanas pad_sem"
                                                                style="padding-left: 1rem;"></label>
                                                            <label class="cantUnit pad_sem2 numberInput"></label>
                                                            <!--    <label
                                                                [ngClass]="calcTotalSemanasP(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="semanas">{{(calcTotalSemanasP(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalSemanasP(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                :
                                                                ''}}</label>

                                                            <label
                                                                [ngClass]="calcTotalSemanasR(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="semanas2">{{(calcTotalSemanasR(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalSemanasR(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                :
                                                                ''}}</label>

                                                            <label
                                                                [ngClass]="calcTotalSemanasC(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="semanas2">{{(calcTotalSemanasC(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalSemanasC(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                :''}}</label>

                                                            <label
                                                                [ngClass]="calcTotalSemanasTotales(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="semanas pad_sem"
                                                                style="padding-left: 1rem;">{{(calcTotalSemanasTotales(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalSemanasTotales(this.presupuestosPorSubcapitulo[subcap])|numberEsp):''}}</label>

                                                            <label
                                                                [ngClass]="calcCantUnit(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="col-1 pad_sem2 numberInput">{{(calcCantUnit(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcCantUnit(this.presupuestosPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label> -->

                                                            <label
                                                                [ngClass]="calcTotalPresupuesto(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="withTotEstDev fontCapitulos numberInput pad_sem2">{{(calcTotalPresupuesto(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalPresupuesto(this.presupuestosPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>

                                                            <label
                                                                [ngClass]="calcTotalDesviacion(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="withTotEstDev fontCapitulos numberInput pad_sem2">{{(calcTotalDesviacion(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcTotalDesviacion(this.presupuestosPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>

                                                            <label
                                                                [ngClass]="calcPresEstimado(this.presupuestosPorSubcapitulo[subcap])<0?'letra-red':''"
                                                                class="withTotEstDev fontCapitulos pad_sem2 numberInput"
                                                                style="padding-left: 1.5rem;">{{(calcPresEstimado(this.presupuestosPorSubcapitulo[subcap])|numberEsp)
                                                                ?(calcPresEstimado(this.presupuestosPorSubcapitulo[subcap])|numberEsp)+'€':''}}</label>
                                                        </mat-panel-title>
                                                    </mat-expansion-panel-header>


                                                    <div class="tam col-12"
                                                        *ngFor="let codigo of this.presupuestosPorCapitulo[cap][subcap]">

                                                        <form [formGroupName]=" codigo.index"
                                                            class="col-12 adaptacionPadding">
                                                            <div class="col-12 formMaterial">
                                                                <div class="col-12 d-flex justify-content-between">
                                                                    <label class="col-1 let-head">{{codigo.codigo.cuenta
                                                                        +""+(codigo.codigo.codigoHijo?codigo.codigo.codigoHijo:"")
                                                                        |
                                                                        codigosCuentas}}</label>
                                                                    <label class="col-2 two-line-limit"
                                                                        [matTooltip]="mostrarTooltip(codigo.codigo) ? getConcepto(codigo.codigo) : ''"
                                                                        matTooltipClass="tooltip-class">{{
                                                                        getConcepto(codigo.codigo) }}</label>
                                                                    <div class="col-1 pad">
                                                                        <input class="searchinput"
                                                                            formControlName="nombre">
                                                                    </div>

                                                                    <div class="tipoWith">
                                                                        <select class="selectPres"
                                                                            placeholder="Tipo Unidad..."
                                                                            formControlName="tipoUnidad"
                                                                            [compareWith]="compararObjetos"
                                                                            [ngClass]="isReading || isProductor?'desactivado':''"
                                                                            [disabled]="isReading">
                                                                            <option *ngFor="let tipo of tipoUnidades"
                                                                                [value]="tipo.id">
                                                                                <span class="columnas">
                                                                                    {{tipo.nombre}}</span>
                                                                            </option>
                                                                        </select>

                                                                    </div>

                                                                    <div class="semanas">
                                                                        <input class="searchinput text-center"
                                                                            formControlName="semanasPreparacion"
                                                                            type="text" mask="separator.2"
                                                                            thousandSeparator="."
                                                                            (focus)="clearIfZero(codigos.controls[codigo.index],'semanasPreparacion')"
                                                                            (blur)="restoreIfEmpty(codigos.controls[codigo.index],'semanasPreparacion')"
                                                                            (keyup)="changeDec($event,codigos.controls[codigo.index].get('semanasPreparacion'));"
                                                                            decimalMarker=",">
                                                                    </div>
                                                                    <div class="semanas2">
                                                                        <input class="searchinput text-center"
                                                                            formControlName="semanasRodaje" type="text"
                                                                            mask="separator.2" thousandSeparator="."
                                                                            (focus)="clearIfZero(codigos.controls[codigo.index],'semanasRodaje')"
                                                                            (blur)="restoreIfEmpty(codigos.controls[codigo.index],'semanasRodaje')"
                                                                            (keyup)="changeDec($event,codigos.controls[codigo.index].get('semanasRodaje'));"
                                                                            decimalMarker=",">
                                                                    </div>
                                                                    <div class="semanas2">
                                                                        <input class="searchinput text-center"
                                                                            formControlName="semanasCierre" type="text"
                                                                            mask="separator.2" thousandSeparator="."
                                                                            (focus)="clearIfZero(codigos.controls[codigo.index],'semanasCierre')"
                                                                            (blur)="restoreIfEmpty(codigos.controls[codigo.index],'semanasCierre')"
                                                                            (keyup)="changeDec($event,codigos.controls[codigo.index].get('semanasCierre'));"
                                                                            decimalMarker=",">
                                                                    </div>
                                                                    <label
                                                                        class="semanas pad_sem text-center">{{getTotalSem(codigos.controls[codigo.index])?getTotalSem(codigos.controls[codigo.index]):''}}</label>
                                                                    <div class="cantUnit pad_sem2">
                                                                        <input class="searchinput numberInput "
                                                                            formControlName="cantidadUnidad" type="text"
                                                                            mask="separator.2" thousandSeparator="."
                                                                            suffix="€"
                                                                            (focus)="clearIfZero(codigos.controls[codigo.index],'cantidadUnidad')"
                                                                            (blur)="restoreIfEmpty(codigos.controls[codigo.index],'cantidadUnidad')"
                                                                            (keyup)="changeDec($event,codigos.controls[codigo.index].get('cantidadUnidad'));"
                                                                            decimalMarker=",">
                                                                    </div>
                                                                    <!-- <div class="col-1 pad_sem2"> -->
                                                                    <!-- <input class="form-control centro" formControlName="finObra"
                                                    type="number"> -->
                                                                    <!-- <input type="text" mask="separator.2" thousandSeparator="." (keyup)="changeDec($event,codigos.controls[codigo.index].get('finObra'));" decimalMarker=","  formControlName="finObra"
                                                            class="searchinput numberInput">
                                                    </div> -->
                                                                    <label
                                                                        class="withTotEstDev numberInput fontCapitulos pad_sem2">{{getTotalPresupuesto(codigos.controls[codigo.index])?(getTotalPresupuesto(codigos.controls[codigo.index])|numberEsp)+'€':'0,00€'}}</label>

                                                                    <div class="withTotEstDev pad_sem2">
                                                                        <input class="searchinput numberInput"
                                                                            formControlName="desviacion"
                                                                            [ngClass]="codigos.controls[codigo.index].get('desviacion')?.value<0?'letra-red':''"
                                                                            type="text" mask="separator.2"
                                                                            thousandSeparator="." suffix="€"
                                                                            [allowNegativeNumbers]="true"
                                                                            (keyup)="changeDec($event,codigos.controls[codigo.index].get('desviacion'));"
                                                                            decimalMarker=",">
                                                                    </div>

                                                                    <!-- <div class="withTotEstDev pad_sem2">
                                                                        <input class="searchinput numberInput"  
                                                                            formControlName="presupuestoEstimado" [ngClass]="codigos.controls[codigo.index].get('presupuestoEstimado')?.value<0?'letra-red':''"
                                                                            type="text" mask="separator.2"
                                                                            thousandSeparator="." suffix="€" [allowNegativeNumbers]="true"
                                                                            (keyup)="changeDec($event,codigos.controls[codigo.index].get('presupuestoEstimado'));"
                                                                            decimalMarker=",">
                                                                    </div> -->
                                                                    <!-- <label [ngClass]="getDesviacion(codigos.controls[codigo.index])<0?'letra-red':''"
                                                                        class="withTotEstDev numberInput pad_sem2">{{getDesviacion(codigos.controls[codigo.index])?(getDesviacion(codigos.controls[codigo.index])|numberEsp)+'€':getTotalPresupuesto(codigos.controls[codigo.index])?(getTotalPresupuesto(codigos.controls[codigo.index])|numberEsp)+'€':'0,00€'}}</label> -->
                                                                    <label
                                                                        [ngClass]="getPresupuestoEstimado(codigos.controls[codigo.index])<0?'letra-red':''"
                                                                        class="withTotEstDev numberInput pad_sem2">{{(getPresupuestoEstimado(codigos.controls[codigo.index])|numberEsp)
                                                                        ?(getPresupuestoEstimado(codigos.controls[codigo.index])|numberEsp)+'€':''}}</label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </mat-expansion-panel>
                                            </span>
                                        </span>

                                    </div>
                                </mat-expansion-panel>
                            </div>
                            <div class="dialog-container" *ngIf="modalPresupuestoClosed">
                                <div class="dialog-content">
                                    <div class="contenedortextos">
                                        <div class="circle_document">
                                            <a class="document-div"></a>
                                        </div>
                                    </div>
                                    <div class="contenedortextos">
                                        <h4 class="colorh4">Va a cerrar un presupuesto</h4>
                                    </div>
                                    <div class="contenedortextos2">
                                        <p>¿Está seguro de que desea realizar esta acción? <br> Despues solo se podra
                                            modificar el presupuesto estimado</p>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <button (click)="(modalPresupuestoClosed= false)" id="btcancel"
                                            class="btn btn-primary mx-1 ">Cancelar</button>
                                        <button id="btacept" class="btn btn-primary ml-1">
                                            <div></div>
                                            <span (click)="closePresupuesto(); (modalPresupuestoClosed= false)"
                                                class="align-middle"> Confirmar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="dialog-container" *ngIf="modalPresupuestoDelete">
                                <div class="dialog-content">
                                    <div class="contenedortextos">
                                        <div class="circle_document">
                                            <a class="document-div"></a>
                                        </div>
                                    </div>
                                    <div class="contenedortextos">
                                        <h4 class="colorh4">Va a eliminar un presupuesto</h4>
                                    </div>
                                    <div class="contenedortextos2">
                                        <p>¿Está seguro de que desea realizar esta acción?</p>
                                    </div>

                                    <div class="d-flex justify-content-end">
                                        <button (click)="(modalPresupuestoDelete= false)" id="btcancel"
                                            class="btn btn-primary mx-1 ">Cancelar</button>
                                        <button id="btacept" class="btn btn-primary ml-1">
                                            <div></div>
                                            <span (click)="deletePresupesto(); (modalPresupuestoDelete= false)"
                                                class="align-middle"> Confirmar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--   <div class="col-12 cuadroBotones">
                            <button class="btn buttonPrimary" (click)="onSubmit()">
                                Guardar presupuesto
                            </button>
                        </div> -->
                    </form>
                </div>
                <div class="dialog-container" *ngIf="isForImport">
                    <div class="dialog-content" style="width: 500px;">
                        <div class="contenedortextos">
                            <div class="circle_document">
                                <a class="document-div"></a>
                            </div>
                        </div>
                        <div class="contenedortextos">
                            <h4 class="colorh4">Va a importar un presupuesto</h4>
                        </div>
                        <div class="contenedortextos2">
                            <p>Para importar un presupuesto, primero será necesario descargar la plantilla que se
                                proporciona. Los datos deben estar todos rellenos, sin errores y sin símbolos, ya que
                                sino la
                                importación no se realizará con éxito</p>
                        </div>

                        <div class="d-flex justify-content-end">
                            <button (click)="(isForImport = false)" id="btcancel"
                                class="btn btn-primary mx-1">Cancelar</button>
                            <button class="buttonsAccions botExportar col-4 mx-1" type="button"
                                (click)="downloadTemplate()">Plantilla
                            </button>
                            <button class="buttonsAccions buttonSquaredWhiteright col-4 mx-1"
                                (click)="$event.preventDefault(); fileInput.click()"
                                [disabled]="this.isReading">Importar</button>
                            <input [disabled]="this.isReading" #inputFile type="file" #fileInput style="display: none;"
                                (change)="onFileChange($event)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="sppiner-container" *ngIf="loading">
    <div class="spinner-border" role="status"></div>