import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class authGuard  {

  constructor(
    private router: Router,
    private tokenservice: TokenStorageService,

   ) {
  }

  isLoggedIn = false;


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
 if(this.tokenservice.getToken()==null){
    window.sessionStorage.setItem("urlPreLogin",state?.url);
 }
   
    this.isLoggedIn = !!this.tokenservice.getToken();
    if(this.tokenservice.isExpired(this.tokenservice.getToken())){
      this.tokenservice.signOutExpiration()
    } 
    if (!this.isLoggedIn) {
      this.router.navigate(["login"]);
      return false;
    }
    return true;
  }
  

  
}



