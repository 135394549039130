import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Productora, ProductoraExport, ProductoraListar } from 'src/app/models/productora';
import { FilterService } from 'src/app/services/filter.service';
import { ProductoraService } from 'src/app/services/productora.service ';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-listado-productoras',
  templateUrl: './listado-productoras.component.html',
  styleUrls: ['./listado-productoras.component.scss']
})
export class ListadoProductorasComponent {


  nombrePagina = "Productoras"
  rutaNuevo = 'admin-contacine/nueva-productora'
  nombreBoton = "Nueva Productora"
  evento: any = '';



  filters: any[] = [];
  pageSize = "10";
  page = "0";
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  dialogVisible: boolean = false
  idEliminar: any = 0
  isFirst: boolean = true

  dataExport: any = []
  mostrarPopup = 'out';
  mostrarDetalle = 'in';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalElements = 0

  listaProd: Productora[] = []

  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource();
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};

  constructor(
    private router: Router,
    private productoraService: ProductoraService,
    private filterService: FilterService,
    public tableService: TableService,
    public interfazInicial: InterfazInicialComponent,
    public notificationService: NotificationService,
  ) { }
  ngOnInit(): void {

    if (this.tableService.checkAnyAccionByPermiso(24, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    }
    this.filters = [
      {
        key: "fechaBaja",
        operator: "IS_NULL",
      }];
    this.filter.filters = this.filters
    if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
      this.tableService.subscribeToProyectoChanges(() => {
        this.getFilterProyecto()
        this.getProductorasList();
      });
      this.getFilterProyecto();
    }
    this.getProductorasList();

  }

  /**
   * Agrega un filtro para el proyecto actual.
   * 
   */
  getFilterProyecto() {
    this.filters.push(
      {
        field_type: "STRING",
        key: "proyectos.id",
        operator: "CONTAINS_ID",
        value: this.interfazInicial.proyaux.id
      });
  }

  /**
   * Inicializa las listas y actualiza la tabla.
   *
   */
  iniciarListas() {
    this.displayedColumnscompleta = []
    if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
      var a: any = sessionStorage.getItem(this.nombrePagina)
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {

      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }

    this.actualizarTabla();
  }

  /**
   * Actualiza la tabla según los campos mostrados.
   * 
   */
  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }

  /**
   * Realiza un filtro en la tabla.
   * @param event Evento del filtro.
   * @param type Tipo del filtro.
   * @param operator Operador del filtro.
   * @param field_type Tipo de campo del filtro.
   * 
   */
  doFilter(event: any, type: any, operator: any, field_type: any) {
    console.log(field_type);
    console.log(type);


    this.filters = this.filterService.doFilter(event, type, operator, field_type, this.filters, null)
    this.paginator.pageIndex = 0
    this.page = "0"
    this.getProductorasList()
  }

  /**
   * Muestra el modal de eliminar.
   * @param mostrar Indica si se debe mostrar o no el modal.
   * 
   */
  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
  }


  /**
   * Marca como visible todos los campos en la tabla.
   *
   */
  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    this.actualizarTabla();
  }

  /**
   * Establece el detalle a mostrar.
   * @param detalle Detalle a mostrar.
   */
  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }


  /**
   * Aplica un filtro general en la tabla.
   */
  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
    } else {
      this.filters = this.filterService.doGeneralFilter(this.evento, this.filters);
    }
    this.paginator.pageIndex = 0

    this.page = "0"
    this.getProductorasList()
  }

  /**
   * Ordena la lista de productoras según una propiedad.
   * @param property Propiedad por la cual ordenar.
   * @param sorts Propiedades de ordenacion.
   */
  ordenar(property: string, sorts: any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (clave == property) {
        this.ordenarCampos[property] = !this.ordenarCampos[property];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.sorts = [{ key: property, direction: orden }];

    this.getProductorasList();
  }

  /**
   * Evalúa y aplica el tamaño de página seleccionado.
   * @param event Evento del cambio de tamaño de página.
   */
  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: '0', size: this.pageSize };
    this.getProductorasList();
  }

  /**
  * Obtiene la lista de productoras según los filtros y orden actualmente aplicados.
  */
  getProductorasList() {

    this.filter = {
      filters: this.filters,
      page: this.page,
      size: this.pageSize,
      sorts: this.sorts,
    };
    /* Importante resetear todas las listas pero en teoria se podrian copiar y 
    pegar los metodos cambiando el servicio que compruaba el back y los objetos que construyen las listas y objetos*/
    this.productoraService.filter(this.filter).subscribe({
      next: (data: any) => {
        if (this.ordenarCampos['id'] == undefined) {
          this.ordenarCampos = this.filterService.crearCampos(data)
        }
        this.dataExport = []
        data.content.forEach((element: any) => {
          this.dataExport.push(new ProductoraExport(element))
        });
        console.log(this.dataExport);


        this.listaProd = []
        if (data && data.content && data.content != null && data.content.length > 0) {

          /* Este es el objeto de la lista cambiando los campos del 
          contructor de orden se cambia los campos de orden en la tabla */
          var aux = new ProductoraListar(data.content[0])
          if (this.isFirst) {
            this.displayedColumns = Object.keys(aux);

            this.displayedColumns.push("acciones")
            this.paginator._intl = this.interfazInicial.paginator

            this.isFirst = false
            this.iniciarListas();
          }

          console.log(data);

          this.totalElements = data.totalElements;

          this.dataSource = data.content
          this.paginator.pageIndex = data.pageable.pageNumber
          
          this.dataSource.paginator = this.paginator;

        } else {
          this.dataSource = new MatTableDataSource();
        }

      },
      error: (error) => {
        //  console.log(error);
      },
    });
  }


  /**
   * Convierte la primera letra de cada palabra a mayúscula.
   * @param text Texto a convertir.
   * @returns Texto con la primera letra de cada palabra en mayúscula.
   */
  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim()

    return text.charAt(0).toUpperCase() + text.slice(1)

  }

  /**
   * Navega a una ruta específica.
   * @param id Identificador del elemento.
   * @param accion Acción a realizar.
   */
  navegar(id: any, accion: string) {
    this.router.navigate([`admin-contacine/${accion}-productora/${id}`]);
  }




  /**
   * Cambio de página de la lista.
   * @param event Evento del cambio de página.
   */
  nextPage(event: PageEvent) {
    this.page = event.pageIndex + "";
    this.pageSize = event.pageSize + "";

    this.getProductorasList();

  }

  /**
   * Elimina un elemento de la lista.
   * @param id Identificador del elemento a eliminar.
   */
  delete(id: any) {
    this.productoraService.deleteById(id).subscribe({
      next: (data) => {
        console.log(data);

      },
      error: (err) => {
        if (err.error && err.error.text) {
          this.notificationService.success("ÉXITO", err.error.text, 5000);
        } else {
          this.notificationService.warning("ERROR", "Ha ocurrido un error.", 5000);
        }

        this.idEliminar = 0
        this.dialogVisible = false
        this.getProductorasList()
      }
    })
  }
}
