import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent {
  form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),

  });

  loading = false;
  isSuccessful = false;
  errorMessage = "";


  constructor(
    private norificacionService: NotificationService,
    private userService: UsuarioService,
  ) { }

  ngOnInit(): void {

  }
  /**
   * Método para enviar una solicitud de restablecimiento de contraseña
   */
  onSubmit(): void {
    this.loading = true;
    this.userService.forgotPassword(this.form.get("email")?.value).subscribe({
      next: (data) => {
        if (data.err) {
          this.norificacionService.error("ERROR", data.message, 5000);
        } else {
          this.isSuccessful = false;
          this.norificacionService.success("Hecho", data.message, 5000);
          window.location.reload();
        }

      },
      error: (err) => {


      },
    });


  }
}
