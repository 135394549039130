<div class="">
      <app-header-listados [nombre]="nombrePagina" [displayedColumnscompleta]="displayedColumnscompleta"
      [mostrarCampos]="mostrarCampos" [rutaNuevo]="rutaNuevo" [(displayedColumns)]="displayedColumns"
      [nombreBoton]="nombreBoton" (doFilterPadre)="doGeneralFilter()" [(evento)]="evento" [canCreate]="tableService.checkPermiso(8,2,interfazInicial.isSuperAdmin,interfazInicial.permisos)"
      [canExport]="tableService.checkPermiso(8,6,interfazInicial.isSuperAdmin,interfazInicial.permisos)"></app-header-listados>
      <div class="fondoblanco fondoblanco_tabla">
        <div class="table_container data_table mat-elevation-z8">
          <table mat-table [dataSource]="dataSource">
          
              <ng-container matColumnDef="gasto">
                  <th mat-header-cell *matHeaderCellDef>
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="GASTO"
                            (keyup)="doFilter($event, 'gasto.id', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('GASTO', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div>
                       </th>
                  <td *matCellDef="let element"> <a class="mia" (click)="navegarAGasto(element.gasto.id)">
                          {{element.gasto.id}}</a> </td>
              </ng-container>
          
              <ng-container matColumnDef="pagado">
                  <th mat-header-cell *matHeaderCellDef>
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="ESTADO"
                            (keyup)="doFilter($event, 'pagado', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('ESTADO', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div> </th>
                  <td *matCellDef="let element"> <span>
                          {{element.pagado? 'PAGADO':'PENDIENTE'}}</span> </td>
              </ng-container>
          
              <ng-container matColumnDef="fecha_pago">
                  <th mat-header-cell *matHeaderCellDef>
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="FECHA PAGO"
                            (keyup)="doFilter($event, 'fecha_pago', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('FECHA PAGO', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div>
                       </th>
                  <td *matCellDef="let element"> <span *ngIf="element.fecha_pago!=undefined">
                          {{element.fecha_pago}}</span> </td>
              </ng-container>
          
              <ng-container matColumnDef="forma_pago">
                  <th mat-header-cell *matHeaderCellDef> 
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="FORMA PAGO"
                            (keyup)="doFilter($event, 'forma_pago.nombre', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('FORMA PAGO', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div>
                  </th>
                  <td *matCellDef="let element"> <span
                          *ngIf="element.forma_pago!=undefined">{{element.forma_pago.nombre}}</span> </td>
              </ng-container>
          
              <ng-container matColumnDef="proveedor">
                <th mat-header-cell *matHeaderCellDef> 
                    <div class="thdisp">
                        <input matInput type="text" class="searchinput" placeholder="PROVEEDOR"
                          (keyup)="doFilter($event, 'proveedor.nombreFiscal', 'LIKE', 'STRING')"
                          [ngStyle]="{'min-width.px': calcTextWidth('PROVEEDOR', '600 14px Poppins, sans-serif')}">
                        <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                      </div>
                </th>
                <td *matCellDef="let element"> <span
                        *ngIf="element.gasto?.proveedor!=undefined">{{element.gasto.proveedor.nombreFiscal}}</span> </td>
            </ng-container>
          
              <ng-container matColumnDef="numero_talon">
                  <th mat-header-cell *matHeaderCellDef>
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="TALÓN/PAGARÉ"
                            (keyup)="doFilter($event, 'numero_talon', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('TALÓN/PAGARÉ', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div> </th>
                  <td *matCellDef="let element"><span *ngIf="element.numero_talon!=undefined">
                          {{element.numero_talon}}</span> </td>
              </ng-container>
          
              <ng-container matColumnDef="cuenta_cargo">
                  <th mat-header-cell *matHeaderCellDef> 
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="CUENTA CARGO"
                            (keyup)="doFilter($event, 'cuenta_cargo', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('CUENTA CARGO', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div>    
                  </th>
                  <td *matCellDef="let element"><span *ngIf="element.cuenta_cargo!=undefined">
                          {{element.cuenta_cargo}}</span> </td>
              </ng-container>
          
              <ng-container matColumnDef="cuenta_destino">
                  <th mat-header-cell *matHeaderCellDef> 
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="CUENTA DESTINO"
                            (keyup)="doFilter($event, 'cuenta_destino', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('CUENTA DESTINO', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div> </th>
                  <td *matCellDef="let element"> <span
                          *ngIf="element.cuenta_destino!=undefined">{{element.cuenta_destino}}</span> </td>
              </ng-container>
              <ng-container matColumnDef="descipcion">
                  <th mat-header-cell *matHeaderCellDef> 
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="DESCRIPCIÓN"
                            (keyup)="doFilter($event, 'descripcion', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('DESCRIPCIÓN', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div> </th>
                  <td *matCellDef="let element"> <span
                          *ngIf="element.descripcion!=undefined">{{element.descripcion}}</span> </td>
              </ng-container>
              <ng-container matColumnDef="cantidad">
                  <th mat-header-cell *matHeaderCellDef>  
                      <div class="thdisp">
                          <input matInput type="text" class="searchinput" placeholder="CANTIDAD"
                            (keyup)="doFilter($event, 'cantidad', 'LIKE', 'STRING')"
                            [ngStyle]="{'min-width.px': calcTextWidth('CANTIDAD', '600 14px Poppins, sans-serif')}">
                          <a [ngClass]="ordenarCampos['id'] ? 'fechaup':'fechadawn'" (click)=" ordenar('id')"></a>
                        </div>
                  </th>
                  <td *matCellDef="let element"> {{element.cantidad|numberEsp}} </td>
              </ng-container>
           
            
          
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
          
          <mat-paginator class="col-12" showFirstLastButtons [pageSize]="pageSize"
          [length]="totalElements" (page)="nextPage($event)">
          </mat-paginator>
      </div>


<!--<mat-calendar (dateSelected)="actualizarCalendario()"   (selectedChange)="actualizarCalendario()" (yearSelected)="actualizarCalendario()" (monthSelected)="actualizarCalendario()" ></mat-calendar>-->

