import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuentaService {

  /* 
  Servicio para la conexion con el back que se encarga de la gestion de cuentas su listado y guardado
  */
  url: string = environment.API_URL + "/codigo";
  constructor(private http: HttpClient) { }

  getAllCuentas(version: string) {
    return this.http.get(`${this.url}/getGenericos/${version}`);
  }

  // getAllCuentas() {
  //   return this.http.get(`${this.url}/getGenericos`);
  // }

  filter(filter: any): Observable<any> {
    return this.http.post(`${this.url}/filter`, filter);
  }

  save(codigos: any): Observable<any> {
    return this.http.post(`${this.url}/saveAll`, codigos);
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  getHByProyAnfCod(id: any, cuenta: any): Observable<any> {

    return this.http.get(`${this.url}/${id}/${cuenta}`);
  }

  getAllCuentasByProyecto(id: any) {
    return this.http.get(`${this.url}/byProyecto/${id}`);
  }

  update(id: any, codigo: any): Observable<any> {
    return this.http.post(`${this.url}/${id}`, codigo);
  }

  asociarTipo(etiqueta: any) {
    return this.http.post(`${this.url}/addEtiqueta`, etiqueta);
  }
}
