import { InterfazInicialComponent } from "../components/interfaz-inicial/interfaz-inicial.component";
import { Proyecto } from "./proyecto";

export class Usuario {


    id!: number;
    imgPerfil!: String;
    nombreUsuario!: string;
    email!: string;
    empresa!: String;
    departamento!: Departamento;
    twoFactorEnabled!: boolean
    proyectos?: Proyecto[]
    cargo?: string
    direccion?: String;
    codigoPostal?: number;
    ciudad?: String;
    pais?: String;
    dni?: String;
    telefono?: number;
    active!: boolean;
    rol!: Rol;


    public constructor(data?: any, init?: Partial<Usuario>) {
        if (data) {
            this.id = data.id;
            this.imgPerfil = data.imgPerfil
            this.nombreUsuario = data.nombreUsuario;
            this.email = data.email;
            this.empresa = data.empresa;
            this.departamento = data.departamento;
            this.twoFactorEnabled = data.twoFactorEnabled
            this.proyectos = data.proyectos
            this.cargo = data.cargo
            this.direccion = data.direccion
            this.codigoPostal = data.codigoPostal
            this.ciudad = data.ciudad
            this.pais = data.pais
            this.dni = data.dni
            this.telefono = data.telefono
            this.active = data.active
            this.rol = data.rol
        } else {
            Object.assign(this, init);
        }


    }
}

export class UsuarioListar {

    id!: number;
    imgPerfil!: String;
    nombreUsuario!: string;
    email!: string;
    // empresa!: String;
    departamento!: Departamento;
    activo!: boolean
    proyectos?: Proyecto[]
    cargo?: string
    direccion?: String;
    codigoPostal?: number;
    permisos!: any;
    ciudad?: String;
    pais?: String;
    dni?: String;
    telefono?: number
    changePassAdmin?: boolean
    public constructor(data?: any, init?: Partial<Usuario>, rol?: number) {
        if (data) {
            this.id = data.usuario.id; 
            this.cargo = data.cargo
            this.imgPerfil = data.usuario.imgPerfil
            this.nombreUsuario = data.usuario.nombreUsuario;
            this.departamento = data.departamento;
            this.changePassAdmin = data.usuario.changePassAdmin;
            console.log(this.changePassAdmin);
            
            //this.email = data.email;
            this.activo = data.usuario.active;
            if (rol == 3) {
                this.proyectos = data.usuario.proyectos
            }

            if (rol == 2) {
                this.permisos = data.usuario.permisos ? data.usuario.permisos : ""
            }
            this.dni = data.usuario.dni
            this.telefono = data.usuario.telefono
            this.ciudad = data.usuario.ciudad
            this.pais = data.usuario.pais
            // this.empresa = data.empresa;
            // this.direccion = data.direccion
            // this.codigoPostal = data.codigoPostal
        } else {
            Object.assign(this, init);
        }


    }
}
export class UsuarioExport {

/*     id!: number;
 */ nombreUsuario!: string;
    email!: string;
    empresa!: string;
    departamento!: string;
    activo!: string
    cargo?: string
   /*  direccion?: string;
    codigoPostal?: number; */
    ciudad?: string;
    telefono?: number
    public constructor(data?: any, init?: Partial<Usuario>) {
        if (data) {
/*             this.id = data.id;
 */            this.nombreUsuario = data.usuario.nombreUsuario;
            this.email = data.usuario.email;
            this.activo = data.usuario.active ? "SI" : "NO";
            this.empresa = data.usuario.empresa;
            this.cargo = data.cargo ? data.cargo : "";
            this.departamento = data.departamento ? data.departamento.nombre : "";
        /*     this.direccion = data.direccion
            this.codigoPostal = data.codigoPostal */
            this.ciudad = data.usuario.ciudad
            this.telefono = data.usuario.telefono
        } else {
            Object.assign(this, init);
        }


    }
}


export class Departamento {
    id!: number;
    nombre!: String;
}

export class Rol {
    id!: number;
    nombre!: String;


}