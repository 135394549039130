import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { AuthService } from 'src/app/services/usuario/auth.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { RaizComponent } from '../_raiz/raiz.component';
import { FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Usuario } from 'src/app/models/usuario';
import { UrlService } from 'src/app/services/url.service';
import { MatDialog } from '@angular/material/dialog';
import { TwoFactorComponent } from '../two-factor/two-factor.component';
import { NextcloudServiceService } from 'src/app/services/nextcloud-service.service';
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StorageServiceService } from 'src/app/services/servicios-funcionalidad/storage-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {



  form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    totpkey: new FormControl(""),
  });



  acceptTemCon = false
  isRecoverPass = false;
  isLoggedIn = false;
  isLoginFailed = false;
  submitted = false;
  loading = false;
  tfaFlag: boolean = false;
  resetQR: boolean = false;
  resetedQR: boolean = false;
  qr: any;
  errorQR: boolean = false;
  errorMessage: String = "";
  tfActivated: boolean = false;
  previousUrl: string = '';
  midata: Usuario = new Usuario()
  returnUrl?: string;





  constructor(
    private tokenStorage: TokenStorageService,
    private router: Router,
    private notificationService: NotificationService,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private raiz: RaizComponent,
    private urlService: UrlService,
    public dialog: MatDialog,
    public nextcloudService: NextcloudServiceService,
    private storageService: StorageServiceService


  ) {
  }


  ngOnInit(): void {

    /*  this.checkPrevious()
     if (this.tokenStorage.getToken()) {
 
       this.isLoggedIn = true;
       this.midata = this.tokenStorage.getUser();
       if (this.previousUrl) { */
    if (this.tokenStorage.getToken()) {
      this.router.navigate(['admin-contacine/proyectos'])
    }
  }



  /* checkPrevious() {
    var urlaux = this.urlService.getPreviousUrl()
    if (urlaux) {
      this.previousUrl = urlaux;

    }
  }
 */

  /**
   * Maneja el evento de envío del formulario de inicio de sesión.
   * 
   * @returns False si hay errores en el formulario, de lo contrario, retorna undefined.
   */
  onSubmit(): any {
    this.submitted = true;
    this.storageService.vaciar()
    
    if (this.form.get('email')?.hasError('pattern')) {
      this.notificationService.warning('Información', 'El email no es válido.', 5000);
      return false;
    }
    if (this.form.get('password')?.hasError('minlength')) {
      this.notificationService.warning('Información', 'La contraseña debe contener 6 caracteres.', 5000);
      return false;
    }

    if (!this.form.valid) {
      this.notificationService.warning('Información', 'Rellene todos los campos por favor.', 5000);
    } else {
      if (!this.acceptTemCon) {
        this.notificationService.warning('Información', 'Acepte los términos y condiciones.', 5000);
        return false;
      }
      this.authService.login(this.form.controls['email'].value, this.form.controls['password'].value, this.form.controls['totpkey'].value).subscribe({
        next: async (data) => {
          console.log(data);

          if (data.changePassAdmin) {
            this.authService.getIdentificador(this.form.controls['email'].value).subscribe({
              next: (data) => {
                console.log(data);

                this.router.navigate([`restore-password/${data.identificador}`]);

              }
            })
          } else {

            if (data['tfaEnabled'] === true && !data['tfa']) {
              this.pedirQR()
            } else if (data['tfaEnabled'] === false) {

              this.tokenStorage.saveToken(data.token);
              this.loginTfaDisabled();
            } else if (data['tfa']) {

              this.form.controls['totpkey'].addValidators([Validators.required, Validators.minLength(6)])
              this.submitted = false;
              this.tfaFlag = true;
              this.pedirQR()
            }
          }

        },
        error: (error) => {

          this.notificationService.error('ERROR', error.error.message, 5000);

          this.isLoginFailed = true;
        },
      });

    }
  }

  /**
   * Realiza acciones específicas cuando el inicio de sesión con autenticación de dos factores está deshabilitado.
   */
  loginTfaDisabled(): any {
    this.isLoggedIn = true;
    this.isLoginFailed = false;
    this.authService.updateAuthStatus(true);
    this.usuarioService.getCurrentUser().subscribe({
      next: (data) => {
        if (data.user.imgPerfil) {

          this.nextcloudService.getBase64(data.user.imgPerfil).subscribe({
            next: (datas) => {
              window.sessionStorage.setItem(environment.IMG_PERFIL, datas.b64);
              this.tokenStorage.setUser(data.user);
              this.raiz.reloadHeader = !this.raiz.reloadHeader;

              this.router.navigate(['admin-contacine/proyectos']);
            },
            error: (err) => {
              this.tokenStorage.setUser(data.user);
              this.raiz.reloadHeader = !this.raiz.reloadHeader;
              this.router.navigate(['admin-contacine/proyectos']);
            }
          })
        } else {
          this.tokenStorage.setUser(data.user);
          this.raiz.reloadHeader = !this.raiz.reloadHeader;

          this.router.navigate(['admin-contacine/proyectos']);
        }

      },
      error: (error) => {
        this.tokenStorage.signOut();
      }
    })

  }
  /**
   * Realiza la peticion para solicitar el código QR necesario para la autenticación de dos factores.
   */
  pedirQR(): any {
    this.submitted = false;
    this.authService.pedirQr(this.form.get('email')?.value).subscribe({
      next: (data) => {
        this.qr = data;
        this.openDialog()

      },
      error: (err) => {

      }
    });
  }
  
  /**
   * Abre un diálogo modal para mostrar el código QR y solicitar el código de autenticación de dos factores.
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(TwoFactorComponent, {
      data: { email: this.form.get('email')?.value, pass: this.form.get('password')?.value, action: this.tfaFlag, qr: this.qr },
      disableClose: true

    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


}
