<app-header-listados [nombre]="nombrePagina" [nombreBoton]="nombreBoton" [isCreatePage]="isCreatePage"
    [(isSaved)]="isSave" [(isClearForm)]="isClearForm"></app-header-listados>
<div>
    <div class="topInfo">
        <label class="titulosPaginas">{{isUpdate?'':isReading?'':'NUEVA'}} CUENTA</label>
    </div>
    <div class="form-surrouded">
        <form class="concepto col-12" [formGroup]="form">
            <div class="col-12 d-flex justify-content-between">
                <div class="col-4 formMaterial">
                    <label class="col-4 formLabel">Código Cuenta</label>
                    <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                        [ngClass]="isReading?'desactivado':'auto'">
                        <input matInput formControlName="cuenta" mask="XX.XX.XX" (input)="inputCodigo()"
                            placeholder="Código Cuenta...">
                        <mat-error>Código cuenta requerido</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-1 padded-col" *ngIf="isAdmin&&!this.isUpdate">
                    <label class="formLabel">Todos</label>
                    <mat-checkbox class="col-1" [checked]="isAll" (change)="selectAll($event.checked)"></mat-checkbox>
                </div>
            </div>


            <div formArrayName="hijos" class="col-12">
                <div *ngFor="let item of hijos.controls; let i = index" [formGroupName]="i" class="col-12">
                    <div class="col-12 rowForm d-flex">
                        <div class="formMaterial">
                            <label class="col-4 formLabel">Proyecto
                            </label>
                            <mat-form-field appearance="outline" class="col-8 formField padingCol8"
                                [ngClass]="isReading?'desactivado':'auto'">
                                <input matInput formControlName="titulo">
                                <mat-error>Proyecto requerido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="formMaterial">
                            <span *ngIf="isUpdate && cuentaUpdate.proyectos">

                                <label class="col-4 formLabel">Código Cuenta
                                </label>
                                <mat-form-field appearance="outline" class="col-5 formField padingCol8"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput formControlName="codigo" [value]="isUpdate===true?cuentaUpdate.codigo:setCodigo(item)|codigosCuentas"
                                        placeholder="00.00.00" mask="XX.XX.XX">
                                    <mat-error>Código requerido</mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-3 formField padingCol8"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput formControlName="codigoHijo" [value]="isUpdate===true?cuentaUpdate.codigoHijo:findByProyAndCodigo(item)"
                                        placeholder="00">
                                    <mat-error>Código hijo requerido</mat-error>
                                </mat-form-field>
                            </span>

                            <span *ngIf="isUpdate &&!cuentaUpdate.proyectos">

                                <label class="col-4 formLabel">Concepto
                                </label>
                                <mat-form-field appearance="outline" class="col-7 formField padingCol8"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput formControlName="aux" [value]="isUpdate===true?cuentaUpdate.concepto:''"
>
                                    <mat-error>Código requerido</mat-error>
                                </mat-form-field>
                                
                            </span>
                            <span *ngIf="!isUpdate">
                                <label class="col-4 formLabel">Código Cuenta
                                </label>
                                <mat-form-field appearance="outline" class="col-5 formField padingCol8"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput formControlName="codigo" [value]="setCodigo(item)|codigosCuentas"
                                        placeholder="00.00.00" mask="XX.XX.XX">
                                    <mat-error>Código requerido</mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="col-3 formField padingCol8"
                                    [ngClass]="isReading?'desactivado':'auto'">
                                    <input matInput formControlName="codigoHijo" [value]="findByProyAndCodigo(item)"
                                        placeholder="00">
                                    <mat-error>Código hijo requerido</mat-error>
                                </mat-form-field>
                            </span>
                        </div>
                        <div class="formMaterial desc">
                            <label class="col-4 formLabel">{{isUpdate?cuentaUpdate.proyectos?"Descripción":"Etiqueta":"Descripción"}}
                            </label>
                            <mat-form-field appearance="outline" class="col-7 formField padingCol8"
                                [ngClass]="isReading?'desactivado':isUpdate||!isAdmin?'col-8':''">
                                <input matInput formControlName="concepto" [value]="setConcepto(item)"
                                    placeholder="{{isUpdate?cuentaUpdate.proyectos?'Descripción':'Etiqueta':'Descripción'}}...">

                                <mat-error>Descripción requerida</mat-error>
                            </mat-form-field>
                            <mat-checkbox *ngIf="isAdmin&&!this.isUpdate" class="col-1" [checked]="checkSelected(item)"
                                (change)="unselect($event.checked,item)"></mat-checkbox>
                        </div>






                    </div>
                </div>
            </div>






            <div class="col-12 cuadroBotones formMaterial">
               <!--  <span *ngIf="!isReading" class="formMaterial">

                    <button class="buttonSquared buttonPrimary" type="button" (click)="volver()">Volver
                    </button>
                </span> -->
                <button *ngIf="isSave?onSubmit():''" >
                </button>
            </div>
        </form>
    </div>

</div>