import { formatDate } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Departamento, Usuario, UsuarioExport, UsuarioListar } from 'src/app/models/usuario';
import { NotificationService } from 'src/app/services/notification.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { HeaderListadosComponent } from '../../header-listados/header-listados.component';
import { environment } from 'src/environments/environment';
import { NextcloudServiceService } from 'src/app/services/nextcloud-service.service';
import { Observable, finalize } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { PermisosService } from 'src/app/services/permisos.service';

@Component({
  selector: 'app-listado-usuarios',
  templateUrl: './listado-usuarios.component.html',
  styleUrls: ['./listado-usuarios.component.scss']
})
export class ListadoUsuariosComponent {



  nombrePagina = "Usuarios"
  rutaNuevo = 'admin-contacine/nuevo-usuario'
  nombreBoton = "Nuevo Usuario"
  evento: any = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalElements = 0


  img = window.sessionStorage.getItem(environment.IMG_PERFIL)

  filters: any[] = [];
  pageSize = "10";
  page = "0";
  sorts: any[] = [];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };

  isFirst: boolean = true
  canvas: any;

  dataExport: any

  mostrarPopup = 'out';
  mostrarDetalle = 'in';

  isCards: boolean = false

  listaUsus: UsuarioListar[] = []

  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource();
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  filtradoBoolean = ['Activo', 'Inactivo'];
  permisosAux: any = []
  accionesIndex: number = 0

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private usuarioService: UsuarioService,
    private nextCloudService: NextcloudServiceService,
    private filterService: FilterService,
    public interfazInicial: InterfazInicialComponent,
    public tableService: TableService,
    private permisoProyectoService: PermisosProyectoService,
    private permisosService: PermisosService,

  ) { }

  ngOnInit(): void {
    if (this.tableService.checkAnyAccionByPermiso(22, this.interfazInicial.isSuperAdmin, this.interfazInicial.permisos)) {
      this.notificationService.error("ERROR", "No tienes permisos suficientes.", 5000)
      this.router.navigate(["admin-contacine/proyectos"])
    }
    this.permisosAux = []
    this.permisosService.getAllPermisos().subscribe({
      next: (data) => {
        this.accionesIndex = data.acciones.length
        data.permisos.forEach((el: any) => {
          if (el.padre) {
            this.permisosAux.push(el)
          }
        })

      }
    })
    if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
      this.tableService.subscribeToProyectoChanges((proyectoActual: any) => {
        this.getFilterProyecto()

        this.getUserList();
      });
      this.getFilterProyecto();
    } else {
     /*  this.filters = [
        {
          key: "usuario.dni",
          operator: "DISTINCT",
        }] */
      this.tableService.subscribeToProyectoChanges((proyectoActual: any) => {
        /* this.filters = [
          {
            key: "usuario.dni",
            operator: "DISTINCT",
          }] */
          this.getUserList();
      });
    }

    this.getUserList();

  }
  getFilterProyecto() {
    this.filters = [
      {
        field_type: "STRING",
        key: "proyecto.id",
        operator: "LIST_CONTAINS",
        value: this.interfazInicial.proyaux.id
      },
      {
        field_type: "STRING",
        key: "usuario.rol.id",
        operator: "LIKE",
        value: "2"
      }
    ];
    this.filter.filters = this.filters
  }


  iniciarListas() {
    this.displayedColumnscompleta = []
    if (sessionStorage.getItem(this.nombrePagina) && sessionStorage.getItem(this.nombrePagina) != "undefined") {
      var a: any = sessionStorage.getItem(this.nombrePagina)
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {

      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }

    this.actualizarTabla();
  }

  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }

  getImage(usu: any, auxData: any) {

    if (usu.imgPerfil) {


      this.nextCloudService.getBase64(usu.imgPerfil).subscribe({
        next: (data) => {

          usu.imgPerfil = data.b64

          this.listaUsus?.push(new UsuarioListar(usu, undefined, this.interfazInicial.currentUser.rol.id))

          if (auxData.length == this.listaUsus.length) {
            var aux2: any = this.listaUsus

            this.dataSource = aux2
          }
        },
        error(err) {

        },
      }
      )
    }
    else {
      this.listaUsus?.push(new UsuarioListar(usu, undefined, this.interfazInicial.currentUser.rol.id))
      console.log("Antes del if");
      
      if (auxData.length == this.listaUsus.length) {
        console.log("Después del if");
        

        var aux2: any = this.listaUsus
        this.dataSource = aux2



      }
    }


  }

  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
  }

  evaluarCheckbox(columna: any) {
    if (this.mostrarCampos[columna] == true) {
      this.mostrarCampos[columna] = false;
    } else {
      this.mostrarCampos[columna] = true;
    }
    this.actualizarTabla();
  }

  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
    this.actualizarTabla();
  }

  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }




  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.getUserList()
    } else {
      let operator = 'ALL_FIELDS_CLASS';
      if (this.evento != '') {
        this.filters = [{
          operator: operator,
          value: this.evento,

        }]

          ;
        if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
          this.filters.push({
            field_type: "STRING",
            key: "proyectos.id",
            operator: "LIKE",
            value: this.tableService.proy.id
          })
        }
        this.filter = {
          filters: this.filters,
          page: this.page,
          size: this.pageSize,
          sorts: this.sorts,
        };
        this.getUserList()
      } else {
        if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
          this.filters = [{
            field_type: "STRING",
            key: "proyectos.id",
            operator: "LIKE",
            value: this.tableService.proy.id
          }]
        }
        else {
          this.filters = []
        }
        this.getUserList()
      }
    }
  }

  ordenar(property: string, sorts: any[] = []) {
    if (property == "usuario.activo") {
      if (this.ordenarCampos[property] == undefined) {
        this.ordenarCampos[property] = false;
      }
    }
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';

    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (clave == property) {
        this.ordenarCampos[property] = !this.ordenarCampos[property];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    if (property == "activo") {
      this.sorts = [{ key: "isActive", direction: orden }];
    } else {
      this.sorts = [{ key: property, direction: orden }];
    }
    this.getUserList();
  }

  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: '0', size: this.pageSize };
    this.getUserList();
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    let valueBool = null
    console.log(type);
    
    if (type == "usuario.activo") {
      type = "usuario.isActive"
      if (event.target != null && event.target.value != "") {
        let value: string = event.target.value
        if (("activo").includes((value.toLowerCase()))) {
          valueBool = "true"
        }
        else if (("inactivo").includes((value.toLowerCase()))) {
          valueBool = "false"
        }
      }
    } else {
      valueBool = null
    }



    this.filters = this.filterService.doFilter(event, type, operator, field_type, this.filters, valueBool)
    this.page = "0"
    this.paginator.pageIndex = 0
    this.getUserList()
  }

  permisosPorProy: { [subcapitulo: string]: any[] } = {};
  async getUserList() {
    console.log(this.ordenarCampos)
    this.filter = {
      filters: this.filters,
      page: this.page,
      size: this.pageSize,
      sorts: this.sorts,
    };

    // Get only active users (fechaBaja is NULL)
    this.filter.filters.push({
      field_type: 'DATE',
      key: 'usuario.fechaBaja',
      operator: 'IS_NULL'
    });

    console.log("filter-> " ,this.filter.filters)
    /* Importante resetear todas las listas pero en teoria se podrian copiar y 
    pegar los metodos cambiando el servicio que compruaba el back y los objetos que construyen las listas y objetos*/
    this.usuarioService.filter(this.filter).subscribe({
      next: async (data: any) => {
        var arrayAux: any[]=[]
        data.content.forEach((element: any)  => {
          var sameUser=false;
        arrayAux.forEach((elementAux, index) => {
          var elementGuardar=element;
          if(elementAux.usuario.id ==  element.usuario.id){
            //elementGuardar.departamento= [element.departamento, elementAux.departamento ]
            elementGuardar.proyecto= [element.proyecto, elementAux.proyecto ]

           sameUser=true

             arrayAux.splice(index, 1); 

           arrayAux.push(elementGuardar)
            
          }
            
          });
          if(!sameUser){

            arrayAux.push(element) 
          }
        });
        data.content=arrayAux
        this.dataExport = []
        if (this.interfazInicial.proyaux && this.interfazInicial.proyaux.id) {
          var indices = []
          console.log("DATA",data.content);
          
          data.content.forEach((element: any) => {
          /*   this.permisoProyectoService.getPermisosFormPreyectoAndUsu(this.interfazInicial.proyaux.id, element.id).subscribe({
              next: (dep) => {

                element.cargo = dep.cargo 
                element.departamento = dep.departamento
                this.filters.forEach((filt:any) => {
                  
                  if(filt.key=="departamento.nombre"){
                    
                    if(!dep.departamento || !dep.departamento.nombre.includes(filt.value+"")){                      
                      indices.push( data.source.findIndex(
                        (objeto:any) => objeto.id === element.id
                        ));
                    }
                  }
                });

              }
            }) */
            console.log(element);
            
            
            this.dataExport.push(new UsuarioExport(element))
          }); 
        }
        console.log(this.ordenarCampos)
        if (this.ordenarCampos['id'] == undefined) {
          this.ordenarCampos = this.filterService.crearCampos(data)
          this.ordenarCampos['usuario.nombreUsuario'] = false;
          this.ordenarCampos['departamento.nombre'] = false;
        }

        console.log(indices);
        

        this.listaUsus = []
        if (data && data.content && data.content != null && data.content.length > 0) {

          await data.content.forEach(async (usu: any) => {
            await (usu = this.getPermisos(usu, data.content))
          });


          /* Este es el objeto de la lista cambiando los campos del 
          contructor de orden se cambia los campos de orden en la tabla */
          var aux = new UsuarioListar(data.content[0], undefined, this.interfazInicial.currentUser.rol.id)
          if (this.isFirst) {
            this.displayedColumns = Object.keys(aux);

            this.displayedColumns.push("acciones")

            this.paginator._intl = this.interfazInicial.paginator
            this.isFirst = false
            this.iniciarListas();
          }
          this.totalElements = data.content.length


          this.dataSource.paginator = this.paginator;
        } else {
          if (this.isFirst) {
            var aux: UsuarioListar = new UsuarioListar()
            console.log("dataaaaaaaa",data);
            
            var demo = new UsuarioListar(data.content[0])
            this.displayedColumns = Object.keys(demo);

            this.displayedColumns.push("acciones")

            this.paginator._intl = this.interfazInicial.paginator
            this.isFirst = false
            this.iniciarListas();
          }
          this.dataSource = data['content']

        }

      },
      error: (error) => {
        //  console.log(error);
      },
    });
  }

  async getPermisos(usu: any, aux: any): Promise<any> {
    if (!this.interfazInicial.isSuperAdmin && !this.interfazInicial.isLicencia) {
      var premisosGuardar: any = []
      usu.usuario.permisos = []
      this.permisoProyectoService.getPermisosFormPreyectoAndUsu(this.interfazInicial.proyaux.id, usu.usuario.id).subscribe({
        next: async (data) => {

     

          if (data.permisos && data.permisos.length != 0) {


            this.permisosPorProy = {}


            await data.permisos.forEach((permi: any) => {


              const nombrePermiso = permi.permiso.nombre;

              if (!this.permisosPorProy[nombrePermiso]) {
                this.permisosPorProy[nombrePermiso] = [];
              }

              permi.index = data.permisos.indexOf(permi)
              this.permisosPorProy[nombrePermiso].push(permi);
            });
            await this.permisosAux.forEach((ele: any) => {
              if (this.permisosPorProy[ele.nombre] && ele.padre) {


                if (this.permisosPorProy[ele.nombre].length == 6 || this.permisosPorProy[ele.nombre].length == 12) {
                  usu.usuario.permisos.push({ nombre: ele.nombre, isAll: true })
                } else {
                  usu.usuario.permisos.push({ nombre: ele.nombre, isAll: false })
                }

              } else {
                for (let element of ele.listadoHijos) {

                  if (this.permisosPorProy[element.nombre]) {
                    usu.usuario.permisos.push({ nombre: ele.nombre, isAll: false })
                    break;
                  }
                };

              }
            })
          }
          await this.getImage(usu, aux)
        }
      })
      return usu



    } else {
      if (this.interfazInicial.proyaux && this.interfazInicial.proyaux.id) {


        this.permisoProyectoService.getPermisosFormPreyectoAndUsu(this.interfazInicial.proyaux.id, usu.id).subscribe({
          next: async (data) => {

            
            await this.getImage(usu, aux)
          }
        })
      } else {
        await this.getImage(usu, aux)
      }
    }
  }

  getFirstLetter(nombre: string) {
    if (nombre) {
      return nombre.charAt(0).toUpperCase();
    }
    return
  }

  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim().replaceAll("_", " ")
    return text.charAt(0).toUpperCase() + text.slice(1)

  }

  navegar(id: any, ruta: string) {

    this.router.navigate([`admin-contacine/${ruta}-usuario/${id}`]);
  }
  calcularProyectos(proyectos: any): number {
    var index = 0

    if (proyectos && proyectos.length != 0) {
      index = proyectos.length
    }

    return index
  };

  updateActivo(id: number, isActive: boolean, permanent: boolean) {
    this.usuarioService.updateActive(id, !isActive, permanent).subscribe({
      next: (data) => {
        if (data) {
          this.getUserList()
        }
      },
      error: (err) => {
        this.notificationService.error('ERROR', err.error.message, 5000);
      }
    })

  }

  darDeBaja(usu: any, permanent: boolean) {
    this.updateActivo(usu, true, permanent)

  }
  solicitarNuevoCorreo(usuId: any) {
    this.usuarioService.sendNewEmail(usuId,false).subscribe({
      next: (data) => {
        this.notificationService.success('ÉXITO', 'Email enviado correctamente.', 5000);

      },
      error: (err) => {
        this.notificationService.error('ERROR', err.message, 5000);
      }
    })

  }

  nextPage(event: PageEvent) {
    this.page = event.pageIndex + "";
    this.pageSize = event.pageSize + "";

    this.getUserList();
  }

  filtrarBoolean(event: any) {
    const value = event.target.value;
    if (value == "Activo") {
      this.doFilter("true", "isActive", "LIKE", "BOOLEAN")
    } else {
      this.doFilter("false", "isActive", "LIKE", "BOOLEAN")

    }
  }


}
