<!-- <div class="contenedor-boton table_container">

    <label class="titulosPaginas"> {{nombre.toUpperCase()}} </label>
    <div (mouseenter)="mostrarModal('in')" (mouseleave)="mostrarModal('out')">
        <p class="mostrar pointer"> <span
                [ngClass]="{'mostrar2': displayedColumnscompleta.length!= displayedColumns.length}"> Mostrar
                Ocultar columnas </span>/
        </p>

        <div [@slideInOut]="mostrarPopup" class="popup">
            <mat-form-field class="formField formFieldcol " appearance="outline">
                <input matInput placeholder="Columna..." type="text" (keyup)="filterCols($event)"
                    (keydown)="filterCols($event)">
            </mat-form-field>

            <div *ngIf="!mostrarColumns || columnsNamescompleta?.at(0)==undefined">
                <div class="checks"
                    *ngFor="let columnas of displayedColumnsFiltred?displayedColumnsFiltred:displayedColumnscompleta index as i"
                    (click)="evaluarCheckbox(i)">
                    <span *ngIf="columnas === 'acciones'">
                        <input type="checkbox" class="checkbox" [checked]="mostrarCampos[columnas]">
                        <span class="columnas"> {{primLetMay(columnas)}}</span>

                    </span>
                </div>
                <div class="checks"
                    *ngFor="let columnas of displayedColumnsFiltred?displayedColumnsFiltred:displayedColumnscompleta index as i"
                    (click)="evaluarCheckbox(i)">
                    <span *ngIf="columnas !== 'acciones'&&columnas !== 'cargo'">
                        <input type="checkbox" class="checkbox" [checked]="mostrarCampos[columnas]">
                        <span class="columnas"> {{primLetMay(columnas)}}</span>

                    </span>
                </div>
            </div>

            <div *ngIf="mostrarColumns && columnsNamescompleta?.at(0)!=undefined">
                <p>{{columnsNamescompleta?.at(0)==undefined}}</p>
                <div class="checks" *ngFor="let columnas of columnsNamescompleta index as i"
                    (click)="evaluarCheckbox(i)">
                    <input type="checkbox" class="checkbox" [checked]="mostrarColumns[columnas]">
                    <span class="columnas"> {{primLetMay(columnas)}}</span>
                </div>
            </div>
        </div>
    </div>

    <p class="ocultar-header pointer" (click)="vetTodo()"><span
            [ngClass]="{'mostrar2': displayedColumnscompleta.length== displayedColumns.length}">Ver todo</span>
    </p>
</div> -->
<div class="headerClass headerZ" [ngClass]="getHeaderClassColor(nombre)">

    <div class="contenedor-principal">

        <div class="contenedor-principal-izq">
            <label class="titulosPaginas "> {{nombre.toUpperCase()}} </label>


            <div appearance="outline" class="contenedor-boton3" *ngIf="!nombre.toLowerCase().includes('inform') && !isCreatePage">
                <mat-form-field appearance="outline" class="w8 formField" *ngIf="!isCreatePage">
                    <mat-select value="null" (selectionChange)="evaluarPageSize($event)">
                        <mat-option value="10" class="mimat-option">Mostrar 10</mat-option>
                        <mat-option value="25" class="mimat-option">Mostrar 25</mat-option>
                        <mat-option value="50" class="mimat-option">Mostrar 50</mat-option>
                        <mat-option value="100" class="mimat-option">Mostrar 100</mat-option>
                        <mat-option value="150" class="mimat-option">Mostrar 150</mat-option>
                        <mat-option value="null" class="mimat-option">Mostrar todo</mat-option>
                    </mat-select>
                </mat-form-field>



                <mat-form-field appearance="outline" class="buscadorHeader formField" *ngIf="!isCreatePage">
                    <input matInput (keyup)="EnviarStringFilter($event)" placeholder="Filtrar todo:">
                </mat-form-field>

            </div>
            </div>
            <div class="d-flex">
            <div class="contenedor-boton2" *ngIf="!nombre.toLowerCase().includes('inform')">

                <button *ngIf="nombreBoton!=''" class="buttonsAccions botVolver" type="button"
                    [ngClass]="router.url.toLowerCase().includes('ver-') && !nombre.toLowerCase().includes('orden') ? 'borderSee' : canExport || canEdit ? '': 'borderSee' "
                    (click)="goBack()"><span class="botText">Volver</span></button>

                <button class="buttonsAccions botExportar" type="button"
                    [ngClass]="{'borderCenter':(nombre.toLowerCase().includes('justif') || nombre.toLowerCase().includes('labor')||(isCreatePage && nombre.toLowerCase().includes('orden') && (!router.url.toLowerCase().includes('ver-') || canSend)))}"
                    *ngIf="!canExport && (nombreBoton!=''  && !isCreatePage) || (router.url.toLowerCase().includes('ver-') &&isCreatePage&&nombre.toLowerCase().includes('orden'))"
                    (click)="!canExport? (!isCreatePage && displayedColumns.length!=0 && nombre.toLowerCase().includes('orden')? exportService.otherexport(nombreBoton,dataExport,nombre,interfazInicial.proyaux.titulo): !isCreatePage && displayedColumns.length!=0 ?exportService.export(nombreBoton,dataExport,nombre,interfazInicial.proyaux.titulo) : generarPDFOc() ): notifExport()"><span class="botText">Exportar</span>
                </button>
                
                <!-- Botón Exportar -->
                <button class="buttonsAccions botExportar"
                [ngClass]="{'borderCenter': nombre.toLowerCase().includes('presu') || 
                            nombre.toLowerCase().includes('justif') || 
                            nombre.toLowerCase().includes('labor'),
                            'borderCenter clearIcon': nombre.toLowerCase().includes('orden de compra') && canSend && !router.url.toLowerCase().includes('ver-'),
                            'disabled': router.url.toLowerCase().includes('ver-'),
                            'clearIcon': !nombre.toLowerCase().includes('presu') && !nombre.toLowerCase().includes('justif') && 
                                        !nombre.toLowerCase().includes('labor') && !nombre.toLowerCase().includes('orden de compra')}"
                type="button" *ngIf="isCreatePage && canExport && (nombre.toLowerCase().includes('coste') || nombre.toLowerCase().includes('presu'))"
                (click)="isClearFormChange.emit(true)">
                <span class="botText">Exportar</span>
                </button>

                <!-- Botón Limpiar -->
                <button class="buttonsAccions botExportar"
                [ngClass]="{'borderCenter': nombre.toLowerCase().includes('presu') || 
                            nombre.toLowerCase().includes('justif') || 
                            nombre.toLowerCase().includes('labor'),
                            'borderCenter clearIcon': nombre.toLowerCase().includes('orden de compra') && canSend && !router.url.toLowerCase().includes('ver-'),
                            'disabled': router.url.toLowerCase().includes('ver-'),
                            'clearIcon': !nombre.toLowerCase().includes('presu') && !nombre.toLowerCase().includes('justif') && 
                                        !nombre.toLowerCase().includes('labor') && !nombre.toLowerCase().includes('orden de compra')}"
                type="button" *ngIf="isCreatePage && !(nombre.toLowerCase().includes('coste') || nombre.toLowerCase().includes('presu'))"
                (click)="isClearFormChange.emit(true)">
                <span class="botText">Limpiar</span>
                </button>

                <button class="buttonsAccions botExportar"
                type="button" *ngIf="nombre.toLowerCase().includes('orden de compra') && canSend" 
                (click)="isSendingChange.emit(true)"><span class="botText">Enviar</span>
                </button>
                <button
                    *ngIf="(nombre.toLowerCase().includes('presu') || nombre.toLowerCase().includes('justif') || nombre.toLowerCase().includes('labor')) && (canImport || canEdit) "
                    class="buttonsAccions buttonSquaredWhiteright col-4" [disabled]="!canImport"
                    (click)="isImportChange.emit(true)"><span class="botText"> Importar</span></button>
            </div>



            <div appearance="outline" class="contenedor-boton3" *ngIf="nombre.toLowerCase().includes('inform')">
                <mat-form-field appearance="outline" class="w8 formField">
                    <mat-select placeholder="Tipo" (selectionChange)="eventoTipoChange.emit($event)">
                        <mat-option value="1">Costes</mat-option>
                        <mat-option value="2">Presupuesto</mat-option>
                        <mat-option value="3">Facturas</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="buscadorHeader formField"
                    *ngIf="subTipos && subTipos.length!=0">
                    <mat-select placeholder="Filtro informe" (selectionChange)="eventoSubTipoChange.emit($event)">
                        <mat-option *ngFor="let sub of subTipos" [value]="sub">

                            <span class="columnas"> {{sub.nombre}}</span></mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="contenedor-boton2" *ngIf="nombre.toLowerCase().includes('inform')">
                <button class="buttonsAccions botVolver col-5" type="button" (click)="goBack()"><span class="botText">Volver</span></button>
                <button class="buttonsAccions botExportar col-5" type="button"
                    (click)="exportPDFChange.emit(true)"><span class="botText">Exportar</span>
                </button>

            </div>

        </div>

        <div class="d-flex">
            <div class="contenedor-boton"
                *ngIf="(!canCreate && nombreBoton!=''&&!nombre.toLowerCase().includes('inform')||nombre.toLowerCase().includes('presu')) && !isViewPage">
                <button *ngIf="(nombreBoton!='pagos' && !nombre.toLowerCase().includes('presu') && !nombre.toLowerCase().includes('coste')) || 
                (nombre.toLowerCase().includes('coste') && canClose) "
                    class="buttonRounded buttonListado"
                    [ngClass]="isCreatePage||nombreBoton.toLowerCase().includes('coste')||nombre.toLowerCase().includes('justif')?'':'buttonAdd'"
                    (click)="isCreatePage?isSavedChange.emit(true) :nombre.toLowerCase().includes('labor')||nombre.toLowerCase().includes('justif')?isPasarACosteChange.emit(true):nuevo()">
                    {{nombreBoton}}</button>

                <div class="contenedorButtonPres" *ngIf="nombre.toLowerCase().includes('presu') && canEdit">
                    <button class="buttonPres" (click)="isSavedChange.emit(true)" [disabled]="!canCreate"
                        type="button">Guardar Presupuesto</button>
                    <mat-form-field class="select-button">

                        <mat-select hidden (selectionChange)="actionChange.emit($event.value)">
                            <mat-option value="cerrarPresupuesto">Cerrar presupuesto</mat-option>
                            <!--  <mat-option value="validarCambios">Validar cambios</mat-option> -->
                            <mat-option value="eliminarPresupuesto">Eliminar presupuesto</mat-option>
                            <!-- <mat-option value="pasarACastflow">Pasar presupuesto a CashFlow</mat-option> -->
                        </mat-select>
                        <!-- Icono de flecha -->
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>

                </div>
            </div>
            <div class="cambio-vista" *ngIf="isCards!=undefined && isCards!=null">
                <img title="Mostrar cards" class="pointer" [ngClass]="!isCards ?'selected':'unselected'"
                    (click)="isCardsChange.emit(false)" src="./assets/icons/table_row.svg">
                <img title="Mostrar tabla" class="pointer" [ngClass]="isCards ?'selected':'unselected'"
                    (click)="isCardsChange.emit(true)" src="./assets/icons/table_cards.svg">
            </div>
        </div>

    </div>


</div>
<span *ngIf="nombre && nombre.toLowerCase().includes('compra') && displayedColumnscompleta.length!=0">
    <div class="contenedor-boton1">
        <h6 class="mih6"> Totales conceptos detalle gasto </h6>
        <p class="mostrar pointer" (click)="verDetalle2('in')">Mostrar totales Conceptos / </p>
        <p class="ocultar pointer" (click)="verDetalle2('out')">Ocultar</p>
    </div>
    <div [@slideInOut]="detalle" class="scrollable-div">
        <div class="form-surrouded">
            <div class="cardsblueIni">
                <p class="cardtext">Total Base</p>
                <p class="cardtextbig" [ngClass]="totalBase<0?'letra-red':''">{{totalBase|numberEsp}} €</p>
            </div>
    
            <div class="cardsblue">
                <p class="cardtext">Total IVA</p>
                <p class="cardtextbig" [ngClass]="totalIva<0?'letra-red':''">{{totalIva|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total IRPF</p>
                <p class="cardtextbig" [ngClass]="totalIRPF<0?'letra-red':''">{{totalIRPF|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total</p>
                <p class="cardtextbig" [ngClass]="totalGasto<0?'letra-red':''">{{totalGasto|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Pendiente Facturar</p>
                <p class="cardtextbig" [ngClass]="pendienteFacturar<0?'letra-red':''">{{pendienteFacturar|numberEsp}} €</p>
            </div>
    
            <div class="cardsblue">
                <p class="cardtext">Total Facturado</p>
                <p class="cardtextbig" [ngClass]="totalFacturado<0?'letra-red':''">{{totalFacturado|numberEsp}} €</p>
            </div>
        </div>
    </div>
</span>
<span *ngIf="nombre && nombre.toLowerCase().includes('gasto') && !isCreatePage">
    <div class="contenedor-boton1">
        <h6 class="mih6"> Totales conceptos detalle gasto </h6>
        <p class="mostrar pointer" (click)="verDetalle2('in')">Mostrar totales Conceptos / </p>
        <p class="ocultar pointer" (click)="verDetalle2('out')">Ocultar</p>
    </div>
    <div [@slideInOut]="detalle" class="scrollable-div">
        <div class="form-surrouded">
            <div class="cardsblueIni">
                <p class="cardtext">Total Base</p>
                <p class="cardtextbig" [ngClass]="totalBase<0?'letra-red':''">{{totalBase|numberEsp}} €</p>
            </div>
    
            <div class="cardsblue">
                <p class="cardtext">Total IVA</p>
                <p class="cardtextbig" [ngClass]="totalIva<0?'letra-red':''">{{totalIva|numberEsp}}€</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total IRPF</p>
                <p class="cardtextbig" [ngClass]="totalIRPF<0?'letra-red':''">{{totalIRPF|numberEsp}} €</p>
            </div>
            <div class="cardsblue">
                <p class="cardtext">Total Gasto</p>
                <p class="cardtextbig" [ngClass]="totalGasto<0?'letra-red':''">{{totalGasto|numberEsp}} €</p>
            </div>
    
            <div class="cardsblue">
                <p class="cardtext">Pendiente OC</p>
                <p class="cardtextbig" [ngClass]="pendienteOc<0?'letra-red':''">{{pendienteOc|numberEsp}} €</p>
            </div>
        </div>
    </div>
</span>
<div *ngIf="!isCreatePage && !nombre.toLowerCase().includes('inform') 
 && displayedColumns.length!=0
" class="contenedor-boton table_container">
    <!--     <label class="titulosPaginas"> {{nombre.toUpperCase()}} </label>
 -->
    <div (mouseenter)="mostrarModal('in')" (mouseleave)="mostrarModal('out')">
        <p class="mostrar pointer"> <span
                [ngClass]="{'mostrar2': displayedColumnscompleta.length!= displayedColumns.length}"> Mostrar
                Ocultar columnas </span>/
        </p>

        <div [@slideInOut]="mostrarPopup" class="popup">
            <mat-form-field class="formField formFieldcol " appearance="outline">
                <input matInput placeholder="Columna..." type="text" (keyup)="filterCols($event)"
                    (keydown)="filterCols($event)">
            </mat-form-field>

            <div *ngIf="!mostrarColumns || columnsNamescompleta?.at(0)==undefined">
                <div class="checks"
                    *ngFor="let columnas of displayedColumnsFiltred?displayedColumnsFiltred:displayedColumnscompleta index as i"
                    (click)="evaluarCheckbox(i)">
                    <span *ngIf="columnas === 'acciones'">
                        <input type="checkbox" class="checkbox" [checked]="mostrarCampos[columnas]">
                        <span class="columnas"> {{primLetMay(columnas)}}</span>

                    </span>
                </div>
                <div class="checks"
                    *ngFor="let columnas of displayedColumnsFiltred?displayedColumnsFiltred:displayedColumnscompleta index as i"
                    (click)="evaluarCheckbox(i)">
                    <span *ngIf="columnas !== 'acciones'&&columnas !== 'cargo'">
                        <input type="checkbox" class="checkbox" [checked]="mostrarCampos[columnas]">
                        <span class="columnas"> {{primLetMay(columnas)}}</span>

                    </span>
                </div>
            </div>

            <div *ngIf="mostrarColumns && columnsNamescompleta?.at(0)!=undefined">
                <p>{{columnsNamescompleta?.at(0)==undefined}}</p>
                <div class="checks" *ngFor="let columnas of columnsNamescompleta index as i"
                    (click)="evaluarCheckbox(i)">
                    <input type="checkbox" class="checkbox" [checked]="mostrarColumns[columnas]">
                    <span class="columnas"> {{primLetMay(columnas)}}</span>
                </div>
            </div>
        </div>
    </div>

    <p class="ocultar-header pointer" (click)="vetTodo()"><span
            [ngClass]="{'mostrar2': displayedColumnscompleta.length== displayedColumns.length}">Ver todo</span>
    </p>
</div>