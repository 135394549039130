<div class="page_content">
    <div class="content_inner">
        <div class="content_login">
            <div class="card_login d-flex flex-column align-items-center justify-content-center">
                <div class="d-flex flex-row align-items-center justify-content-center mb-12">
                    <div class="logo"></div>
                </div>
                <form  (ngSubmit)="onSubmit()" [formGroup]="form">
                    <h1 class="textCenter formMaterial ">Restablecer contraseña</h1>
                    <div class="rowForm formMaterial">
                        <mat-form-field appearance="outline" class="col-12 ">
                            <input type="email" class="form-control" name="email" formControlName="email" required
                                email matInput placeholder="Correo electrónico"
                                [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}">
                        </mat-form-field>
                    </div>

                    <div class="rowForm formMaterial">
                        <mat-form-field appearance="outline" class="col-12 h-100">
                            <input type="password" class="form-control" matInput name="password"
                                formControlName="password" placeholder="Contraseña"
                                [ngClass]="{'is-invalid': submitted && this.form.controls['password'].errors}">
                        </mat-form-field>
                    </div>
                    <div class="rowForm formMaterial">
                        <mat-form-field appearance="outline" class="col-12 h-100">
                            <input type="password" class="form-control" matInput name="reppassword"
                                formControlName="reppassword" placeholder="Repita la contraseña"
                                [ngClass]="{'is-invalid': submitted && this.form.controls['reppassword'].errors}">
                        </mat-form-field>
                    </div>

                    <div class="form-group">
           
                        <div class="form-group formMaterial">
                            <button class="btn btn-primary btn-block" [disabled]="submitted">
                                Restablecer contraseña
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>