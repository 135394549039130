import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';



@Component({
  selector: 'app-restore-pass',
  templateUrl: './restore-pass.component.html',
  styleUrls: ['./restore-pass.component.scss']
})
export class RestorePassComponent {
  form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    reppassword: new FormControl("", [Validators.required]),
  });

  isSuccessful = false;
  submitted = false;
  identificador: string = '';
  showPassword: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificacionService: NotificationService,
    private usuarioService: UsuarioService

  ) { }

  ngOnInit() {


    const token = this.route.snapshot.queryParams['token'];

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.obtenerIdentificador();

  }

  obtenerIdentificador() {
    this.route.params
      .subscribe(params => {
        //console.log(params);
        this.identificador = params['identificador'];


        this.usuarioService.checkStateresetPassword(this.identificador).subscribe({
          next: (data) => {
            if (data.err) {
              this.notificacionService.error("Error", data.message, 5000);
              this.router.navigate(['']);
            }
          }
        })
      }
      );
  }

  get f() { return this.form.controls; }

  onSubmit(): any {
    this.submitted = true;


    if (!this.form.valid) {
      this.notificacionService.warning('Información', 'Rellene todos los campos por favor.', 5000);
      this.submitted = false

    } else {
      if (this.form.get('email')?.hasError('pattern')) {
        this.notificacionService.warning('Información', 'El email no es válido.', 5000);
        this.submitted = false

        return false;
      }
      if (this.form.get('password')?.hasError('minlength')) {
        this.notificacionService.warning('Información', 'La contraseña deben contener 6 caracteres.', 5000);
        this.submitted = false

        return false;
      }
      if (this.form.get('password')?.value != this.form.get('reppassword')?.value) {
        this.notificacionService.warning('Información', 'Las contraseñas debe coincidir', 5000);
        this.submitted = false

        return false;
      }
      this.usuarioService.resetPassword(this.identificador, this.form.get("email")?.value, this.form.get("password")?.value).subscribe({
        next: data => {
          this.isSuccessful = true;
          if (data.err) {
            if (data.err == "ERROR1") {
              this.notificacionService.error("ERROR", data.message, 5000);
              this.submitted = false
            } else {
              this.notificacionService.error("ERROR", "Compuebe que el email es el correcto", 5000);
              this.submitted = false
            }
          } else {
            this.notificacionService.success("Hecho", data.message, 5000);
            this.router.navigate(['']);
          }

        },
        error: err => {
          this.submitted = false;
          this.notificacionService.error("ERROR", err.message, 5000);
        }
      });

    }

  }

}
