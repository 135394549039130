import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Proyecto } from '../models/proyecto';

@Injectable({
  providedIn: 'root'
})
export class ProyectoService {

  /* 
  Servicio para las peticiones para la creacion,modificacion y listado de proyectos
  */
  url: string = environment.API_URL + "/proyecto";


  constructor(private http: HttpClient
  ) { }

  getAllProyectos(): Observable<any> {
    return this.http.get(`${this.url}/findAll`);
  }
  getSem(id: any): Observable<any> {
    return this.http.get(`${this.url}/ceckSemAct/${id}`);
  }
  filter(filter: any): Observable<any> {
    return this.http.post(`${this.url}/filter`, filter);
  }
  findById(id: number): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }
  save(proyecto: Proyecto): Observable<any> {
    return this.http.post(`${this.url}/save`, proyecto);
  }
  update(proyecto: Proyecto, id?: string): Observable<any> {
    console.log(proyecto);
    console.log(id);


    return this.http.post(`${this.url}/${id}`, proyecto);
  }
  checkSR(fechaInicioRodaje: any, fechaFinRodaje: any, semanas: number): Observable<any> {
    console.log(semanas);

    return this.http.post(`${this.url}/checkSR`, { fechaInicioRodaje, fechaFinRodaje, semanas });
  }

  asociarProyProdExit(productoraId: number, proyectoId: number) {
    let params = new HttpParams().set("productoraId", productoraId).set("proyectoId", proyectoId)
    console.log(params);

    return this.http.get(`${this.url}/asociarExitente`, { params: params });
  }

  delete(id: string): Observable<string> {
    return this.http.get<string>(`${this.url}/delete/${id}`);
  }
}