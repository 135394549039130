import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { dropdownAnimation } from 'src/app/animations/dropdownAnimation';
import { NotificationService } from 'src/app/services/notification.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { RegistroGastosService } from 'src/app/services/registro-gastos-service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { BehaviorSubject, Observable, finalize } from 'rxjs';

import { FilterService } from 'src/app/services/filter.service';
import { InterfazInicialComponent } from '../../interfaz-inicial/interfaz-inicial.component';
import { TableService } from 'src/app/services/servicios-funcionalidad/table.service';
import { OrdenCompraService } from 'src/app/services/orden-compra.service';
import { NextcloudServiceService } from 'src/app/services/nextcloud-service.service';
import { ControlCostesService } from 'src/app/services/control-costes.service';
import { GastoExport } from 'src/app/models/gasto';
import { PermisosProyectoService } from 'src/app/services/permisos-proyecto.service';
import { Departamento } from 'src/app/models/usuario';

@Component({
  selector: 'app-registro-gastos',
  templateUrl: './registro-gastos.component.html',
  styleUrls: ['./registro-gastos.component.scss'],
  animations: [dropdownAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class RegistroGastosComponent {
  filters: any[] = [];
  pageSize: any = 99999;
  page: any = 0;
  sorts: any[] = [{ key: 'fechaModificacion', direction: 'DESC' }];
  filter: any = {
    filters: this.filters,
    page: this.page,
    size: this.pageSize,
    sorts: this.sorts,
  };
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalElements = 0;
  evento: any = '';
  nombrePagina = 'GASTOS';
  rutaNuevo = 'admin-contacine/nuevo-gasto';
  nombreBoton = 'Nuevo Gasto';
  canvas: any;
  mostrarPopup = 'out';
  mostrarDetalle = 'in';
  gastosRecibidos: any;
  dialogVisible: boolean = false;
  idEliminar: any = 0;
  displayedColumns: string[] = [];
  displayedColumnscompleta: string[] = [];
  dataSource = new MatTableDataSource();
  mostrarCampos: { [clave: string]: boolean } = {};
  ordenarCampos: { [clave: string]: boolean } = {};
  totalBase: number = 0;
  totalIva: number = 0;
  totalIRPF: number = 0;
  totalGasto: number = 0;
  pendienteOc: number = 0;
  proyecto: any;
  dataExport: any;
  deparamento: any;
  contadorFiltros: number = 0;
  constructor(
    private registroGastosService: RegistroGastosService,
    private router: Router,
    private notificationService: NotificationService,
    private filterService: FilterService,
    public interfazInicial: InterfazInicialComponent,
    public tableService: TableService,
    private ordenCompraService: OrdenCompraService,
    private nextCloudService: NextcloudServiceService,
    private controlCostesService: ControlCostesService,
    public permisosProyectoService: PermisosProyectoService,
    private tokenService: TokenStorageService,
    private route: ActivatedRoute
  ) { }
  codigo: any;
  codigoHijo: any;
  ngOnInit(): void {
    if (
      this.tableService.checkAnyAccionByPermiso(
        8,
        this.interfazInicial.isSuperAdmin,
        this.interfazInicial.permisos
      )
    ) {
      this.notificationService.error(
        'ERROR',
        'No tienes permisos suficientes.',
        5000
      );
      this.router.navigate(['admin-contacine/proyectos']);
    }
    if (this.route.snapshot.queryParamMap.get('codigo')) {
      console.log('dentro');

      this.codigo = this.route.snapshot.queryParamMap.get('codigo');
      console.log('codigo', this.codigo);
    }
    if (this.route.snapshot.queryParamMap.get('codigoHijo')) {
      console.log('dentro');

      this.codigoHijo = this.route.snapshot.queryParamMap.get('codigoHijo');
      console.log('codigo', this.codigoHijo);
    }
    if (!this.interfazInicial.isSuperAdmin) {
      this.tableService.subscribeToProyectoChanges(() => {
        this.filter.filters = [];
        this.getDepartamento();
      });
      this.getDepartamento();
    } else {
      this.getGastos();
    }
    this.IniciarListas();
  }

  getDepartamento() {
    this.permisosProyectoService
      .getPermisosFormPreyectoAndUsu(
        this.interfazInicial.proyaux.id,
        this.tokenService.getUser().id
      )
      .pipe(
        finalize(() => {
          this.getGastos();
        })
      )
      .subscribe({
        next: (data) => {
          this.deparamento = data.departamento;
          if (this.deparamento == undefined) {
            this.notificationService.warning(
              'ERROR',
              'No dispones de un departamento en este proyecto.',
              5000
            );
          }
        },
      });
  }

  IniciarListas() {
    this.displayedColumns = [
      'Nº',
      'Fecha Factura',
      'Factura',
      'Código cuenta',
      'Descripción cuenta',
      'Concepto',
      'NIF',
      'Proveedor',
      'tieneCoste',
      'Base',
      'Iva',
      'Irpf',
      'Total',
      'Departamento',
      'Creado',
      'Modificado',
      'Fecha modificación',
      'Período de Impuesto',
      'Acciones',
    ];

    if (
      sessionStorage.getItem(this.nombrePagina) &&
      sessionStorage.getItem(this.nombrePagina) != 'undefined'
    ) {
      var a: any = sessionStorage.getItem(this.nombrePagina);
      this.mostrarCampos = JSON.parse(a);
      this.displayedColumns.forEach((element) => {
        this.displayedColumnscompleta.push(element);
      });
    } else {
      this.displayedColumns.forEach((element) => {
        this.mostrarCampos[element] = true;
        this.displayedColumnscompleta.push(element);
      });
    }
    this.actualizarTabla();
  }

  actualizarTabla() {
    this.displayedColumns = [];
    this.displayedColumns.push(...this.displayedColumnscompleta);
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.displayedColumns.splice(this.displayedColumns.indexOf(clave), 1);
      }
    }
  }

  calcTextWidth(text: any, font: any) {
    let canvas =
      this.canvas || (this.canvas = document.createElement('canvas'));
    let context = canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width + 10;
  }

  primLetMay(text: String): String {
    text = text.replace(/([A-Z])/g, ' $1').trim();

    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  mostrarModal(mostrar: string) {
    this.mostrarPopup = mostrar;
  }

  vetTodo() {
    for (let clave in this.mostrarCampos) {
      if (this.mostrarCampos[clave] == false) {
        this.mostrarCampos[clave] = true;
      }
    }
  }

  verDetalle(detalle: string) {
    this.mostrarDetalle = detalle;
  }

  doGeneralFilter() {
    if (this.evento.pageSize != undefined) {
      this.pageSize = this.evento.pageSize;
      this.filter = {
        filters: [],
        page: this.page,
        size: this.pageSize,
        sorts: this.sorts,
      };
      this.getGastos();
    } else {
      let operator = 'ALL_FIELDS_CLASS';

      if (this.evento != '') {
        this.filter.filters = [
          {
            operator: operator,
            value: this.evento,
            values: [
              'Proveedor.cif',
              'Proveedor.nombreFiscal',
              'PeriodoIva.nombre',
            ],
          },
        ];
        this.getGastos();
      } else {
        this.filter.filters = [];
        this.getGastos();
      }
    }
  }
  ordenar(property: string, sorts: any[] = []) {
    var orden = this.ordenarCampos[property] ? 'ASC' : 'DESC';
    var index = 0;
    sorts.forEach((element: any) => {
      if (element['key'] == property) {
        sorts.splice(index, 1);
      }
      index++;
    });
    for (let clave in this.ordenarCampos) {
      if (property.includes(clave)) {
        this.ordenarCampos[clave] = !this.ordenarCampos[clave];
      } else {
        this.ordenarCampos[clave] = false;
      }
    }
    this.filter.sorts = [{ key: property, direction: orden }];

    this.getGastos();
  }

  evaluarPageSize(event: any) {
    this.pageSize = event.value;
    this.filter = { filter: this.filter, page: this.page, size: this.pageSize };
    this.getGastos();
  }

  doFilter(event: any, type: any, operator: any, field_type: any) {
    this.filter.filters.splice(
      this.filter.filters.length - this.contadorFiltros,
      this.contadorFiltros
    );

    this.filter.filters = this.filterService.doFilter(
      event,
      type,
      operator,
      field_type,
      this.filter.filters,
      null
    );

    this.getGastos();
  }

  evaluateFilter() {
    console.log(this.deparamento.id);
    this.filter.filters.forEach((element: any) => {
      if (element.key == 'num_pc') this.filter.filters.delete(element);
    });
    //this.filter.filters = this.filter.filters.filter((element: any) => element.key !== "num_pc");
    if (!this.interfazInicial.isSuperAdmin) {
      if (
        this.interfazInicial.tipoUsu.id == 3 ||
        this.interfazInicial.tipoUsu.id == 2 ||
        this.interfazInicial.tipoUsu.id == 4 ||
        this.interfazInicial.isLicencia
      ) {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });
        this.contadorFiltros = 1;
      } else if (
        (this.interfazInicial.tipoUsu.id == 5 || this.interfazInicial.tipoUsu.id == 6) &&
        this.interfazInicial.depatamentoUsu
      ) {
        this.contadorFiltros = 2;
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'departamento.id',
          operator: 'LIKE',
          value: this.deparamento.id,
        });
      } else {
        this.contadorFiltros = 3;
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'proyecto.id',
          operator: 'LIKE',
          value: this.interfazInicial.proyaux.id,
        });
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'departamento.id',
          operator: 'LIKE',
          value: this.deparamento.id,
        });
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'createdBy.id',
          operator: 'LIKE',
          value: this.interfazInicial.currentUser.id,
        });
      }
    }
  }

  getGastos() {
    this.evaluateFilter();

    this.filter.filters.push({
      field_type: 'STRING',
      key: 'num_pc',
      operator: 'IS_NULL',
    });
    console.log('filters -> ', this.filter);
    if (this.codigo) {
      this.filter.filters.push({
        field_type: 'STRING',
        key: 'conceptos.codigo.cuenta',
        operator: 'LIKE',
        value: this.codigo + '',
      });
      if (this.codigoHijo) {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'conceptos.codigo.codigoHijo',
          operator: 'LIKE',
          value: this.codigoHijo + '',
        });
      } else {
        this.filter.filters.push({
          field_type: 'STRING',
          key: 'conceptos.codigo.codigoHijo',
          operator: 'IS_NULL',
        });
      }
    }
    this.registroGastosService
      .getRegistros(this.filter)
      .pipe(
        finalize(() => {
          this.calculatTotales();
        })
      )

      .subscribe({
        next: (data: any) => {
          this.dataExport = [];
          data.source.forEach((element: any) => {
            this.dataExport.push(new GastoExport(element));
          });
          if (data != null) {
            this.gastosRecibidos = data['pageList'];
          }
          this.dataSource = this.gastosRecibidos;
          this.totalElements = data.source.length;
          this.dataSource.paginator = this.paginator;
          if (this.ordenarCampos['id'] == undefined) {
            this.paginator._intl = this.interfazInicial.paginator;
            let nombre: string = '';
            if (data['source'].length != 0) {
              var mapa = Object.entries(data['source'][0]);
              mapa.forEach((element) => {
                nombre = element[0];
                this.ordenarCampos[nombre] = false;
              });
              this.ordenarCampos['proveedor.cif'] = false;
              this.ordenarCampos['proveedor.nombreFiscal'] = false;
              this.ordenarCampos['departamento.nombre'] = false;
              this.ordenarCampos['createdBy.nombreUsuario'] = false;
              this.ordenarCampos['modifyBy.nombreUsuario'] = false;
              this.ordenarCampos['periodo_iva.nombre'] = false;
            }
          }
        },
        error: (error) => { },
      });
  }

  getTotalOrdenDeCompraProyecto() {
    let proyectoAux;
    if (this.interfazInicial.proyaux.id == undefined) {
      proyectoAux = 0;
    } else {
      proyectoAux = this.interfazInicial.proyaux.id;
    }
    this.ordenCompraService
      .getTotalOrdenDeCompraProyecto(proyectoAux)
      .subscribe({
        next: (data) => {
          console.log('pendienteOc', data);
          this.pendienteOc = data;
        },
        error: (error) => { },
      });
  }

  formatDate(date: String[]) {
    var year = date[0];
    var month = date[1];
    var day = date[2];
    return day + '-' + month + '-' + year;
  }

  calculatTotales() {
    this.getTotalOrdenDeCompraProyecto();
    this.totalBase = 0;
    this.totalIva = 0;
    this.totalIRPF = 0;
    this.totalGasto = 0;
    this.gastosRecibidos.forEach((element: any) => {
      console.log(element);
      console.log(element.total);
      this.totalBase = this.totalBase + element.base;
      this.totalIva = this.totalIva + element.iva;
      element.conceptos.forEach((elementc: any) => {
        this.totalIRPF = this.totalIRPF + elementc.irpf;
        this.totalGasto = this.totalGasto + elementc.total;
      });
    });

    //pendienteOc
  }

  navegarAEditar(id: any, accion: any) {
    this.router.navigate([`admin-contacine/${accion}-gasto/` + id]);
  }

  borrarElemento(id: any) {
    this.registroGastosService.deleteGasto(id).subscribe({
      next: (data) => {
        this.getGastos();
        this.notificationService.success(
          'ÉXITO',
          'Gasto borrado correctamente.',
          5000
        );
      },
      error: (error) => { },
    });
    this.dialogVisible = false;
  }

  nextPage(event: PageEvent) {
    this.filter.page = event.pageIndex + '';
    this.filter.pageSize = event.pageSize + ''
    this.getGastos();
  }
  download(element: any) {
    this.nextCloudService.getBase64(element.documento.id_nextcloud).subscribe({
      next: (data) => {
        const linkSource = 'data:application/pdf;base64,' + data.b64;
        const a = document.createElement('a');
        const fileName = 'Gasto_' + element.numeroFactura + '.pdf';
        a.href = linkSource;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
      },
    });
  }

  pasarCoste(gasto: any) {
    this.controlCostesService.pasarGastos(gasto.id).subscribe({
      next: (data: any) => {
        this.notificationService.success('ÉXITO', 'Gasto pasado a coste correctamente', 5000);
        gasto.tieneCoste = true;
      },
      error: (error) => {
        this.notificationService.error('ERROR', 'Ha ocurrido un error al pasar a coste intentelo de nuevo', 5000);
      },
    });
  }

  conceptosTooltip(index: number): string {
    return `${this.gastosRecibidos[index].conceptos
      .map((concepto: any) => `${concepto.codigo.cuenta} - ${this.getConcepto(concepto.codigo)} ${concepto.descripcion != undefined && concepto.descripcion != '' ? ' (' + concepto.descripcion  + ')' : ''}`)
      .join('\n')}`;
  }

  getConcepto(element: any): string {
    var etiqueta = element.etiquetas.filter((item: any) => item.proyecto.id == this.interfazInicial.proyaux.id)[0];
    if (etiqueta && etiqueta.descripcion) return etiqueta.descripcion;
    else return element.concepto;
  }
}
