import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OcGastoService {
  documento: any
  oc: any
  constructor() { }
  /*
  Servicio que se encarga del envio de informacion de la OC al gasto con el documento que se quiere asociar 
   */
  setDocumento(doc: any) {
    this.documento = doc
  }
  getDocumento() {
    return this.documento
  }
  setOc(oc: any) {
    this.oc = oc
  }
  getOc() {
    return this.oc
  }

}
